import * as api from '../../API/index'
import { GET_SEEKER, LOADING, RECOMMENDED_SEEKER, SEEKER_LOADING, SEEKER_SUCCESS, SUCCESS, UPDATE_SEEKER } from "../../Utils/Constant";


export const getSeekerRecommended = () => async (dispatch) => {
    try {
        const { data: { data } } = await api.fetchSeekerRecommended();
        dispatch({ type: RECOMMENDED_SEEKER, payload: data })
    } catch (error) {
        console.log(error)
    }
}

export const fetchSeeker = (ID) => async (dispatch) => {
    try {
        dispatch({ type: LOADING })
        dispatch({ type: SEEKER_LOADING })
        const { data: { data } } = await api.fetchSeeker(ID);
        dispatch({ type: GET_SEEKER, payload: { data: data } })
        dispatch({ type: SUCCESS })
        dispatch({ type: SEEKER_SUCCESS })
    } catch (error) {
        console.log(error)
        // dispatch({ type: ERROR })
        // dispatch({ type: SEEKER_ERROR })
    }
}

export const updateSeeker = (name, city, country, username, code, phone, address, dob, gender, id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING })
        const { data: { data } } = await api.updateSeeker(name, city, country, username, code, phone, address, dob, gender, id);
        dispatch({ type: UPDATE_SEEKER, payload: { data: data } })
        dispatch({ type: SUCCESS })
    } catch (error) {
        console.log(error)
        // dispatch ({ type: ERROR })
    }
}
