import { Modal } from 'flowbite-react'
import { useEffect } from 'react'
import { BarLoader } from 'react-spinners'
import { distributeResume } from '../API'

const LoadingModal = ({ visible, toggleVisible, toggleResume }) => {


    return (
        <div>
            <Modal show={visible} size={'xl'} onClose={() => toggleVisible()} >
                {/* <Modal.Header className='text-white py-4 text-2xl font-bold'>
                    Distributing Resume
                </Modal.Header> */}



                <Modal.Body>
                    <div className='py-5 flex'>
                        <p className='text-center  text-[1.1rem] mt-[-10px] font-[700] '>Distributing Resume</p>
                        <button onClick={() => {
                            toggleResume();

                        }} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center :hover:bg-gray-600 :hover: " data-modal-hide="popup-modal">
                            <svg className="w-3 h-3" aria-hidden="true" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>


                    <p className='text-center mt-5 mb-2'>Sending Resumes To Emails</p>
                    <div className='flex justify-center mt-5 '>
                        <BarLoader color="blue" />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default LoadingModal
