import React, { useState } from 'react'
import Footer from '../components/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { AllCategories } from '../API/actions/categoriesAction'
import TopBar from '../components/TopBar'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'

const Categories = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const categories = useSelector(state => state.category.categories)
    const [nodata, setNoData] = useState(false)
    const loading = useSelector(state => state.loading.allCategoryLoading)

    useEffect(() => {
        console.log(categories)
    }, [categories])

    useEffect(() => {
        dispatch(AllCategories())
    }, [dispatch])

    const handleSubmit = (id, name) => {
        navigate('/jobs', { state: { ID: id, Name: name } })
    }

    useEffect(() => {
        if (categories?.length === 0) {
            setNoData(true)
        } else {
            setNoData(false)
        }
    })

    return (
        <div>
            {loading ?
                <div className='flex justify-center mt-[20%]'>
                    <ClipLoader size='5rem' />
                </div>
                :
                <>

                    <div>
                        <h1 className='text-[2.3rem] font-[600] text-center mt-5 max-md:text-[1.5rem] '>Categories</h1>
                        {nodata ?
                            <center className='mb-10'>
                                <div className=" ">
                                    <h1 className=" text-[2rem] text-gray-500 pt-10">No Categories Found</h1>
                                </div>
                            </center>
                            :
                            <div className='mt-10 '>

                                <div className='container mx-auto px-20 max-md:px-2 mt-10'>
                                    <div class="grid grid-cols-2 md:grid-cols-2 rounded-2xl lg:grid-cols-4 gap-8 max-md:gap-2 mb-8">


                                        {categories?.map((value) => (
                                            <div onClick={() => handleSubmit(value.id, value?.name)} key={value.id} className='bg-gray-100 cursor-pointer shadow-lg rounded-2xl hover:shadow-2xl transition duration-300 border-2' >
                                                <div className='h-[15rem] max-md:h-[13rem]'>
                                                    <img src={value.image} alt={value.alt} className='w-32 h-32 mx-auto mt-4 p-4 rounded-full bg-white' />
                                                    <div className='p-4'>
                                                        <h2 className='text-xl font-semibold mb-2 text-center max-md:text-[.8rem]'>{value.name}</h2>
                                                    </div>
                                                </div>
                                                <div className='flex justify-center mb-2'>
                                                    <p className='bg-[#F0A51E] text-white font-semibold rounded-full py-1 w-32 text-center' > {value.jobs} JOBS </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                    <Footer />
                </>}
        </div>
    )
}

export default Categories
