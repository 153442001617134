import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SESSION_SEEKER } from '../Utils/Constant';
import { AllApplied } from '../API/actions/appliedAction';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import { appliedJobs } from '../API';
import { CiLocationOn } from 'react-icons/ci';
import moment from 'moment';
import ProviderModal from '../components/ProviderModal';
import { useNavigate } from 'react-router';

const AppliedJobs = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [applied, setApplied] = useState([]);

    console.log('applied of screen', applied);


    const [data, setData] = useState();

    useEffect(() => {
        setData(JSON.parse(sessionStorage.getItem(SESSION_SEEKER)));
    }, []);

    // console.log('info', data);

    // useEffect(() => {
    //     if (!applied) {
    //         dispatch(AllApplied(data?.id))
    //     } else {
    //         dispatch(AllApplied(data?.id))

    //     }

    // }, [applied, data?.id])


    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await appliedJobs(data?.id);
                console.log('applied', res);
                setApplied(res?.data?.data)

            } catch (error) {
                console.error('Error verifying seeker:', error);
            }
        };

        fetchData();
    }, [data?.id]);

    const [visible, setVisible] = useState(false)
    const [jobId, setJobId] = useState('')
    const toggleVisibility = (id) => {
        setVisible(!visible)
        setJobId(id)
    }



    return (
        <div>
            <ProviderModal visible={visible} toggleVisibility={toggleVisibility} ID={jobId}
                navigate={navigate} />
            <div className=' py-10 min-h-screen'>
                <center>
                    <h1 className='uppercase text-[40px] font-[400]  max-md:text-[1.5rem]'>Applied Jobs</h1>
                    <h1 className=' text-[#999] text-[16px] font-[400]  max-md:w-[80%]  '>The Most Comprehensive
                        Search Engine For Jobs.</h1>
                    <hr className='w-[3rem] h-2 bg-green-400 rounded-xl mt-4' />
                </center>
                <div className='w-[70%] m-auto'>
                    {applied?.length > 0 ?
                        <div className='grid grid-cols-2 max-md:grid-cols-1 gap-5 m-auto mt-5 '>

                            {applied?.map((value) => (
                                <div
                                    onClick={() => toggleVisibility(value?.job)}
                                    className='border-2 rounded-xl bg-white hover:shadow-xl hover:shadow-gray-400 cursor-pointer p-6 transition duration-300'>
                                    <div className='w-full'>
                                        <div className='flex'>
                                            <h1 className='text-gray-800 text-[1.4rem] max-md:text-[.6rem] font-[600]'>{value?.title}</h1>
                                        </div>
                                        <h1 className='text-gray-600 text-[1.1rem] mt-2 font-[500] max-md:text-[.6rem]'>{value?.company_name}</h1>
                                        <h1 className='text-gray-600 text-[0.9rem] font-[400] max-md:text-[.6rem]'>
                                            {value.type}</h1>
                                        <div className='flex mt-2'>
                                            <h1 className='h-full text-gray-700 text-[0.8rem] font-[500] bg-gray-200 text-center py-1 px-4 rounded-md max-md:text-[.6rem]'>Salary {value?.salary} </h1>
                                        </div>

                                        <h1 className=' ml-4 text-gray-600 text-[.8rem] max-md:text-[.6rem] font-[400] text-right'>{moment(value?.date).format('YYYY-MM-DD')}</h1>

                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        <p className='text-center text-gray-600 font-[600] mt-6 text-[1.5rem]'>No Applied Jobs </p>
                    }
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default AppliedJobs
