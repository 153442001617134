import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { google, LoginSeeker } from '../API'
import { AiFillEye, AiFillEyeInvisible, AiOutlineMail } from 'react-icons/ai'
import { RiLockPasswordLine } from 'react-icons/ri'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Spinner } from 'flowbite-react'
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import ProAccount from '../components/ProAccount'
import { SESSION_ID, SESSION_LOGIN, SESSION_SEEKER, SESSION_TYPE } from "../Utils/Constant";

const Login = () => {


    const [loginData, setLoginData] = useState({ email: '', password: '' })

    // console.log('loginData', loginData);

    const navigate = useNavigate()

    const handleChange = (e) => {
        setLoginData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    useEffect(() => {
        const isLogin = sessionStorage.getItem(SESSION_LOGIN)
        if (isLogin === "true") {
            navigate('/')
        } else {

        }
    }, [navigate])
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            login();
        }
    };
    const login = () => {
        setLoading(true)

        if (loginData?.email?.length > 0) {
            if (loginData?.password?.length > 0) {
                
                LoginSeeker(loginData?.email, loginData?.password).then(res => {
                    const { data: { data } } = res

                    const { data: { status } } = res
                    console.log(status);
                    if (status === 'OK') {
                        // if (data.plan === 0) {
                        //     setLoading(false)
                        //     toggleVisible()
                        // } else {
                        sessionStorage.setItem(SESSION_LOGIN, "true")
                        sessionStorage.setItem(SESSION_ID, data.id)
                        sessionStorage.setItem(SESSION_TYPE, "SEEKER")
                        sessionStorage.setItem(SESSION_SEEKER, JSON.stringify(data))
                        setLoading(false)
                        navigate('/')

                        // }
                    } else {
                        setLoading(false)
                        toast.error('Invalid Username or Password', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                })

            } else {
                setLoading(false)
                toast.error('Enter Password', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }


        } else {
            setLoading(false)
            toast.error('Enter Email', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }


    }

    var test = ' this is test '


    const googleLogin = useGoogleLogin({

        onSuccess: async tokenResponse => {
            console.log(tokenResponse);
            setLoading(true)

            // fetching userinfo can be done on the client or the server
            console.log('auth', test)
            const userInfo = await axios
                .get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: { Authorization: `Bearer ${tokenResponse.access_token}` },

                })
                .then(async res => {
                    console.log('auth pass', test)

                    console.log(res)

                    await google(res.data.name, res.data.given_name + (res.data.sub).substring((res.data.sub).length - 6), res.data.email, '', '', '', '', res.data.sub, 'GOOGLE', 'true').then(async response => {
                        console.log(response)
                        const { data: { responseCode } } = response;
                        const { data: { data } } = response
                        console.log(data)
                        if (responseCode === 200) {
                            await google(res.data.name, res.data.given_name + (res.data.sub).substring((res.data.sub).length - 6), res.data.email, '', '', '', '', res.data.sub, 'GOOGLE', 'true').then(response2 => {
                                console.log('this is the data for google', response2)
                                const { data: { responseCode } } = response2;
                                const { data: { data } } = response2
                                if (responseCode === 200) {
                                    sessionStorage.setItem(SESSION_LOGIN, "true")
                                    sessionStorage.setItem(SESSION_ID, data.id)
                                    sessionStorage.setItem(SESSION_TYPE, "SEEKER")
                                    sessionStorage.setItem(SESSION_SEEKER, JSON.stringify(data))
                                    setLoading(false)
                                    navigate('/')
                                }
                                else {
                                    alert('Something went wrong')
                                }
                            })
                        } else if (responseCode === 210) {
                            setLoading(false)
                            toast.error('Email already exist', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }
                    ).catch(e => {
                        console.log(e)
                    })
                })
        },
    });


    useEffect(() => {
        console.log(googleLogin)
    }, [googleLogin])

    // console.log(SESSION_LOGIN)

    // useEffect(() => {
    //     if (googleLogin) {
    //         navigate('/');
    //     }
    // }, [googleLogin]);
    // console.log(googleLogin)


    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const toggleVisible = () => setVisible(!visible)

    const handleClick = () => {
        navigate('/')
    }

    const [show, setShow] = useState(false)

    const handleSubmit = () => {
        setShow(!show)
        myFunction()
    }

    const myFunction = () => {
        var x = document.getElementById("myInput");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password"
        }
    }

    return (
        <>
            {/* <ProAccount visible={visible} toggleVisible={toggleVisible} navigate={handleClick} /> */}

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div>
                <div className='w-[100%] h-[24rem] bg-blue-600'>
                </div>
                <div className=' bg-gray-400 '>
                    <div className="flex bg-white py-10 rounded-xl w-[60%] relative top-[-250px] m-auto ">
                        <div className="w-[50%] mt-[10%] max-md:hidden">
                            <img src="./assets/panelLogin.png" alt="" className="w-160 " />
                        </div>
                        <center className="w-[40%] max-md:w-[90%] max-md:m-auto">
                            <div className=' p-[10px]'><br />
                                <h1 className='text-[22px]'>Welcome Back :)</h1><br />
                                <h1 className='text-[13px] text-gray-600 font-[600]'>To keep connected with us please
                                    login with your personal information by email and password</h1><br />
                                <input type="email" name="email" onChange={handleChange} onKeyPress={handleKeyPress} placeholder='Enter Your Email'
                                    className='shadow-lg rounded-[12px] text-[.9rem] text-gray-700 font-[500] w-[100%] max-md:w-[100%] pl-[50px] border-2 pt-[7px] pb-[7px]' /><br />
                                <div className="relative top-[-1.9rem] left-[-44%] w-[10%]"><AiOutlineMail />
                                </div>
                                <input type="password" name="password" id='myInput' onChange={handleChange} onKeyPress={handleKeyPress} placeholder='Password'
                                    className='shadow-lg rounded-[12px] text-[.9rem] text-gray-700 font-[500] w-[100%] max-md:w-[100%] pl-[50px] border-2 pt-[7px] pb-[7px]' /><br />
                                <div className="relative top-[-1.9rem] left-[-44%] w-[10%]"><RiLockPasswordLine />
                                </div>
                                {show ?
                                    <div className="relative top-[-2.9rem] left-[44%] w-[10%] text-[1.4rem]" onClick={() => handleSubmit()}> <AiFillEye />
                                    </div>
                                    :
                                    <div className="relative top-[-2.9rem] left-[44%] w-[10%] text-[1.4rem]" onClick={() => handleSubmit()}> <AiFillEyeInvisible />
                                    </div>
                                }
                                <div className="flex">
                                    <Link to='/register'
                                        className="ml-auto text-[.9rem] hover:underline text-blue-600"> Register
                                        now </Link>
                                </div>
                                {loading ?
                                    <Button
                                        className='!bg-blue-600 text-white cursor-pointer font-[600] px-10  w-[100%] rounded-full mt-6'>
                                        <Spinner
                                            aria-label="Spinner button example"
                                            size="sm"
                                        />
                                        <span className="pl-3">
                                            Loading...
                                        </span>
                                    </Button> :
                                    <input
                                        type='submit'
                                        value='Log In'
                                        onClick={() => login()}
                                        className='bg-blue-600 text-white cursor-pointer font-[600] px-10 py-[5px] w-[100%] rounded-full mt-6' />
                                }


                                <h1 className="text-[.9rem] text-gray-700 font-[600] text-left mt-4">You can also join
                                    with</h1>
                                <div className="flex gap-2 mt-4 justify-center">
                                    <button onClick={() => googleLogin()}
                                        className='rounded-[12px] text-[.9rem] text-gray-700 font-[500] w-[100%] pl-[30px] border-2 py-[7px] hover:shadow-sm hover:bg-gray-100 '>
                                        <div className='flex items-center'>
                                            <img src='./assets/google2.png' alt=""
                                                className="w-8 h-8 cursor-pointer rounded-full border-2 border-gray-100 ml-[-1.3rem]" />
                                            <p className='text-[1.1rem] ml-12 font-[600]'>Continue with Google</p>
                                        </div>
                                    </button>
                                </div>

                            </div>
                        </center>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Login
