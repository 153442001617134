import * as api from '../../API/index'
import { ALL_JOBS, CATEGORY_JOB_LOADING, GETJOB_CATEGORY, GETJOB_COMPANY, GETSEARCH_JOB, GET_JOB, GET_JOBS, JOB_LOADING, LOADING, RECENT_JOBS, RECENT_JOB_LOADING, SEARCH_JOB_LOADING, SUCCESS } from "../../Utils/Constant";


export const AllJobs = () => async (dispatch) => {
    try {
        dispatch({ type: LOADING })
        const { data: { data } } = await api.fetchAllJobs();
        dispatch({ type: ALL_JOBS, payload: { jobs: data } })
        dispatch({ type: SUCCESS })
    } catch (error) {
        console.log(error)
    }
}

export const getJobs = () => async (dispatch) => {
    try {
        const { data: { data } } = await api.fetchJobs()
        console.log(data)
        dispatch({ type: GET_JOBS, payload: data })
    } catch (error) {
        console.log(error)
    }
}
export const getJob = (id, user) => async (dispatch) => {
    try {
        dispatch({ type: JOB_LOADING })
        const { data: { data } } = await api.fetchJob(id, user);
        dispatch({ type: GET_JOB, payload: data })
    } catch (error) {
        console.log(error)
    }
}
export const getJobSearch = (jobSearch) => async (dispatch) => {
    try {
        dispatch({ type: SEARCH_JOB_LOADING })
        const { data: { data } } = await api.fetchJobSearch(jobSearch.search, jobSearch.country, jobSearch.category, jobSearch.city, jobSearch.company, jobSearch.salaryStart, jobSearch.salaryEnd, jobSearch.type, jobSearch.isCountry, jobSearch.isCategory, jobSearch.isCity, jobSearch.isCompany, jobSearch.isSalary, jobSearch.isType);
        dispatch({ type: GETSEARCH_JOB, payload: data })
    } catch (error) {
        console.log(error)
    }
}
export const getJobByCategory = (category) => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_JOB_LOADING })
        const { data: { data } } = await api.fetchJobByCategory(category);
        dispatch({ type: GETJOB_CATEGORY, payload: data })
    } catch (error) {
        console.log(error)
    }
}

export const getJobByCompany = (company) => async (dispatch) => {
    try {
        const { data: { data } } = await api.fetchJobByCompany(company);
        dispatch({ type: GETJOB_COMPANY, payload: data })
    } catch (error) {
        console.log(error)
    }
}

export const getRecentJobs = () => async (dispatch) => {
    try {
        dispatch({ type: LOADING })
        dispatch({ type: RECENT_JOB_LOADING })
        const { data: { data } } = await api.fetchRecentJobs();
        dispatch({ type: RECENT_JOBS, payload: data })
        dispatch({ type: SUCCESS })
    } catch (error) {
        console.log(error)
    }
}