import React, { useState } from 'react'
import Footer from '../components/Footer'
import TopBar from '../components/TopBar'

const ContactUs = () => {

    const [contactData, setContactData] = useState({ name: '', email: '', message: '' })

    const handleChange = (e) => {
        setContactData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }
    const mailtoHref = "mailto:info@jobss.com.au?subject=SendMail&body=Description";

    return (
        <div>
           

            <h1 className='text-[2.3rem] font-[600] text-center mt-10 mb-[-2rem]'>Contact Us</h1>
            <section className="text-gray-600 body-font relative">
                <div className=" px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
                    <div
                        className="w-[100%] h-screen  bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
                        <img width="100%" height="100%" className="absolute inset-0 " marginheight="0" marginwidth="0"
                            scrolling="no" src='/assets/bg.jpg' />
                        <div className="bg-white relative w-[90%] flex flex-wrap py-6 rounded-xl shadow-md">
                            <div className="lg:w-1/2 px-6">
                                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
                                <p className="mt-1">Murrumba Downs QLD 4503</p>
                            </div>
                            <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
                                <a href={mailtoHref} className="text-indigo-500 leading-relaxed">info@jobss.com.au </a>
                                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
                                <p className="leading-relaxed"> 1300 562 772</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
                        <h2 className="text-gray-900 text-2xl mb-1 font-medium ">Contact Us</h2>
                        <p className="text-[.9rem] mb-5 text-gray-600">We Will Connect With Hirring Agencies, Job Banks
                            And Recruiters To Place Your Cv</p>
                        <div className="relative mb-4">
                            <label for="name" className="leading-7 text-sm text-gray-600">Name</label>
                            <input type="text" id="name" name="name" onChange={handleChange}
                                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div className="relative mb-4">
                            <label for="email" className="leading-7 text-sm text-gray-600">Email</label>
                            <input type="email" id="email" name="email" onChange={handleChange}
                                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div className="relative mb-4">
                            <label for="message" className="leading-7 text-sm text-gray-600">Message</label>
                            <textarea id="message" name="message" onChange={handleChange}
                                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                        </div>
                        <button
                            className="text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg">Send
                        </button>
                        <p className="text-xs text-gray-500 mt-3">WE WILL CONNECT WITH HIRING AGENCIES, JOB BANKS AND RECRUITERS TO PLACE YOUR CV.</p>
                    </div> */}
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default ContactUs
