import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './Payment';
import Footer from '../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSeeker } from '../API/actions/seekerAction';
import { SESSION_SEEKER } from '../Utils/Constant';

const stripePromise = loadStripe('pk_live_51JyEXWJ1pOdYlLIldJdoClXliI5xTV7prN0KHFTcmBDLhVkgKv8JczXgC86POetKDsxD2lZUF2XUxfZovR1SrCbG00NNnrZQwF');

const Checkout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [client, setClient] = useState(null);
  const [options, setOptions] = useState(null);

  const price = parseInt(location?.state?.price || 0) * 100; // Fetch price from state and convert to cents
  const seeker = useSelector((state) => state.seeker.seeker); // Get seeker data from Redux store

  // Check if user already has a plan
  useEffect(() => {
    if (seeker?.plan > 0) {
      navigate('/'); // Redirect to home if the user already has an active plan
    }
  }, [seeker, navigate]);

  // Retrieve user data from sessionStorage if not already set
  useEffect(() => {
    if (!data) {
      const sessionData = sessionStorage.getItem(SESSION_SEEKER);
      if (sessionData) {
        setData(JSON.parse(sessionData));
      }
    }
  }, [data]);

  // Fetch seeker data if not already available
  useEffect(() => {
    if (data && !seeker) {
      dispatch(fetchSeeker(data?.id));
    }
  }, [data, seeker, dispatch]);

  // Fetch Stripe payment intent client secret
  const fetchPaymentIntentClientSecret = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/create-payment-intent`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ price }),
      });

      const { clientSecret, error } = await response.json();

      if (error) {
        console.error('Error fetching client secret:', error);
        return;
      }

      setClient(clientSecret);
    } catch (err) {
      console.error('Error while fetching payment intent:', err);
    }
  };

  useEffect(() => {
    fetchPaymentIntentClientSecret();
  }, [price]);

  // Update Stripe Elements options when the client secret is ready
  useEffect(() => {
    if (client) {
      setOptions({ clientSecret: client });
    }
  }, [client]);

  // Handle missing user scenario
  if (!data) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <h1 className="text-2xl font-bold">User not found</h1>
        <p className="text-gray-600 mt-2">Please log in and try again.</p>
        <button
          onClick={() => navigate('/login')}
          className="mt-4 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
        >
          Go to Login
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-grow">
        <div>
          {options ? (
            <Elements stripe={stripePromise} options={options}>
              <PaymentForm clientSecret={client} />
            </Elements>
          ) : (
            <div className="flex justify-center items-center h-full">
              <p className="text-gray-600">Loading payment details...</p>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Checkout;
