import { Checkbox, Label, Modal, TextInput } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { LoginSeeker } from '../API'
import { AiFillEye, AiFillEyeInvisible, AiOutlineMail } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SESSION_ID, SESSION_LOGIN, SESSION_SEEKER, SESSION_TYPE } from "../Utils/Constant";
const LoginModal = ({ visible, toggleVisible }) => {



    return (
        <div>

            <Modal show={visible} size={'md'} onClose={() => toggleVisible()}>

                <Modal.Body>
                    <div className="relative w-full max-w-md max-h-full">
                        <div className="relative bg-white ">
                            <button onClick={toggleVisible} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center :hover:bg-gray-600 :hover: " data-modal-hide="popup-modal">
                                <svg className="w-3 h-3" aria-hidden="true" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-6 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 :text-gray-200" aria-hidden="true" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 :text-gray-400">You must login to apply this job </h3>
                                <div className='flex justify-evenly'>

                                    <Link to='/login' className="  bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 text-white font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2 border-[2px] border-red-600">
                                        Sign in Now
                                    </Link>
                                    <p onClick={toggleVisible} className="text-gray-500 bg-white rounded-lg border-[2px] border-gray-400 text-sm font-[600] w-[40%] pt-2 cursor-pointer hover:bg-gray-200 ">Cancel</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default LoginModal
