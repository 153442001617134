import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCvByUser } from '../API/actions/cvAction'
import { SESSION_ID } from '../Utils/Constant'
import TopBar from '../components/TopBar'
import Footer from '../components/Footer'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import { ApplyJob, CreateCoverLetter } from '../API/actions/coverLetterActions'
import moment from 'moment'
import { applyJob, createCoverLetter } from '../API'

const data = [
    { role: 'Developer', company: 'Technomentors', timeperiod: '2022-3002' },
    { role: 'Android', company: 'Develons', timeperiod: '2022-3002' },
]

const CoverLetter = () => {


    const params = useLocation();

    const intro = params?.state?.intro;
    console.log('intro', intro);
    const statement = params?.state?.statement;
    console.log('statement', statement);
    const job = params?.state?.job;
    console.log('job', job);

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    const currentDate = `${day}-${month}-${year}`;

    const user = sessionStorage.getItem(SESSION_ID)
    console.log('user', user)

    const cv = useSelector(state => state.cv.cvs);

    useEffect(() => {
        console.log('cv', cv)
    }, [cv])

    useEffect(() => {
        dispatch(getCvByUser(user));
    }, [dispatch, user]);

    const handleApply = () => {
        const postDate = moment().format("YYYY-MM-DD")
        createCoverLetter(user, job, postDate, cv?.role, intro, statement).then(res => {
            // console.log(res)
            const { data: { data } } = res;
            if (data.affectedRows === 1) {
                const cover = data.insertId;
                applyJob(job, user, postDate, cover).then(res => {
                    console.log('applied done', res)
                    const { data: { data } } = res;
                    if (data.affectedRows === 1) {
                        navigate('/applysuccessfully')
                    }
                })
            }
        })
    }

    return (
        <div>

            <h1 className='text-[3.125rem] font-[800] text-[#000] text-center max-md:text-[2rem] uppercase'>Cover Letter </h1>
            <div style={{ backgroundColor: 'white', }} className='border-4 w-[80%] max-md:w-[100%]  m-auto p-4 my-5'>

                <div className='w-[70%] mt-10 max-md:w-[100%]  m-auto'>
                    <h1 style={{ fontSize: 36, color: 'black', textAlign: 'center' }}>{cv?.name}</h1>
                    <h1 style={{ fontSize: 20, color: 'black', textAlign: 'center' }}> {cv?.address}</h1>

                    <div   className='flex max-md:flex-col justify-between mt-5'>
                        <h1 style={{ fontSize: 16, color: 'black', marginTop: 10 }}> {cv?.code}{cv?.phone}</h1>
                        <h1 style={{ fontSize: 16, color: 'black' }}>{cv?.email}</h1>
                    </div>
                </div>
                <hr style={{ backgroundColor: 'black', height: 1, marginTop: 20 }} ></hr>
                <div className='p-[1rem] '>
                    <p className='text-red-500 text-[1rem] font-[700] '>{currentDate}</p>
                    <h2 className='text-[1rem] font-[800] mt-5 '>Expression of interest: {cv?.role}</h2>
                    <p className='text-[1rem] ml-5 font-[500] mt-3 '>{intro}</p>
                    <p className='text-[1rem] ml-5 font-[500] mt-3 '>{statement}</p>

                    <div className='m-5 p-[3rem] bg-gray-300 ' >
                        {cv.careers?.map((value) => {
                            return (
                                <li className='text-black font-[600]' >I was working as a {value.job} in {value.company} from {value.timeperiod}</li>
                            )
                        })}
                    </div>

                    <div className='mt-[2rem]' >
                        {cv?.educations?.map((value) => {
                            return (
                                <p className='text-gray-700 font-[600]'>I hold a {value.qualification} degree completed in {value.timeperiod} at {value.institute}</p>
                            )
                        })}
                    </div>

                    <p className='text-gray-700 font-[600] mt-5'>{cv?.statement}</p>

                    <h2 className='text-gray-900 text-[1.2rem] font-[500] mt-5'>Your's Sincrely</h2>
                    <h2 className='text-gray-900 text-[1.2rem] font-[600] text-right mt-5'>{cv?.name}</h2>

                    <center>
                        <Button onClick={handleApply} type='button' variant='contained' className='py-2 px-10 text-white font-[600] rounded-lg' >Apply</Button>
                    </center>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default CoverLetter
