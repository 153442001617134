
import { CREATE_COVER_LETTER, ERROR, LOADING, SUCCESS } from "../../Utils/Constant"

const coverLetter = (state = { isLoading: true, success: false, error: false }, action) => {
    switch (action.type) {
        case LOADING:
            return { ...state, isLoading: true, success: false, error: false }
        case SUCCESS:
            return { ...state, isLoading: false, success: true, error: false }
        case ERROR:
            return { ...state, isLoading: false, success: false, error: true }
        case CREATE_COVER_LETTER:
            return { ...state, coverLetters: [...state.coverLetters, action.payload] }
        case CREATE_COVER_LETTER:
            return { ...state, apply: [...state.apply, action.payload] }
        default:
            return state
    }
}

export default coverLetter
