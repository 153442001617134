import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { verifySeeker } from '../API';
import { ToastContainer, toast } from 'react-toastify';

const VerifySuccessful = () => {

    const navigate = useNavigate();
    const [mail, setMail] = useState('');
    const [id, setId] = useState('');

    const [data, setData] = useState()

    function b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    useEffect( () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        if (urlSearchParams.get('u')) {
            setId(b64DecodeUnicode(urlSearchParams.get('u')));
        }
    }, []);

    // console.log('id', id, mail, data);

    useEffect(() => {
        if (id) {
            verifySeeker(id, 'true').then(res => {
                console.log('data return ', res);
                setData(res?.status)
                if (res?.status === 200) {
                    toast.success('Profile verified Successfuly', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }

            });
        }
    }, [id]);
    return (
        <div className="h-screen flex justify-center items-center bg-gray-200">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {data === 200 ?
                <div className="w-[50%] py-[3rem] bg-white flex flex-col items-center border-2 shadow-md rounded-md">
                    <div className="flex flex-col items-center">
                        <h2 className="mt-8 font-[700] text-[1.4rem]">
                            Profile Verified Successfully!
                        </h2>
                        <p className="w-[60%] mt-3 text-gray-600 text-[.8rem] font-[400] text-center">
                            profile verified successfully, trust and credibility increase, fostering reliability and assurance within the community and individual.                        </p>
                    </div>
                    <div className="flex justify-center gap-5 w-full">
                        <button
                            onClick={() => navigate("/SearchPage", { replace: true })}
                            className="w-[30%] mt-10 bg-green-500 text-white font-[500] hover:bg-green-700 hover:border-white rounded-[8px] border-2 py-3 transition-all ease-in-out duration-300"
                        >
                            Find Jobs
                        </button>
                        <button
                            onClick={() => navigate("/", { replace: true })}
                            className="w-[30%] mt-10 font-[500] hover:bg-gray-200 rounded-[8px] border-2 py-3">
                            Go to Home
                        </button>
                    </div>
                </div>
                :
                <p>An Error Occur while Verifying Please try again in some time</p>
            }

        </div>
    );
};

export default VerifySuccessful;
