import { Modal } from 'flowbite-react'
import React from 'react'

const CountryModal = ({ visible, click, list, toggleVisibility }) => {
    return (
        <div>
            <Modal show={visible} size={'2xl'} onClose={() => toggleVisibility()}>
                <Modal.Header > Select Country</Modal.Header>
                <Modal.Body className='  bg-white  max-md:ml-0'>
 
 
                    <ul style={{ margin: '0', padding: '0', listStyle: 'none', }}  className='min-h-[500px]'>
                        {list?.map((item, index) => (
                            <li key={index}>
                                <div onClick={() => click(item)}>
                                    <div style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                    }} className="cursor-pointer" >
                                        <h5  >{item.name}</h5>
                                    </div>
                                    <div style={{
                                        backgroundColor: '#777777',
                                        height: '0.5px',
                                    }} className='my-4'></div>
                                </div>
                            </li>
                        ))}
                    </ul>


                </Modal.Body>
            </Modal>
        </div >
    )
}

export default CountryModal
