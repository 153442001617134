import React from 'react'
import TopBar from '../components/TopBar'
import { AiOutlineMail } from 'react-icons/ai'
import { BsPersonFill, BsTelephone } from 'react-icons/bs'
import { CiLocationOn } from 'react-icons/ci'
import { useEffect } from 'react'
import { useState } from 'react'
import LoginRequireModal from '../components/LoginRequireModal'
import { SESSION_SEEKER } from '../Utils/Constant'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSeeker } from '../API/actions/seekerAction'
import { GetPlanById } from '../API'

const ProfileInfo = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const seeker = useSelector(state => state.seeker.seeker)
    const [seekerData, setSeekerData] = useState()
    const [trigger, setTrigger] = useState(false);

    useEffect(() => {
        if (!seeker) {
            setSeekerData(JSON.parse(sessionStorage.getItem(SESSION_SEEKER)));
            console.log(JSON.parse(sessionStorage.getItem(SESSION_SEEKER)))
            setTrigger(!trigger)
        }
    }, [seeker]);

    console.log('seeker', seekerData?.id);

    useEffect(() => {
        if (!seeker) {
            dispatch(fetchSeeker(seekerData?.id))
            console.log('fetch seeker ');
        }

    }, [dispatch, seeker, trigger]);


    const [visible, setVisible] = useState(false)
    const toggleVisible = () => setVisible(!visible)

    const [plan, setPlan] = useState()

    console.log('seeker id', seeker);



    useEffect(() => {
        if (seeker?.plan) {

            GetPlanById(seeker?.plan).then(res => {
                console.log('plan by id', res)
                setPlan(res?.data?.data)
            })
        }

    }, [seeker])


    return (
        <div className='min-h-screen'>

            <LoginRequireModal visible={visible} toggleVisible={toggleVisible} />

            <div className=''>

                <div className="gap-6 mx-6 flex-col ">
                    <div className='w-[50%] max-md:w-[100%] p-6  bg-gray-300  hover:bg-gray-200 transition-all rounded-xl m-auto'>
                        <h1 className='text-[1.2rem] max-sm:text-[1.5rem] font-[600] w-[50%]'>Account Info</h1>

                        <div onClick={() => navigate('/personalinfo')}
                            className='flex w-[100%] cursor-pointer p-6 justify-between'   >

                            <div className=''>
                                <div
                                    className='border-3 border-black '>
                                    <div className='flex items-center gap-3 mt-4'>
                                        <span><BsPersonFill size={25} /></span><h2
                                            className='text-[1.1rem] font-[500] text-gray-600 '>{seeker?.name}</h2>
                                    </div>
                                    <div className='flex items-center gap-3 mt-4'>
                                        <span><AiOutlineMail size={25} /></span><h2
                                            className='text-[1.1rem] font-[500] text-gray-600 '>{seeker?.email}</h2>
                                    </div>
                                    {seeker?.phone ?
                                        <div className='flex items-center gap-3 mt-4'>
                                            <span><BsTelephone size={25} /> </span><h2
                                                className='text-[1.1rem] font-[500] text-gray-600 '>{seeker?.code}{seeker?.phone}</h2>
                                        </div>
                                        :
                                        ''}
                                    {seeker?.address ?
                                        <div className='flex items-center gap-3 mt-4'>
                                            <span><CiLocationOn size={25} /></span><h2
                                                className='text-[1.1rem] font-[500] text-gray-600 '>{seeker?.address}</h2>
                                        </div>
                                        :
                                        ''}
                                </div>
                            </div>
                            <img src="./assets/arrow.png" alt="" className='w-16 h-16 mt-[5%]' />
                        </div>

                    </div>


                    <div className='w-[50%] max-md:w-[100%] border-4 rounded-xl p-6  m-auto mt-6'>

                        <h1 className='text-[1.2rem] max-sm:text-[1.5rem] font-[600]  '>Plan Info</h1>
                        {plan ?
                            <div className="text-[#000000] text-sm font-light w-[100%] bg-white p-6 rounded-xl">
                                <div className='' >
                                    <h1 className=" max-md:text-[.7rem] font-[300] text-center text-[1.3rem] mb-6">{plan?.name}</h1>
                                    {/* <p className=" max-md:text-[.7rem]  text-[1rem] mb-6 " style={{ fontWeight: 'bold', fontFamily: 'sans-serif' }} >{plan?.accounttype}</p> */}
                                    {plan?.purpose?.split(',').map((purposePart, index) => (
                                        <ul key={index} className=" text-left">
                                            <li className="max-md:text-[.7rem] text-[16px] font-[600] mt-2">{`\u2B26 ${purposePart.trim()} `}</li>
                                        </ul>
                                    ))}
                                    <div className="flex justify-between mt-6">
                                        <h1 className=" max-md:text-[.7rem] text-[1rem] font-[600] my-2">Subscription Price :</h1>
                                        <h1 className=" max-md:text-[.7rem] text-[1.2rem] font-[600] my-2">$ {plan?.amount}</h1>
                                    </div>
                                </div>
                            </div>
                            :
                            <p className='text-[1.2rem] text-gray-700 font-[600]' >
                                Please Purchase a plan
                            </p>}
                    </div>

                </div>

            </div>
        </div>
    )
}

export default ProfileInfo
