import React from 'react'
import QuickSearch from '../components/QuickSearch'
import GetApp from '../components/GetApp'
import Footer from '../components/Footer'
import TopBar from '../components/TopBar'
import Search from '../components/Search'

const JobDetail = () => {
    return (
        <div>
            <TopBar/>
            <Search/>
            <QuickSearch/>
            <h1 className='uppercase text-[1rem] text-center p-2 font-[800] text-white bg-[#F0A51E] max-md:text-[0.6rem]'>We
                will connect with hiring agencies, job banks and recruiters to place your CV</h1>
            <GetApp/>
            <h1 className='text-[2.3rem] font-[600] text-center mt-5'>Job Detail</h1>
            <div className='bg-[#EAEAEA] pb-6'>
                <h1 className='text-[1.7rem] font-[500] text-center pt-10'>Social Media Marketing</h1>

                <div className='mt-10 m-auto w-[60%] bg-white rounded-xl'>
                    <div className='flex justify-between pt-6'>
                        <h1 className='ml-6 font-[500] text-[1rem]'>Facebook</h1>
                        <h1 className='mr-4 font-[500] text-[1rem]'>Today</h1>
                    </div>
                    <div className='flex justify-center'>
                        <h1 className='bg-[#00A224] text-white px-4 py-1 rounded-xl'>Salary 5000/month</h1>
                    </div>
                    <h1 className='text-[2rem] font-[500] text-center mt-5'>IT & Communications</h1>
                    <h1 className='text-[1rem] font-[500] text-center mb-5'>Lahore</h1>

                    <div className='flex bg-[#D9D9D9] '>

                        <div className='bg-[#84c2dc] w-[40%] rounded-r-2xl'>
                            <div className='flex justify-center mt-4'>
                                <h1 className='bg-[#2994FF] text-white font-[600] px-2 rounded-xl py-2'>Full TIme</h1>
                            </div>
                            <h1 className='text-center font-bold text-[1.2rem]'>Mon - Sat</h1>
                            <h1 className='text-center text-[1.3rem] font-[500]'>9 AM - 5 PM</h1>
                        </div>
                        <div className='w-[60%] mt-4 pb-2'>
                            <h1 className='text-center font-[500] text-[1.4rem]'>5Years</h1>
                            <h1 className='text-center  font-bold text-[1.5rem]'>Bachelors</h1>
                            <h1 className='text-center '>Java, Java script,Andorid
                                IOS,WEB</h1>

                        </div>
                    </div>
                    <h1 className='ml-10 mt-5 text-[1.6rem] font-[600]'>Description:</h1>
                    <div className='m-auto w-[90%] mt-4 pb-6'>
                        <h1 className='font-[500]  '>Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                            an unknown printer took a galley of type and scrambled it to make a type specimen book. It
                            has survived not only five centuries, but also the leap into electronic typesetting,
                            remaining essentially unchanged. It was popularised in the 1960s with the release of
                            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                            software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy
                            text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                            dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled
                            it to make a type specimen book. It has survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially unchanged. It was popularised in the
                            1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                            with desktop publishing software like Aldus PageMaker including versions of Lorem
                            Ipsum.</h1>
                    </div>

                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default JobDetail
