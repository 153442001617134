import { combineReducers } from "redux";
import category from "./category";
import job from "./job";
import company from "./company";
import country from "./country";
import city from "./city";
import seeker from "./seeker";
import cv from "./cv";
import bookmark from "./bookmark";
import apiJob from "./apiJob";
import loading from "./loading";
import coverLetter from "./coverLetter";

export default combineReducers({
    category,
    job,
    company,
    country,
    city,
    seeker,
    cv,
    bookmark,
    loading,
    apiJob,
    coverLetter
})
