import React from 'react'
import { FcSupport } from 'react-icons/fc'
import { CgArrowsExchangeAlt } from 'react-icons/cg'
import { IoIosInfinite } from 'react-icons/io'
import Footer from '../components/Footer'
import TopBar from '../components/TopBar'

const AboutUs = () => {
    return (
        <div>
           
            <div className="bg-gray-300 py-[5%]">
                <h1 className="text-gray-700 font-[300] text-[2.4rem] max-md:text-[2rem] w-[60%] m-auto ">Access valuable career insights and guidance from seasoned professionals to steer your career in the right direction.</h1>
            </div>
            <div className='my-10'>
                <div className="flex">
                    <div className='bg-[#060E3E] w-[5%]'>   </div>
                    <div className='flex flex-col items-center mb-10'>
                        <div className='w-[60%] m-auto'>
                            <h1 className='text-[2rem] font-[400] text-gray-800'>About US</h1>
                            <p className='mt-4 text-gray-600 w-[80%] '>
                            Accessing valuable career insights and guidance from seasoned professionals is an essential step in steering your career in the right direction. By tapping into the wealth of knowledge and experience offered by these mentors, you can receive personalized advice to navigate challenges and make informed decisions. Connecting with a network of experts not only expands your professional circle but also provides opportunities for mentorship. Armed with these insights, you can confidently navigate your career journey, making strategic choices that empower your growth and lead to long-term success in your chosen field.</p>
                        </div>
                    </div>
                </div>


                <div className="grid grid-cols-3 gap-[5%] px-[10%] mt-[5%]">
                    <div className='border-2   rounded-md hover:scale-105 transition-all 	duration-300 ease-in-out shadow-md py-5 px-5'>
                        <FcSupport color="#284451" className="font-bold text-[1.7rem] border-2  rounded-full   w-12 h-12 p-2 shadow-md shadow-[#060E3E]" />
                        <h1 className="text-[1.4rem] py-[2%] mt-[2%] text-gray-700 font-[600] ">Employee Stories</h1>
                        <p className="text-[.9rem] text-gray-600 font-[600] ">
                        Hear firsthand accounts from our team members about their experiences and successes working with jobss.com.au .</p>
                    </div>
                    <div className='border-2   rounded-md hover:scale-105 transition-all 	duration-300 ease-in-out shadow-md py-5 px-5'>
                        <CgArrowsExchangeAlt color="#284451" className="font-bold text-[1.7rem] border-2  rounded-full   w-12 h-12 p-2 shadow-md shadow-[#060E3E]" />
                        <h1 className="text-[1.4rem] py-[2%] mt-[2%] text-gray-700 font-[600] ">Job Alerts</h1>
                        <p className="text-[.9rem] text-gray-600 font-[600] ">
                        Stay up to date with the latest job openings by subscribing to our job alerts. Receive notifications tailored to your preferences.</p>
                    </div>
                    <div className='border-2   rounded-md hover:scale-105 transition-all 	duration-300 ease-in-out shadow-md py-5 px-5'>
                        <IoIosInfinite color="#284451" className="font-bold text-[1.7rem] border-2  rounded-full   w-12 h-12 p-2 shadow-md shadow-[#060E3E]" />
                        <h1 className="text-[1.4rem] py-[2%] mt-[2%] text-gray-700 font-[600] ">Careers</h1>
                        <p className="text-[.9rem] text-gray-600 font-[600] ">
                            Explore career opportunities with us and find out how you can contribute to our mission of making the job search process easier for everyone.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AboutUs