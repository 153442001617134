import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TopBar from '../components/TopBar'
import { AllCountries } from '../API/actions/countriesActions'
import CountryModal from '../components/CountryModal'
import CitySelectModal from '../components/ProfileModals/CitySelectModal'
import { AllCities } from '../API/actions/citiesAction'
import { fetchSeeker, updateSeeker } from '../API/actions/seekerAction'
import { SESSION_SEEKER } from '../Utils/Constant'
import PhoneModal from '../components/ProfileModals/PhoneModal'
import { EmailRequest, verifySeeker } from '../API'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { ClipLoader } from 'react-spinners'
import Footer from '../components/Footer'
import MailModal from '../components/MailModal'
import { IoAlertCircleSharp } from "react-icons/io5";


const PersonalInfo = () => {

    const navigate = useNavigate()

    const [stateCheck, setStateCheck] = useState(false)
    const [data, setData] = useState();


    const seeker = useSelector(state => state.seeker.seeker)

    const cities = useSelector(state => state.city.cities)
    const countries = useSelector(state => state.country.countries)

    const [completed, setCompleted] = useState(false)
    const [verified, setVerified] = useState(false)
    const [country, setCountry] = useState()
    const [citiesData, setCitiesData] = useState()


    const [cityName, setNameCity] = useState('')
    const [countryName, setCountryName] = useState('')
    const [phoneCode, setPhoneCode] = useState('')
    const [gen, setGen] = useState('')
    const [trigger, setTrigger] = useState(false)

    const [cityVisible, setCityVisible] = useState(false)
    const [countryVisible, setCountryVisible] = useState(false)

    const [changed, setChanged] = useState(false)

    const toggleVisibility = () => setCityVisible(!cityVisible)
    const toggleCountryVisibility = () => setCountryVisible(!countryVisible)


    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setData(JSON.parse(sessionStorage.getItem(SESSION_SEEKER)));
    }, [trigger]);

    const dispatch = useDispatch();



    const [seekerData, setSeekerData] = useState({
        name: '',
        city: '',
        country: '',
        username: '',
        phone: '',
        address: '',
        dob: '',
        gender: '',
        id: ''
    })

    useEffect(() => {
        setSeekerData((prevData) => ({ ...prevData, name: seeker?.name || '', address: seeker?.address || '', dob: seeker?.dob || '', phone: seeker?.phone || '', gender: seeker?.gender || '' }));
    }, [seeker]);


    useEffect(() => {
        setLoading(true)
        dispatch(fetchSeeker(data?.id))
        setLoading(false)
    }, [dispatch, trigger, data]);

    useEffect(() => {
        if (seeker) {

            setSeekerData({
                ...seekerData,
                name: seeker?.name,
                city: seeker?.city,
                country: seeker?.country,
                username: seeker?.username,
                code: seeker?.code,
                phone: seeker?.phone,
                address: seeker?.address,
                dob: seeker?.dob,
                gender: seeker?.gender,
                id: seeker?.id
            })
            setCountry(seeker?.country)
            setPhoneCode(seeker?.code)
            setGen(seeker?.gender)
            setNameCity(seeker?.city_name)
            setCountryName(seeker?.country_name)

        } else {
            setTrigger(!trigger)
        }

        // dispatch(CheckSeeker(seeker?.id))
        // if (seeker?.address && seeker?.city && seeker?.country && seeker?.dob && seeker?.email && seeker?.gender && seeker?.id && seeker?.name && seeker?.password && seeker?.code && seeker?.phone && seeker?.username) {
        //     setCompleted(true)
        // } else {
        //     setCompleted(false)
        // }
        // if (seeker?.verified === "true") {
        //     setVerified(true)
        // } else {
        //     setVerified(false)
        // }
    }, [seeker])



    const update = () => {
        dispatch(updateSeeker(seekerData?.name, seekerData?.city, seekerData?.country, seekerData?.username, seekerData?.code, seekerData?.phone, seekerData?.address, seekerData?.dob, seekerData?.gender, seekerData?.id))
        setTrigger(!trigger)
        toast.success('Profile Updated Successfuly', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        setTimeout(() => {
            navigate('/')
        }, 1500)

    }


    useEffect(() => {
        dispatch(AllCities())
    }, [dispatch,]);

    useEffect(() => {
        dispatch(AllCountries())
    }, [dispatch,]);

    const cityClick = (item) => {
        setSeekerData({ ...seekerData, city: item.id })
        toggleVisibility()
        setNameCity(item.name)
    }

    const countryClick = (value) => {
        setSeekerData({ ...seekerData, country: value?.id })
        setCountry(value?.id)
        toggleCountryVisibility()
        setCountryName(value?.name)
    }

    // gendermodal==============

    const [phoneVisible, setPhoneVisible] = useState(false)
    const togglePhoneVisible = () => setPhoneVisible(!phoneVisible)

    const setCode = (code) => {
        setPhoneCode(code)
        setSeekerData({ ...seekerData, code: code })
        togglePhoneVisible()
    }

    useEffect(() => {
        const searched = cities?.filter((data) => {
            return data.country === country
        })
        setCitiesData(searched)
    }, [country]);

    // const [visible, setVisible] = useState(false)
    // const toggleVisible = () => setVisible(!visible)
    // const [text, setText] = useState('Please Complete your Profile First')
    // const click = (t) => {
    //     toggleVisible();
    //     setText(t)
    // }

    const verify = async (value) => {
        await verifySeeker(seeker?.id, value).then(res => {
            setTrigger(!trigger)

        })
    }


    const inputStyle = {
        padding: "1.0rem 0.75rem",
        width: "100%",
        fontSize: "0.8rem",
        lineHeight: "1.25",
        color: "#343434",
        backgroundColor: "#efefef",
        backgroundImage: "none",
        backgroundClip: "padding-box",
        border: "1px solid #efefef",
        borderRadius: "8px",
        transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)",
    }

    const [mailVisible, setMailVisible] = useState(false)


    // console.log('seeker id ', seeker?.id);


    const [checkProfile, setCheckProfile] = useState(false)

    const toggleMail = async () => {
        if (seeker?.address && seeker?.city && seeker?.country && seeker?.code && seeker?.phone && seeker?.gender && seeker?.name) {
            setLoading(true)
            await EmailRequest(seeker?.id).then(res => {
                setTrigger(!trigger)

            })
            setLoading(false)
            setMailVisible(!mailVisible)

        }
        else {
            toast.error('Please complete profile first', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const toggleMailVisible = () => setMailVisible(!mailVisible)

    useEffect(() => {
        if (seeker?.address && seeker?.city && seeker?.country && seeker?.code && seeker?.phone && seeker?.gender && seeker?.name) {
            setCheckProfile(true)
        } else {
            setCheckProfile(false)
        }

    }, [seeker])

    console.log('seeker profile', checkProfile);



    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <MailModal visible={mailVisible} toggleMail={toggleMail} email={seeker?.email} toggleMailVisible={toggleMailVisible} />
            <PhoneModal visible={phoneVisible} togglePhoneVisible={togglePhoneVisible} set={setCode} />
            <CitySelectModal visible={cityVisible} toggleVisibility={toggleVisibility} list={citiesData} click={cityClick} />
            <CountryModal visible={countryVisible} toggleVisibility={toggleCountryVisibility} list={countries} click={countryClick} />

            {loading ?
                <div className='flex justify-center my-[10%]'>
                    <ClipLoader size='5rem' />
                </div>

                :
                <div className='border-t-2 border-t-gray mt-[-2rem]' >
                    <div className='flex flex-col items-center mt-5' >
                        <h2 className='text-[2rem] font-[700]' >Profile</h2>
                        {checkProfile ?
                            ''
                            :
                            <p className='text-[1rem] font-[500] text-red-600'>Complete your profile</p>
                        }

                        {seeker?.verified === 'true' ?
                            <p className='text-[1rem] font-[800] text-green-600'>Verified</p>
                            :
                            ''
                        }
                    </div>
                    <div className='w-[80%] max-md:px-2 max-md:w-[90%] m-auto rounded-md px-10 py-10'>
                        <div className="flex gap-6 max-md:flex-col">
                            <div className='focus:outline-none rounded-md focus:border-blue-600 border-[0px] w-[100%] py-2 pl-4'>
                                <h5 className='mb-[-10px] text-sm'>Name</h5>
                                <input
                                    type="text"
                                    placeholder="Name"
                                    className="focus:outline-none rounded-md focus:border-blue-600 mt-4 font-[500] pl-4 border-[#E5E7EB]"
                                    style={inputStyle}
                                    value={seekerData?.name}
                                    onChange={(event) => setSeekerData({ ...seekerData, name: event.target.value })}
                                />
                                {seekerData?.name ?
                                    ''
                                    :
                                    <IoAlertCircleSharp className='ml-auto mt-[-30px] mr-4' color='red' />
                                }

                            </div>
                            <div className='focus:outline-none rounded-md focus:border-blue-600 border-[0px] w-[100%] py-2 pl-4 max-md:mt-[-19px]'>
                                <h5 className='mb-[-10px] text-sm'>Email</h5>
                                <input
                                    type="text"
                                    placeholder="Email"
                                    style={inputStyle}
                                    className="focus:outline-none rounded-md focus:border-blue-600 mt-4 font-[500] pl-4 border-[#E5E7EB]"
                                    value={seeker?.email}
                                    readOnly
                                />

                            </div>
                        </div>
                        <div className="flex gap-6 max-md:flex-col">
                            <div className='focus:outline-none rounded-md focus:border-blue-600 border-[0px] w-[100%] py-2 pl-4'>
                                <h5 className='mb-[-10px] text-sm'>Gender</h5>
                                <select
                                    style={inputStyle}
                                    className="focus:outline-none rounded-md focus:border-blue-600 mt-4 font-[500] pl-4 border-[#E5E7EB] cursor-pointer"
                                    value={seekerData?.gender}
                                    onChange={(event) => setSeekerData({ ...seekerData, gender: event.target.value })}
                                >
                                    <option value="" disabled selected>Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Rather Not to Say">Rather Not to Say</option>
                                </select>
                                {seekerData?.gender ?
                                    ''
                                    :
                                    <IoAlertCircleSharp className='ml-auto mt-[-30px] mr-4' color='red' />
                                }
                            </div>
                            <div className='focus:outline-none rounded-md focus:border-blue-600 border-[0px] w-[100%] py-2 pl-4 max-md:mt-[-19px]' >
                                <h5 className='mb-[-10px] text-sm'>Date Of Birth</h5>
                                <input
                                    type="date"
                                    placeholder="Birthday"
                                    style={inputStyle}
                                    className="focus:outline-none rounded-md focus:border-blue-600 mt-4 font-[500] pl-4 pr-8 border-[#E5E7EB]"
                                    value={seekerData?.dob}
                                    onChange={(event) => setSeekerData({ ...seekerData, dob: event.target.value })}
                                />
                                {seekerData?.dob ?
                                    ''
                                    :
                                    <IoAlertCircleSharp className='ml-auto mt-[-30px] mr-10' color='red' />
                                }
                            </div>
                        </div>
                        <div className="flex gap-6 max-md:flex-col">
                            <div className='focus:outline-none rounded-md focus:border-blue-600 border-[0px] w-[100%] py-2 pl-4'>
                                <h5 className='mb-[-10px] text-sm'>Country</h5>
                                <p style={inputStyle} className="focus:outline-none rounded-md focus:border-blue-600 border-[1px] shadow-md w-[100%] mt-4 py-2 pl-4 cursor-pointer"
                                    onClick={() => toggleCountryVisibility()} >
                                    {countryName ? countryName : `Select Country`}
                                </p>
                                {seekerData?.country ?
                                    ''
                                    :
                                    <IoAlertCircleSharp className='ml-auto mt-[-30px] mr-4' color='red' />
                                }
                            </div>

                            <div className='focus:outline-none rounded-md focus:border-blue-600 border-[0px] w-[100%] py-2 pl-4 max-md:mt-[-19px]'>
                                <h5 className='mb-[-10px] text-sm'>City</h5>
                                <p style={inputStyle} onClick={() => toggleVisibility()}
                                    className="focus:outline-none rounded-md focus:border-blue-600 border-[1px] shadow-md w-[100%] mt-4 py-2 pl-4 cursor-pointer" >
                                    {cityName ? cityName : `Select City`} </p>
                                {seekerData?.city ?
                                    ''
                                    :
                                    <IoAlertCircleSharp className='ml-auto mt-[-30px] mr-4' color='red' />
                                }
                            </div>
                            <div className='focus:outline-none rounded-md focus:border-blue-600 border-[0px] w-[100%] py-2 pl-4 max-md:mt-[-19px]'>
                                <h5 className='mb-[-10px] text-sm'>Phone</h5>
                                <div className="focus:outline-none bg-[#efefef] rounded-md focus:border-blue-600 border-[1px] shadow-md w-[100%] mt-4 flex ">
                                    <p className='mt-[8px] font-[400] ml-4 cursor-pointer text-gray-500 text-[.9rem] ' onClick={() => togglePhoneVisible()} > {phoneCode ? phoneCode : `code`}</p>
                                    <p className='mt-[7px] px-4'>|</p>
                                    <input
                                        type="text"
                                        placeholder="Phone"
                                        className="bg-[#efefef] font-[400] pl-4 border-none outline-none text-gray-500 text-[.9rem]"
                                        value={seekerData?.phone}
                                        onChange={(e) => setSeekerData({ ...seekerData, phone: e.target.value })}
                                    />
                                    {seekerData?.phone ?
                                        ''
                                        :
                                        <IoAlertCircleSharp className='ml-auto mt-4 mr-4' color='red' />
                                    }
                                </div>
                            </div>

                        </div>
                        <div className='focus:outline-none rounded-md focus:border-blue-600 border-[0px] w-[100%] py-2 pl-4'>
                            <h5 className='mb-[-10px] text-sm'>Address</h5>
                            <input
                                type="text"
                                placeholder="Address"
                                style={inputStyle}
                                className="focus:outline-none rounded-md focus:border-blue-600 mt-4 font-[500] pl-4 border-[#E5E7EB] .placeholder-gray-100::placeholder text-gray-900"
                                value={seekerData?.address}
                                onChange={(event) => setSeekerData({ ...seekerData, address: event.target.value })}
                            />
                            {seekerData?.address ?
                                ''
                                :
                                <IoAlertCircleSharp className='ml-auto mt-[-30px] mr-4' color='red' />
                            }
                        </div>
                        <center>
                            <Button onClick={() => update()} className="uppercase border-2 rounded-xl !px-5 py-1 !bg-blue-600 !hover:bg-blue-800 !text-gray-100 !font-[500] !mt-4 !ml-[1rem] mr-auto cursor-pointer text-center"
                            > Update Profile</Button>
                            {seeker?.verified === 'false' ?
                                <Button onClick={() => toggleMail()} className="uppercase border-2 rounded-xl !px-5 py-1 !bg-blue-600 !hover:bg-blue-800 !text-gray-100 !font-[500] !mt-4 !ml-[1rem] mr-auto cursor-pointer text-center"
                                > Verify Profile</Button>
                                :
                                ''
                            }
                        </center>


                    </div>
                </div>
            }
            <Footer />
        </div>
    )
}

export default PersonalInfo
