import React, { useEffect, useState } from 'react';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import { fetchPlans, fetchProviderPlans } from '../API/index';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSeeker } from '../API/actions/seekerAction';
import { SESSION_SEEKER, SESSION_LOGIN } from '../Utils/Constant';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Pricing = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [login, setLogin] = useState(false);
    const [seekerData, setSeekerData] = useState();
    const [seekerPlans, setSeekerPlans] = useState([]);
    const [providerPlans, setProviderPlans] = useState([]);
    const [data, setData] = useState();
    const [activeTab, setActiveTab] = useState('seeker'); // Default tab is seeker

    const seeker = useSelector((state) => state.seeker.seeker);

    // Check login status and retrieve seeker data from session storage
    useEffect(() => {
        const isLogin = sessionStorage.getItem(SESSION_LOGIN);
        if (isLogin === "true") {
            setLogin(true);
            setSeekerData(JSON.parse(sessionStorage.getItem(SESSION_SEEKER)));
        } else {
            setLogin(false);
        }
    }, []);

    // Redirect if seeker already has a plan
    useEffect(() => {
        if (seeker?.plan > 0) {
            navigate('/');
        }
    }, [seeker, navigate]);

    // Fetch seeker and provider plans
    useEffect(() => {
        const fetchSeekerPlansData = async () => {
            try {
                const res = await fetchPlans();
                setSeekerPlans(res?.data?.data || []);
            } catch (error) {
                console.error('Error fetching seeker plans:', error);
            }
        };

        const fetchProviderPlansData = async () => {
            try {
                const res = await fetchProviderPlans();
                setProviderPlans(res?.data?.data || []);
            } catch (error) {
                console.error('Error fetching provider plans:', error);
            }
        };

        fetchSeekerPlansData();
        fetchProviderPlansData();
    }, []);

    useEffect(() => {
        setData(JSON.parse(sessionStorage.getItem(SESSION_SEEKER)));
    }, [seeker]);

    useEffect(() => {
        if (data?.id) {
            dispatch(fetchSeeker(data?.id));
        }
    }, [dispatch, data]);

    const handlePress = (value, nam, pur, Pname, accounttype, id) => {
        navigate('/checkout', { state: { price: value, name: nam, purpose: pur, Pname: Pname, accounttype: accounttype, id: id } });
    };

    const handleVerifyFirst = () => {
        toast.error('If you want to buy a plan, please verify first.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    };

    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            
            <section className="text-gray-600 body-font overflow-hidden min-h-screen">
                <div className="container px-5 py-6 pb-10 mx-auto">
                    <div className="flex flex-col text-center w-full mb-10">
                        <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">
                            Pricing
                        </h1>
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-500">
                            Upgrade your plan to unlock premium features and find better job opportunities on the website.
                        </p>
                    </div>

                    {/* Tabs for Seeker and Provider Plans */}
                    <div className="flex justify-center mb-6">
                        <button
                            className={`px-4 py-2 mx-2 text-lg font-semibold rounded ${activeTab === 'seeker' ? 'bg-blue-700 text-white' : 'bg-gray-200 text-gray-700'}`}
                            onClick={() => setActiveTab('seeker')}
                        >
                            I am a Jobseeker
                        </button>
                        {!login && (
                            <button
                                className={`px-4 py-2 mx-2 text-lg font-semibold rounded ${activeTab === 'provider' ? 'bg-blue-700 text-white' : 'bg-gray-200 text-gray-700'}`}
                                onClick={() => setActiveTab('provider')}
                            >
                                I am a Job Provider
                            </button>
                        )}
                    </div>

                    <div className="flex flex-wrap -m-4 justify-center">
                        {/* Seeker Plans */}
                        {activeTab === 'seeker' && seekerPlans.map((item) => (
                            <div key={item.id} className="p-4 xl:w-1/3 md:w-1/2 w-full">
                                <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden transition-transform duration-300 transform hover:scale-105">
                                    <h2 className="text-lg tracking-widest title-font mb-2 font-semibold text-indigo-600">{item.name}</h2>
                                    <h1 className="text-4xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none font-bold">${item.amount}</h1>
                                    <ul className="list-disc text-sm text-gray-600 mt-4 ml-1 mb-20">
                                        {item.purpose?.split(', ').map((sentence, index) => (
                                            <li key={index}>{sentence}</li>
                                        ))}
                                    </ul>
                                    {login ? (
                                        seeker?.verified === "true" ? (
                                            <button
                                                onClick={() =>
                                                    handlePress(item.amount, item.name, item.purpose, item.accounttype, item.name, item.id)
                                                }
                                                className="bg-blue-700 hover:bg-blue-900 text-white border-0 py-2 px-4 w-full rounded-lg transform transition-all duration-300"
                                            >
                                                Buy Now
                                            </button>
                                        ) : (
                                            <button
                                                onClick={() => navigate('/personalinfo')}
                                                className="bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-indigo-500 hover:to-purple-600 text-white border-0 py-2 px-4 w-full rounded-lg transform transition-all duration-300"
                                            >
                                                Verify Account First
                                            </button>
                                        )
                                    ) : (
                                        <button
                                            onClick={() => navigate('/login')}
                                            className="bg-blue-700 hover:bg-blue-900 text-white border-0 py-2 px-4 w-full rounded-lg transform transition-all duration-300"
                                        >
                                            Get Started
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}

                        {/* Provider Plans */}
                        {activeTab === 'provider' && providerPlans.map((item) => (
                            <div key={item.id} className="p-4 xl:w-1/3 md:w-1/2 w-full">
                                <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden transition-transform duration-300 transform hover:scale-105">
                                    <h2 className="text-lg tracking-widest title-font mb-2 font-semibold text-indigo-600">{item.name}</h2>
                                    <h1 className="text-4xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none font-bold">${item.amount}</h1>
                                    <ul className="list-disc text-sm text-gray-600 mt-4 ml-1 mb-20">
                                        {item.purpose?.split(', ').map((sentence, index) => (
                                            <li key={index}>{sentence}</li>
                                        ))}
                                    </ul>
                                    <button
                                        onClick={() => window.location.href = 'https://jobss.com.au/admin/providerLogin'}
                                        className="bg-blue-700 hover:bg-blue-900 text-white border-0 py-2 px-4 w-full rounded-lg transform transition-all duration-300"
                                    >
                                        Get Started
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Pricing;
