import React, { useEffect, useState } from 'react'
import { registerProvider } from '../API'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { BsFillPeopleFill } from 'react-icons/bs'
import { AiFillEye, AiFillEyeInvisible, AiOutlineMail } from 'react-icons/ai'
import { RiLockPasswordLine } from 'react-icons/ri'
import { AllCities } from '../API/actions/citiesAction'
import { AllCountries } from '../API/actions/countriesActions'
import { useDispatch, useSelector } from 'react-redux'
import CitySelectModal from '../components/ProfileModals/CitySelectModal'
import CountryModal from '../components/CountryModal'
import { FaCity } from "react-icons/fa";
import { GoPeople } from "react-icons/go";
import { GrOrganization } from 'react-icons/gr'
import { MdAccountBalance } from "react-icons/md";


const ProviderRegister = () => {

    const dispatch = useDispatch()

    const [cityVisible, setCityVisible] = useState(false)
    const toggleVisibility = () => setCityVisible(!cityVisible)

    const [data, setData] = useState({ name: '', size: '', email: '', password: '', confirmpassword: '', city: '', country: '' })

    const cities = useSelector(state => state.city.cities)
    const countries = useSelector(state => state.country.countries)

    const [country, setCountry] = useState()
    const [citiesData, setCitiesData] = useState()

    const [countryVisible, setCountryVisible] = useState(false)
    const toggleCountryVisibility = () => setCountryVisible(!countryVisible)

    const [cityName, setNameCity] = useState('')
    const [countryName, setCountryName] = useState('')

    const navigate = useNavigate()

    const handleChange = (e) => {
        setData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    console.log('data', data);

    const RegisterUser = () => {
        if (data?.name?.length >= 2) {

            if (data?.city) {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data?.email)) {
                    toast.error('Please Enter a Valid Email Address', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    if (data?.password?.length >= 4) {
                        if (data?.password === data.confirmpassword) {
                            registerProvider(data?.name, data?.size, data?.city, data?.country, data?.email, '', '', '', '', data?.password, '').then(res => {
                                // console.log('register response', res);
                                toast.success('Successfully Registered', {
                                    position: "top-right",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTimeout(() => {
                                    navigate('/');
                                }, 1500);
                                const { data: { responseCode } } = res;
                                if (responseCode === 200) {
                                    setShow(true)
                                }
                            });
                        } else {
                            toast.error('Password Not Match', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                        }
                    } else {
                        toast.error('Please Enter a Strong Password', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }
            } else {
                toast.error('Please Enter a city', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else {
            toast.error('Please Enter a Valid Name', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }
    }

    const [showPassword, setShowPassword] = useState(false)

    const handlePassword = () => {
        setShowPassword(!showPassword)
        typeFunction()
    }

    const typeFunction = () => {
        let x = document.getElementById("Input")
        if (x.type === "password") {
            x.type = "text"
        } else {
            x.type = "password"
        }
    }

    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const handleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
        ConfirmtypeFunction()
    }

    const ConfirmtypeFunction = () => {
        let x = document.getElementById("InputConfirm")
        if (x.type === "password") {
            x.type = "text"
        } else {
            x.type = "password"
        }
    }
    const [show, setShow] = useState(false)

    const handleVisible = () => {
        setShow(!show)
    }
    useEffect(() => {
        dispatch(AllCities())
        dispatch(AllCountries())
    }, [dispatch,]);



    const cityClick = (item) => {
        setData({ ...data, city: item.id })
        toggleVisibility()
        setNameCity(item.name)
    }

    const countryClick = (value) => {
        setData({ ...data, country: value?.id })
        setCountry(value?.id)
        toggleCountryVisibility()
        setCountryName(value?.name)
    }
    useEffect(() => {
        const searched = cities?.filter((data) => {
            return data.country === country
        })
        setCitiesData(searched)
    }, [country]);




    return (
        <div>
            <div>
                <CitySelectModal visible={cityVisible} toggleVisibility={toggleVisibility} list={citiesData} click={cityClick} />
                <CountryModal visible={countryVisible} toggleVisibility={toggleCountryVisibility} list={countries} click={countryClick} />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <div>
                    <div className='w-[100%] h-[24rem] bg-blue-600'>
                    </div>
                    <div className=' bg-gray-400 '>
                        <div className="flex bg-white py-10 rounded-xl w-[60%] relative top-[-250px] m-auto ">
                            <div className="w-[50%] mt-[10%] max-md:hidden">
                                <img src="./assets/panelLogin.png" alt="" className="w-160 " />
                            </div>
                            <center className="w-[40%] max-md:w-[90%] max-md:m-auto">
                                <div className=' p-[10px]'><br />
                                    <h1 className='text-[22px]'>Welcome to Jobs.com.au!</h1><br />
                                    <h1 className='text-[13px] text-gray-600 font-[600]'>Join us today and unlock a world of
                                        possibilities.</h1><br />
                                    <input onChange={handleChange} type="text" name='name' placeholder='Comapny name'
                                        className='shadow-lg rounded-[12px] text-[.9rem] text-gray-700 font-[500] w-[100%] max-md:w-[100%] pl-[50px] border-2 pt-[7px] pb-[7px]' /><br />
                                    <div className="relative top-[-1.9rem] left-[-44%] w-[10%]"><GrOrganization />
                                    </div>
                                    <input onChange={handleChange} type="text" name='size' placeholder='Company size'
                                        className='shadow-lg rounded-[12px] text-[.9rem] text-gray-700 font-[500] w-[100%] max-md:w-[100%] pl-[50px] border-2 pt-[7px] pb-[7px]' /><br />
                                    <div className="relative top-[-1.9rem] left-[-44%] w-[10%]"><GoPeople />
                                    </div>
                                    <input onChange={handleChange} type="email" name="email" placeholder='Email'
                                        className='shadow-lg rounded-[12px] text-[.9rem] text-gray-700 font-[500] w-[100%] max-md:w-[100%] pl-[50px] border-2 pt-[7px] pb-[7px]' /><br />
                                    <div className="relative top-[-1.9rem] left-[-44%] w-[10%]"><AiOutlineMail />
                                    </div>
                                    <p className='shadow-lg rounded-[12px] text-[.9rem] text-gray-700 font-[500] w-[100%] max-md:w-[100%] pl-[50px] border-2 border-gray-700 pt-[7px] pb-[7px] text-left'
                                        onClick={() => toggleCountryVisibility()} >
                                        {countryName ? countryName : `Select Country`}
                                    </p>
                                    <div className="relative top-[-1.9rem] left-[-44%] w-[10%]"><MdAccountBalance />
                                    </div>
                                    <p onClick={() => toggleVisibility()}
                                        className='shadow-lg rounded-[12px] text-[.9rem] text-gray-700 font-[500] w-[100%] max-md:w-[100%] pl-[50px] border-2 border-gray-700 pt-[7px] pb-[7px] text-left' >
                                        {cityName ? cityName : `Select City`} </p>
                                    <div className="relative top-[-1.9rem] left-[-44%] w-[10%]"><FaCity />
                                    </div>
                                    <input onChange={handleChange} type="password" name="password" id='Input' placeholder='Password'
                                        className='shadow-lg rounded-[12px] text-[.9rem] text-gray-700 font-[500] w-[100%] max-md:w-[100%] pl-[50px] border-2 pt-[7px] pb-[7px]' /><br />
                                    <div className="relative top-[-1.9rem] left-[-44%] w-[10%]"><RiLockPasswordLine />
                                    </div>
                                    {showPassword ?
                                        <div className="relative top-[-2.9rem] left-[44%] w-[10%] text-[1.4rem]" onClick={() => handlePassword()}> <AiFillEye />
                                        </div>
                                        :
                                        <div className="relative top-[-2.9rem] left-[44%] w-[10%] text-[1.4rem]" onClick={() => handlePassword()}> <AiFillEyeInvisible />
                                        </div>
                                    }
                                    <div className='mt-[-1.4rem]'>
                                        <input onChange={handleChange} type="password" name="confirmpassword" id='InputConfirm' placeholder='Confirm Password'
                                            className='shadow-lg rounded-[12px] text-[.9rem] text-gray-700 font-[500] w-[100%] max-md:w-[100%] pl-[50px] border-2 pt-[7px] pb-[7px]' /><br />
                                        <div className="relative top-[-1.9rem] left-[-44%] w-[10%]"><RiLockPasswordLine />
                                        </div>
                                        {showConfirmPassword ?
                                            <div className="relative top-[-2.9rem] left-[44%] w-[10%] text-[1.4rem]" onClick={() => handleConfirmPassword()}> <AiFillEye />
                                            </div>
                                            :
                                            <div className="relative top-[-2.9rem] left-[44%] w-[10%] text-[1.4rem]" onClick={() => handleConfirmPassword()}> <AiFillEyeInvisible />
                                            </div>
                                        }
                                    </div>

                                    <input onClick={() => RegisterUser()} type='submit' value='Sign Up'
                                        className='bg-blue-600 text-white cursor-pointer font-[600] px-10 py-[5px] w-[100%] rounded-full mt-6' />
                                    <div className="flex gap-2 mt-4 justify-center">
                                    </div>
                                    <span> <a  className='text-[17px]'>Already have an account? </a> <a  href='https://jobss.com.au/admin/providerLogin'
                                        className='text-blue-500'>Sign In</a><br /> </span><br />
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProviderRegister
