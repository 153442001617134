import { Modal } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import CareerModal from './ResumeModals/CareerModal'
import { cvStatement, deleteCVCareer, deleteCVCourse, deleteCVEducation, deleteCVInterest, deleteCVLanguage, deleteCVSkill, roleUpdate, updateCVCareer, updateCVCourse, updateCVEducation, updateCVInterest, updateCVLanguage, updateCVSkill } from '../API'
import { CVCareer, CVCourse, CVEducation, CVInterest, CVLanguage, CVSkill, getCvByUser } from '../API/actions/cvAction'
import { useDispatch, useSelector } from 'react-redux'
import { SESSION_SEEKER } from '../Utils/Constant'
import CourseModal from './ResumeModals/CourseModal'
import EducationModal from './ResumeModals/EducationModal'
import SkillModal from './ResumeModals/SkillModal'
import InterestModal from './ResumeModals/InterestModal'
import LanguageModal from './ResumeModals/LanguageModal'
import RoleModal from './ResumeModals/RoleModal'
import PersonalStatementModal from './ResumeModals/PersonalStatementModal';
import { AiFillEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import DeleteModal from './DeleteModal'
import TopBar from './TopBar'
import Footer from './Footer'
import { useNavigate } from 'react-router-dom'




const ResumeForm = ({ }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const cv = useSelector(state => state.cv.cvs)

    console.log('cv', cv);



    const [seekerData, setSeekerData] = useState()

    useEffect(() => {
        if (!seekerData) {
            setSeekerData(JSON.parse(sessionStorage.getItem(SESSION_SEEKER)));
        }
    }, [seekerData])

    // console.log('seekerData', seekerData);

    const [roleData, setRoleData] = useState(cv?.role)
    const [personalInfo, setPersonalInfo] = useState()
    const [educationVisible, setEducationVisible] = useState(false)
    const [careerVisible, setCareerVisible] = useState(false)
    const [courseVisible, setCourseVisible] = useState(false)
    const [skillVisible, setSkillVisible] = useState(false)
    const [interestVisible, setInterestVisible] = useState(false)
    const [languageVisible, setLanguageVisible] = useState(false)
    const [deleteVisible, setDeleteVisible] = useState(false)
    const [edData, setEdData] = useState(null)
    const [carData, setCarData] = useState(null)
    const [couData, setCouData] = useState(null)
    const [inData, setInData] = useState(null)
    const [lanData, setLanData] = useState(null)
    const [resData, setResData] = useState(null)
    const [skData, setSkData] = useState(null)
    const [delV, setDelV] = useState()
    const [delID, setDelID] = useState()
    const [delVal, setDelVal] = useState('')
    const [delLoad, setDelLoad] = useState(false)
    const [trigger, setTrigger] = useState(false)
    const [isloading, setIsLoading] = useState(true)
    const [ID, setID] = useState()
    const [infoVisible, setInfoVisible] = useState(false)
    const [roleVisible, setRoleVisible] = useState(false)


    const toggleEducationVisibility = () => setEducationVisible(!educationVisible);
    const toggleCareerVisibility = () => setCareerVisible(!careerVisible);
    const toggleCourseVisibility = () => setCourseVisible(!courseVisible);
    const toggleSkillVisibility = () => setSkillVisible(!skillVisible)
    const toggleInterestVisibility = () => setInterestVisible(!interestVisible)
    const toggleLanguageVisibility = () => setLanguageVisible(!languageVisible)

    const toggleRoleVisibility = () => setRoleVisible(!roleVisible)
    const toggleInfoVisibility = () => setInfoVisible(!infoVisible)

    const toggleDeleteVisibile = (val, id) => {
        setDelV(val)
        setDelID(id)
        setDeleteVisible(!deleteVisible)
    }


    // career ==============
    const addCareer = (company, job, timeperiod, address) => {
        dispatch(CVCareer(cv?.id, company, job, timeperiod, address))

    }

    const updateCareer = async (company, job, timeperiod, address, phone, id) => {
        await updateCVCareer(cv?.id, company, job, timeperiod, address, phone, id).then(res => {
            setTrigger(!trigger)
            setCarData(null)
        })
    }

    const editCareer = (d) => {
        setCarData(d)
        toggleCareerVisibility()
        setTrigger(!trigger)
    }

    const deleteCareer = async (id) => {
        await deleteCVCareer(id).then(res => {
            setTrigger(!trigger)
            setDelVal('')
            toggleDeleteVisibile('', 0)
            setDelLoad(false)
            console.log('delete career ', res);
        })
    }


    // course ==================
    const addCourse = (course, timeperiod, institute) => {
        dispatch(CVCourse(cv?.id, course, timeperiod, institute))
        // setTrigger(!trigger)
    }

    const updateCourse = async (course, timeperiod, institute, id) => {
        await updateCVCourse(cv?.id, course, timeperiod, institute, id).then(res => {
            setTrigger(!trigger)
            setCouData(null)
        })
    }
    const editCourse = (d) => {
        setCouData(d)
        toggleCourseVisibility()
    }
    const deleteCourse = async (id) => {
        await deleteCVCourse(id).then(res => {
            setTrigger(!trigger)
            setDelVal('')
            toggleDeleteVisibile('', 0)
            setDelLoad(false)
        })
    }

    // education ===============
    const addEducation = (qualification, timeperiod, institute) => {
        dispatch(CVEducation(cv?.id, qualification, timeperiod, institute))

    }

    const updateEducation = async (qualification, timeperiod, institute, id) => {
        await updateCVEducation(cv?.id, qualification, timeperiod, institute, id).then(res => {
            setTrigger(!trigger)
            setEdData(null)
        })
    }
    const editEducation = (d) => {
        setEdData(d)
        toggleEducationVisibility()
    }

    const deleteEducation = async (id) => {
        await deleteCVEducation(id).then(res => {
            setTrigger(!trigger)
            setDelVal('')
            toggleDeleteVisibile('', 0)
            setDelLoad(false)
        })
    }


    // skills================

    const addSkill = (skill) => {
        dispatch(CVSkill(cv?.id, skill))

    }

    const updateSkill = async (skill, id) => {
        await updateCVSkill(cv?.id, skill, id).then(res => {
            setTrigger(!trigger)
            setSkData(null)
        })
    }

    // interest ===================
    const addInterest = (interest) => {
        dispatch(CVInterest(cv?.id, interest))

    }

    const updateInterest = async (interest, id) => {
        await updateCVInterest(cv?.id, interest, id).then(res => {
            setTrigger(!trigger)
            setInData(null)
        })
    }
    const deleteInterest = async (id) => {
        await deleteCVInterest(id).then(res => {
            setTrigger(!trigger)
            setDelVal('')
            toggleDeleteVisibile('', 0)
            setDelLoad(false)
        })
    }


    // languages =======================
    const addLanguage = (language) => {
        dispatch(CVLanguage(cv?.id, language))

    }

    const updateLanguage = async (language, id) => {
        await updateCVLanguage(cv?.id, language, id).then(res => {
            setTrigger(!trigger)
            setLanData(null)
        })
    }
    const deleteLanguage = async (id) => {
        await deleteCVLanguage(id).then(res => {
            setTrigger(!trigger)
            setDelVal('')
            toggleDeleteVisibile('', 0)
            setDelLoad(false)
        })
    }


    const editInterest = (d) => {
        setInData(d)
        toggleInterestVisibility()
    }

    const editLanguage = (d) => {
        setLanData(d)
        toggleLanguageVisibility()
    }

    const editSkill = (d) => {
        setSkData(d)
        toggleSkillVisibility()
    }
    const deleteSkill = async (id) => {
        await deleteCVSkill(id).then(res => {
            setTrigger(!trigger)
            setDelVal('')
            toggleDeleteVisibile('', 0)
            setDelLoad(false)
        })
    }


    // role ==============

    const addRole = async (role) => {
        await roleUpdate(role, seekerData?.id).then(res => {
            setRoleData(role)
            console.log('role', res)
            setTrigger(!trigger)
        }).catch(err => {
            console.log(err)
        })
    }

    // statement ================
    const addPersonalInfo = async (statement) => {
        await cvStatement(cv?.id, statement).then(res => {
            setTrigger(!trigger)
            setPersonalInfo(seekerData?.statement)
            console.log('statement', res)
        })
    }


    useEffect(() => {
        if (delVal === "EDUCATION") {
            deleteEducation(delID)
        } else if (delVal === "CAREER") {
            deleteCareer(delID)
        } else if (delVal === "COURSE") {
            deleteCourse(delID)
        } else if (delVal === "SKILL") {
            deleteSkill(delID)
        } else if (delVal === "INTEREST") {
            deleteInterest(delID)
        } else if (delVal === "LANGUAGE") {
            deleteLanguage(delID)
        }
    }, [delVal]);


    useEffect(() => {

        dispatch(getCvByUser(seekerData?.id))

    }, [seekerData?.id, trigger])


    const [data, setdata] = useState('');

    useEffect(() => {
        if (roleData) {
            setdata(roleData)
        } else {
            setdata(cv?.role)
        }
    }, [roleData, cv])

    return (
        <div className=''>

            <DeleteModal visible={deleteVisible} toggleVisibility={toggleDeleteVisibile} del={setDelVal} val={delV} setLoad={setDelLoad} isLoad={delLoad} />

            <CareerModal visible={careerVisible} toggleCareerVisibility={toggleCareerVisibility} add={addCareer} edit={updateCareer} data={carData} />

            <CourseModal visible={courseVisible} toggleCourseVisibility={toggleCourseVisibility} add={addCourse} edit={updateCourse} data={couData} />

            <EducationModal visible={educationVisible} toggleEducationVisibility={toggleEducationVisibility} add={addEducation} edit={updateEducation} data={edData} />

            <SkillModal visible={skillVisible} toggleSkillVisibility={toggleSkillVisibility} add={addSkill} edit={updateSkill} data={skData} />

            <InterestModal visible={interestVisible} toggleInterestVisibility={toggleInterestVisibility} add={addInterest} edit={updateInterest} data={inData} />

            <LanguageModal visible={languageVisible} toggleLanguageVisibility={toggleLanguageVisibility} add={addLanguage} edit={updateLanguage} data={lanData} />

            <RoleModal visible={roleVisible} toggleRoleVisibility={toggleRoleVisibility} add={addRole} data={roleData} />

            <PersonalStatementModal visible={infoVisible} toggleInfoVisibility={toggleInfoVisibility} add={addPersonalInfo} data={seekerData?.statement} />


            <div className='p-10 px-20  max-md:p-2 max-md:px-4'>

                <form className="flex flex-col rounded-xl border-4 p-20 bg-gray-200 max-md:p-4">

                    <div>
                        <p className="text-gray-900 font-[500] text-[1.2rem] text-center mb-4 ">
                            Role
                        </p>

                        <div className='w-[40%] m-auto max-md:w-[80%]'>
                            <p
                                className="rounded-md focus:outline-none text-gray-600 border-4  m-auto p-4 bg-white border-white" >
                                {data ? data : `Add Role`}
                            </p>
                            <AiFillEdit className='ml-auto mr-4 mt-[-40px] cursor-pointer' onClick={toggleRoleVisibility} />
                        </div>

                    </div>
                    <div>

                        <p className=" text-gray-900 font-[500] text-left w-[85%] ml-auto text-[1.2rem] mt-10 mb-4 max-md:w-[100%]">
                            Personal Statement
                        </p>

                        <div className='w-[70%] m-auto max-md:w-[100%] ' >
                            <p
                                className="rounded-md focus:outline-none text-gray-600 border-4  m-auto p-4 bg-white border-white max-md:text-[.9rem] max-md:p-2 " >
                                {cv?.statement ? cv?.statement : `Enter Personal Statement`}
                            </p>
                            <AiFillEdit className='ml-auto mr-4 mt-[-40px] cursor-pointer' onClick={toggleInfoVisibility} />
                        </div>

                    </div>

                    <div className="mt-8">
                        <p className="  text-gray-900 font-[500] text-left w-[85%] ml-auto text-[1.2rem] mt-4 max-md:w-[100%]">
                            Education
                        </p>
                        <div className='flex justify-end  w-[85%] max-md:w-[100%]'>
                            <p onClick={() => editEducation({ status: 1 })} className=' text-blue-700 underline px-4 pt-[4px] text-[0.9rem] rounded-xl mb-2 cursor-pointer ml-auto ' >Add More</p>
                        </div>
                        {cv?.educations?.length > 0 ?
                            <div className='grid grid-cols-2 gap-4 w-[70%] m-auto max-md:w-[100%] max-md:grid-cols-1 '  >
                                {cv?.educations?.map((value) => (
                                    <div className="flex rounded-md focus:outline-none text-gray-600 border-4  p-4 justify-between bg-white border-white max-md:text-[.9rem] max-md:p-2">
                                        <div>
                                            <p className="text-gray-600 text-[1.3rem] max-md:text-[.9rem]" >
                                                {value?.qualification ? value?.qualification : `Add Your Education`} </p>
                                            <p className='text-gray-800 font-[600] max-md:text-[.9rem]'>{value?.institute}</p>
                                            <p className='text-[.9rem] '>{value?.timeperiod}</p>
                                        </div>

                                        <div className='flex gap-6' >
                                            <AiFillEdit size={20} onClick={() => editEducation({ id: value.id, degree: value.qualification, institute: value.institute, timeperiod: value.timeperiod, status: 0 })} className='cursor-pointer' />
                                            <MdDelete size={20} onClick={() => toggleDeleteVisibile('EDUCATION', value?.id)} className='cursor-pointer' />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            <p className='text-center text-gray-600 font-[600] '>no education added</p>
                        }
                    </div>

                    <div className="mt-8">
                        <p className="  text-gray-900 font-[500] text-left w-[85%] ml-auto text-[1.2rem] mt-4 max-md:w-[100%]">
                            Career
                        </p>
                        <div className='flex justify-end  w-[85%] max-md:w-[100%]'>
                            <p onClick={() => editCareer({ status: 1 })} className=' text-blue-700 underline px-4 pt-[4px] text-[0.9rem] rounded-xl mb-2 cursor-pointer ml-auto ' >Add More</p>
                        </div>
                        {cv?.careers?.length > 0 ?
                            <div className='grid grid-cols-2 gap-4 w-[70%] m-auto max-md:w-[100%] max-md:grid-cols-1 ' >
                                {cv?.careers?.map((value) => (
                                    <div className="flex rounded-md focus:outline-none text-gray-600 border-4  p-4 justify-between bg-white border-white max-md:p-2">
                                        <div>
                                            <p className="text-gray-600 text-[1.3rem] max-md:text-[.9rem]" >
                                                {value?.job ? value?.job : `Add Your job`} </p>
                                            <p className='text-gray-800 font-[600] max-md:text-[.9rem]'>{value?.company}</p>
                                            <p className='text-[.9rem] '>{value?.timeperiod}</p>
                                        </div>

                                        <div className='flex gap-6' >
                                            <AiFillEdit size={20} onClick={() => editCareer({ company: value.company, job: value.job, timeperiod: value.timeperiod, address: value.address, phone: value.phone, id: value.id, status: 0 })} className='cursor-pointer' />
                                            <MdDelete size={20} onClick={() => toggleDeleteVisibile('CAREER', value?.id)} className='cursor-pointer' />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            <p className='text-center text-gray-600 font-[600] '>no career added</p>
                        }

                    </div>

                    <div className="mt-8">
                        <p className="  text-gray-900 font-[500] text-left w-[85%] ml-auto text-[1.2rem] mt-4 max-md:w-[100%]">
                            Courses
                        </p>
                        <div className='flex justify-end  w-[85%] max-md:w-[100%]'>
                            <p onClick={() => editCourse({ status: 1 })} className=' text-blue-700 underline px-4 pt-[4px] text-[0.9rem] rounded-xl mb-2 cursor-pointer ml-auto ' >Add More</p>
                        </div>
                        {cv?.courses?.length > 0 ?
                            <div className='grid grid-cols-2 gap-4 w-[70%] m-auto  max-md:w-[100%] max-md:grid-cols-1' >
                                {cv?.courses?.map((value) => (
                                    <div className="flex rounded-md focus:outline-none text-gray-600 border-4  p-4 justify-between bg-white border-white max-md:p-2">
                                        <div>
                                            <p className="text-gray-600 text-[1.3rem] max-md:text-[.9rem]" >
                                                {value?.course ? value?.course : `Add Your Course`} </p>
                                            <p className='text-gray-800 font-[600] max-md:text-[.9rem]'>{value?.institute}</p>
                                            <p className='text-[.9rem] '>{value?.timeperiod}</p>
                                        </div>

                                        <div className='flex gap-6' >
                                            <AiFillEdit size={20} onClick={() => editCourse({ course: value.course, timeperiod: value.timeperiod, institute: value.institute, id: value.id, status: 0 })} className='cursor-pointer' />
                                            <MdDelete size={20} onClick={() => toggleDeleteVisibile('COURSE', value?.id)} className='cursor-pointer' />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            <p className='text-center text-gray-600 font-[600] '>no course added</p>
                        }
                    </div>


                    <div className="mt-8">
                        <p className="  text-gray-900 font-[500] text-left w-[85%] ml-auto text-[1.2rem] mt-4 max-md:w-[100%]">
                            Skills
                        </p>
                        <div className='flex justify-end  w-[85%] max-md:w-[100%]'>
                            <p onClick={() => editSkill({ status: 1 })} className=' text-blue-700 underline px-4 pt-[4px] text-[0.9rem] rounded-xl mb-2 cursor-pointer ml-auto ' >Add More</p>
                        </div>
                        {cv?.skills?.length > 0 ?
                            <div className='grid grid-cols-2 gap-4 w-[70%] m-auto   max-md:w-[100%] max-md:grid-cols-1 ' >
                                {cv?.skills?.map((value) => (
                                    <div className="flex rounded-md focus:outline-none text-gray-600 border-4  p-4 justify-between bg-white border-white max-md:p-2">
                                        <div>
                                            <p className="text-gray-600 text-[1.3rem] max-md:text-[.9rem]" >
                                                {value?.skill ? value?.skill : `Add Your skill`} </p>

                                        </div>

                                        <div className='flex gap-6' >
                                            <AiFillEdit size={20} onClick={() => editSkill({ skill: value.skill, id: value.id, status: 0 })} className='cursor-pointer' />
                                            <MdDelete size={20} onClick={() => toggleDeleteVisibile('SKILL', value?.id)} className='cursor-pointer' />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            <p className='text-center text-gray-600 font-[600] '>no skill added</p>
                        }
                    </div>



                    <div className="mt-8">
                        <p className="  text-gray-900 font-[500] text-left w-[85%] ml-auto text-[1.2rem] mt-4 max-md:w-[100%]">
                            Interest
                        </p>
                        <div className='flex justify-end  w-[85%] max-md:w-[100%] '>
                            <p onClick={() => editInterest({ status: 1 })} className=' text-blue-700 underline px-4 pt-[4px] text-[0.9rem] rounded-xl mb-2 cursor-pointer ml-auto ' >Add More</p>
                        </div>
                        {cv?.interests?.length > 0 ?
                            <div className='grid grid-cols-2 gap-4 w-[70%] m-auto  max-md:w-[100%] max-md:grid-cols-1' >
                                {cv?.interests?.map((value) => (
                                    <div className="flex rounded-md focus:outline-none text-gray-600 border-4  p-4 justify-between bg-white border-white max-md:p-2">
                                        <div>
                                            <p className="text-gray-600 text-[1.3rem] max-md:text-[.9rem]" >
                                                {value?.interest ? value?.interest : `Add Your interest`} </p>

                                        </div>

                                        <div className='flex gap-6' >
                                            <AiFillEdit size={20} onClick={() => editInterest({ interest: value.interest, id: value.id, status: 0 })} className='cursor-pointer' />
                                            <MdDelete size={20} onClick={() => toggleDeleteVisibile('INTEREST', value?.id)} className='cursor-pointer' />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            <p className='text-center text-gray-600 font-[600] '>no interest added</p>
                        }
                    </div>

                    <div className="mt-8">
                        <p className=" text-gray-900 font-[500] text-left w-[85%] max-md:w-[100%] ml-auto text-[1.2rem]">
                            Languages
                        </p>
                        <div className='flex justify-end  w-[85%] max-md:w-[100%] '>
                            <p onClick={() => editLanguage({ status: 1 })} className=' text-blue-700 underline px-4 pt-[4px] text-[0.9rem] rounded-xl mb-2 cursor-pointer ml-auto ' >Add More</p>
                        </div>
                        {cv?.languages?.length > 0 ?
                            <div className='grid grid-cols-2 gap-4 w-[70%] m-auto max-md:w-[100%] max-md:grid-cols-1' >
                                {cv?.languages?.map((value) => (
                                    <div className="flex rounded-md focus:outline-none text-gray-600 border-4  p-4 justify-between bg-white border-white max-md:p-2">
                                        <div>
                                            <p className="text-gray-600 text-[1.3rem] max-md:text-[.9rem]" >
                                                {value?.language ? value?.language : `Add Your language`} </p>

                                        </div>

                                        <div className='flex gap-6' >
                                            <AiFillEdit size={20} onClick={() => editLanguage({ language: value.language, id: value.id, status: 0 })} className='cursor-pointer' />
                                            <MdDelete size={20} onClick={() => toggleDeleteVisibile('LANGUAGE', value?.id)} className='cursor-pointer' />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            <p className='text-center text-gray-600 font-[600] '>no language added</p>
                        }
                    </div>

                    <input
                        type="submit"
                        value="Create Resume"
                        onClick={() => navigate('/resume')}
                        className="uppercase border-2 rounded-xl px-6 py-1 bg-blue-600 hover:bg-blue-800 text-gray-100 font-[500] mt-5 ml-auto mr-auto cursor-pointer"
                    />
                </form>

            </div>
            <Footer />
        </div>
    )
}

export default ResumeForm
