import React from 'react'

const Verify = () => {
    return (
        <>
            <div>
                <div className='w-[100%] h-[21rem] bg-blue-600'>
                </div>
                <div className='w-[100%] h-screen bg-gray-400'>
                    <center>
                        <div className='bg-white rounded-md p-[10px] w-[40%] text-[18px] relative top-[-210px]'>
                            <br/><br/>
                            <h1 className='text-[29px] font-normal'>Change Password</h1><br/><br/>
                            <input type="text" placeholder='Enter Your Phone Numberr'
                                   className='shadow-lg border-slate-100 rounded-[12px] pr-[176px] border-[1px] pt-[11px] pb-[11px]'/><br/><br/>
                            <input type="email" placeholder='Enter Your Email'
                                   className='shadow-lg border-slate-100	 rounded-[12px] pr-[176px] border-[1px] pt-[11px] pb-[11px]'/><br/><br/><br/><br/><br/>
                            <button
                                className='pl-[140px] pr-[140px] rounded-[30px] text-[30px] bg-blue-600 text-white pt-[6px] pb-[6px]'>
                                Send
                            </button>
                            <br/><br/>
                        </div>
                    </center>
                </div>
            </div>
        </>
    )
}

export default Verify
