import React, { useEffect, useState } from 'react'
import TopBar from '../components/TopBar'
import { getJobByCategory, getJobByCompany } from '../API/actions/jobsAction'
import { useDispatch, useSelector } from 'react-redux'
import LoginRequireModal from '../components/LoginRequireModal'
import { useLocation, useNavigate } from 'react-router-dom'
import Footer from '../components/Footer'
import { ClipLoader } from 'react-spinners'
import ProviderModal from '../components/ProviderModal'

const Jobs = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const id = location.state.ID
    const ids = location.state.IDD
    const name = location.state?.Name
    console.log('name', name);

    useEffect(() => {
        console.log('id', ids)
    }, [ids])

    const category = useSelector(state => state.job.jobs)
    const loading = useSelector(state => state.loading.categoryJobLoading)

    useEffect(() => {
        if (id) {
            dispatch(getJobByCategory(id))
        }
    }, [id])


    useEffect(() => {
        if (ids) {
            dispatch(getJobByCompany(ids))
        }
    }, [ids])

    useEffect(() => {
        console.log('category', category)
    }, [category])


    const [requireVisible, setRequireVisible] = useState(false)
    const toggleVisible = () => setRequireVisible(!requireVisible)


    const [visible, setVisible] = useState(false)
    const [jobId, setJobId] = useState('')
    const toggleVisibility = (id) => {
        setVisible(!visible)
        setJobId(id)
    }
    return (
        <div>
            {loading ?
                <div className='flex justify-center mt-[20%]'>
                    <ClipLoader size='5rem' />
                </div>
                :
                <>

                    <LoginRequireModal visible={requireVisible} toggleVisible={toggleVisible} />
                    <ProviderModal visible={visible} toggleVisibility={toggleVisibility} ID={jobId}
                        navigate={navigate} />


                    <div className=' py-10 min-h-screen'>
                        <center>
                            <h1 className='uppercase text-[40px] font-[400]  max-md:text-[1.5rem]'>{name ? name : `Jobs`}</h1>
                            <h1 className=' text-[#999] text-[16px] font-[400]  max-md:w-[80%]  '>The Most Comprehensive
                                Search Engine For Jobs.</h1>
                            <hr className='w-[3rem] h-2 bg-green-400 rounded-xl mt-4' />
                        </center>

                        <div className='w-[70%] m-auto'>
                            {category?.length > 0 ?


                                <div className='grid grid-cols-2 max-md:grid-cols-1 gap-5 m-auto mt-5 '>
                                    {category?.map((value) => (
                                        <div onClick={() => toggleVisibility(value.id)}
                                            className='border-2 rounded-xl bg-white hover:shadow-xl hover:shadow-gray-400 cursor-pointer pb-8 pt-4 transition duration-300'>
                                            {/* <p className='bg-[#ccf0d0] rounded-md px-2 ml-4 text-[#207a00]'>{value.status}</p> */}
                                            <h1 className='font-[600] ml-4 w-[80%]'>{value.title}</h1>
                                            <h1 className='ml-4 font-[500] my-2'>{value.company_name}</h1>
                                            <button className='bg-gray-200 rounded-md py-2 font-[600] px-2 ml-4 mb-2 '>{value.type}</button>
                                            <div className='flex'>
                                                <p className='ml-4 font-[400]'>{value.category_name}</p>
                                                <p className='ml-auto mr-4 font-[400]'>{value.qualification}</p>
                                            </div>
                                            <h1 className='text-[1.1rem] max-md:text-[.9rem] mt-2 text-center text-blue-600 font-[600]'>{value.city_name}, {value.country_name}</h1>
                                        </div>
                                    ))}
                                </div>
                                :
                                <p className='text-center text-[2rem] mt-10 text-gray-700 font-[600]'>No Jobs</p>
                            }
                        </div>

                    </div>
                    <Footer />
                </>}
        </div>
    )
}

export default Jobs
