import { Modal } from 'flowbite-react'
import React from 'react'

const CitySelectModal = ({ visible, click, list, toggleVisibility }) => {
    return (
        <div>
            <Modal show={visible} size={'2xl'} onClose={() => toggleVisibility()}>
                <Modal.Header> Select City</Modal.Header>
                <Modal.Body className='  bg-white  max-md:ml-0'>


                    {list?.length > 0 ?
                        <ul style={{ margin: '0', padding: '0', listStyle: 'none', }} className='min-h-[500px]'>
                            {list?.map((item, index) => (
                                <li key={index}>
                                    <div onClick={() => click(item)}>
                                        <div style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer'
                                        }}>
                                            <span style={{
                                                fontSize: '15px',
                                                fontWeight: 600,

                                            }}>{item.name}</span>
                                        </div>
                                        <div style={{
                                            backgroundColor: '#777777',
                                            height: '0.5px',
                                        }} className='my-4'></div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        :
                        <p className='text-center text-gray-800 font-[600]'> No city found / No country selected </p>
                    }


                </Modal.Body>
            </Modal>
        </div >
    )
}

export default CitySelectModal
