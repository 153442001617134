import React, { useEffect, useState } from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { CiLocationOn } from 'react-icons/ci'
import { TbWorldWww } from 'react-icons/tb'
import { BsTelephone } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from 'flowbite-react'
import {SESSION_LOGIN} from "../Utils/Constant";

const CrmSinglePage = () => {
    const [login, setLogin] = useState()

    const navigate = useNavigate()

    useEffect(() => {
        const isLogin = sessionStorage.getItem("LOGIN")
        if (isLogin === "true") {
            setLogin(true)
        } else {
            setLogin(false)
            navigate('/login')
        }
    })

    const logout = () => {
        sessionStorage.setItem(SESSION_LOGIN, "false")
        sessionStorage.setItem("ID", null)
        sessionStorage.setItem("TYPE", null)
        sessionStorage.setItem("USER", null)
        navigate('/')

    }
    return (
        <>
            <div>
                <div className='w-[100%] pb-5 h-[full] bg-blue-600 flex max-sm:flex-col '>
                    <div className='pl-[6rem] max-md:pl-[2rem] pt-[3rem] max-sm:flex max-sm:flex-col max-sm:justify-center max-sm:items-center'>
                        <img src="assets/profile.png" alt="" className='w-[120px] h-[120px]  ' />
                        <h3 className='text-[1.4rem] text-white font-[500] mt-4 max-sm:mt-1'>Shankar Doe</h3>
                    </div>
                    <div className='ml-[6rem] max-sm:ml-[2rem] mt-[5rem] max-sm:mt-3  font-[800]'>
                        <h1 className='text-white text-[3rem]   max-md:text-center max-sm:text-[1.5rem] max-sm:mb-[1rem] '>Welcome to Profile & Info Page</h1>
                    </div>
                    <div className='flex items-center ml-auto mr-5'>
                        <Button onClick={() => logout()} className='bg-white text-black border-[1px] border-gray-900 ' color='white'>Log Out</Button>
                    </div>
                </div>

                <div className='w-[100%] py-[2rem]  md:flex justify-between h-full bg-gray-300'>
                    <div className='bg-white ml-[3rem] max-md:ml-5 h-full py-[20px] px-[20px] w-[30%] max-md:w-[90%] text-[18px] rounded-md'>
                        <div className=' p-[1rem]'>
                            <h1 className='text-center text-[2rem] font-[800] mt-3'> Profile & Info </h1>
                        </div>
                        <div className='bg-gray-100 mt-[0.5rem] py-[0.5rem] md:px-[2rem] max-md:px-[1rem]  md:flex md:flex-col flex-wrap rounded-md leading-8'>
                            <h3 className='text-gray-500 font-[600]'>Contact</h3>
                            <div className='flex items-center px-4 mt-3'>
                                <span><AiOutlineMail /> </span>
                                <span className='text-gray-500 md:text-[1rem] max-md:text-[0.7rem] ml-3 '>ShankarDoe@gmail.com</span>
                            </div>
                            <div className='flex items-center px-4'>
                                <span><BsTelephone />  </span>
                                <span className='text-gray-500 md:text-[1rem] max-md:text-[0.7rem] ml-3 '>+92 128-238-283</span>
                            </div>
                            <div className='flex items-center px-4'>
                                <span><TbWorldWww />  </span>
                                <span className='text-gray-500 md:text-[1rem] max-md:text-[0.7rem] ml-3 '>www.shankardoe.com</span>
                            </div>
                            <div className='flex items-center px-4'>
                                <span><CiLocationOn /> </span>
                                <span className='text-gray-500 md:text-[1rem] max-md:text-[0.7rem] ml-3 '>Mughalpura, Lahore</span>
                            </div>

                            <div className='ml-[3.5rem]'>
                                <button className='mt-4 py-1 px-[3rem] font-[600]  bg-white rounded-md'>Edit</button>
                            </div>

                        </div>
                        <div className='bg-gray-100 mt-[1rem] py-[1rem] px-[3rem] rounded-md leading-8'>
                            <h3 className='text-gray-500 font-[600]'>Owner</h3>
                            <p className='font-[600] pl-3 '>Shankar Doe</p>
                        </div>
                        <div className='bg-gray-100 mt-[1rem] py-[1rem] px-[3rem] rounded-md leading-8'>

                            <h3 className='text-gray-500 font-[600]'>Custom Info</h3>
                            <div className='flex justify-between py-[0.5rem] px-[1.5rem] max-sm:px-[0.5rem] '>
                                <span>Plan: </span>
                                <span className='text-right text-gray-500 text-[1rem]'>Pro</span>
                            </div>
                            <div className='flex justify-between py-[0.5rem] px-[1.5rem] max-sm:px-[0.5rem] '>
                                <span>IP :</span>
                                <span className='text-right text-gray-500 text-[1rem]'>192.147.3.13</span>
                            </div>
                            <div className='flex justify-between py-[0.5rem] px-[1.5rem] max-sm:px-[0.5rem] '>

                                <span>Users: </span>
                                <span className='text-right text-gray-500 text-[1rem]'>3</span>
                            </div>
                            <div className='flex justify-between py-[0.5rem] px-[1.5rem] max-sm:px-[0.5rem] '>
                                <span>Domain: </span>
                                <span className='text-right text-gray-500 text-[1rem]'>stoq</span>
                            </div>
                        </div>
                    </div>
                    <div className=' h-full rounded-md w-[70%] max-md:w-[100%] max-md:mt-5 px-[2.8rem] max-md:px-[1rem] text-[18px]'>
                        <div className='bg-white h-[full] rounded-md p-[10px] w-[100%] text-[18px]'>
                            <section class="text-gray-600 body-font">
                                <div class="container px-5 py-10 mx-auto">
                                    <div class="flex flex-col text-center w-full mb-20">
                                        <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Master Cleanse Reliac Heirloom</h1>
                                        <p class="lg:w-2/3 mx-auto leading-relaxed text-base">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. </p>
                                    </div>
                                    <div class="flex flex-wrap -m-4 text-center">
                                        <div class="p-4 md:w-1/4 sm:w-1/2 w-full">
                                            <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                                                    <path d="M8 17l4 4 4-4m-4-5v9"></path>
                                                    <path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29"></path>
                                                </svg>
                                                <h2 class="title-font font-medium text-3xl text-gray-900">2.7K</h2>
                                                <p class="leading-relaxed">Downloads</p>
                                            </div>
                                        </div>
                                        <div class="p-4 md:w-1/4 sm:w-1/2 w-full">
                                            <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                                                    <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                                                    <circle cx="9" cy="7" r="4"></circle>
                                                    <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
                                                </svg>
                                                <h2 class="title-font font-medium text-3xl text-gray-900">1.3K</h2>
                                                <p class="leading-relaxed">Users</p>
                                            </div>
                                        </div>
                                        <div class="p-4 md:w-1/4 sm:w-1/2 w-full">
                                            <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                                                    <path d="M3 18v-6a9 9 0 0118 0v6"></path>
                                                    <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z"></path>
                                                </svg>
                                                <h2 class="title-font font-medium text-3xl text-gray-900">74</h2>
                                                <p class="leading-relaxed">Files</p>
                                            </div>
                                        </div>
                                        <div class="p-4 md:w-1/4 sm:w-1/2 w-full">
                                            <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                                                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                                </svg>
                                                <h2 class="title-font font-medium text-3xl text-gray-900">46</h2>
                                                <p class="leading-relaxed">Places</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className='flex max-sm:flex-col mt-5 gap-4'>
                            <div className='bg-white h-[18rem] flex flex-col justify-center items-center rounded-md p-[3rem] w-[50%] max-sm:w-[100%] text-[18px]'>
                                <img src="assets/engineering.png" alt="" className='w-[6rem] h-[6rem]' />
                                <Link to="http://192.168.1.2:3000/" className='mt-5 px-5 py-2 bg-yellow-300 rounded-lg text-white font-[600]'>Go to Dashboard</Link>
                            </div>
                            <div className='bg-white h-[18rem] flex flex-col justify-center items-center rounded-md  w-[50%] max-sm:w-[100%] text-[18px]'>
                                <img src="assets/services.png" alt="" className='w-[6rem] h-[6rem]' />
                                <button className='mt-5 px-5 py-2 bg-blue-500 rounded-lg text-white font-[600]'>Our Services</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CrmSinglePage
