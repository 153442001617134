export const isProfileCompleteAndVerified = (seeker) => {
    return (
      seeker?.verified === 'true' &&
      seeker?.name &&
      seeker?.address &&
      seeker?.city &&
      seeker?.country &&
      seeker?.dob &&
      seeker?.gender &&
      seeker?.phone
    );
  };
  