import React, { useState } from 'react'
import Footer from '../components/Footer'
import TopBar from '../components/TopBar'
import '../css/main.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom'
const ApplyForm = () => {

  const params = useLocation();
  const job = params?.state?.job;
  console.log('job', job)

  const [intro, setIntro] = useState()
  const [statement, setStatement] = useState()

  const navigate = useNavigate()

  const handleSubmit = () => {
    if (intro?.length > 0) {
      if (statement?.length > 0) {
        navigate('/coverletter', { state: { intro: intro, statement: statement, job: job } })
      }
      else {
        toast.error('Please Fill the Statement Field', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      toast.error('Please Fill the Intro Field', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  return (
    <div>
      
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="contact1">
        <div className="container-contact1">
          <div className="contact1-pic js-tilt" data-tilt>
            <img src='./assets/email.png' alt="IMG" />
          </div>
          <form className="contact1-form validate-form">
            <span className="contact1-form-title">
              Create Cover Letter
            </span>
            <div className="wrap-input1 validate-input" data-validate="Name is required">
              <input className="input1" type="text" name="name" onChange={(e) => setIntro(e.target.value)} placeholder="Intro" required />
              <span className="shadow-input1"></span>
            </div>
            <div className="wrap-input1 validate-input " data-validate="Message is required">
              <textarea className="input1" name="message" onChange={(e) => setStatement(e.target.value)} placeholder="Tell us about yourself" required />
              <span className="shadow-input1"></span>
            </div>
            <div onClick={handleSubmit} className="container-contact1-form-btn">
              <button className="contact1-form-btn">
                Create Cover Letter
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ApplyForm