import React from 'react'

const ChangePassword = () => {
    return (
        <>
            <div>
                <div className='w-[100%] h-[21rem] bg-blue-600'>
                </div>
                <div className='w-[100%] h-screen bg-gray-400'>
                    <center>
                        <div className='bg-white rounded-md p-[10px] w-[40%] text-[18px] relative top-[-210px]'>
                            <br/><br/>
                            <h1 className='text-[26px] font-normal'>Change Password</h1><br/><br/>
                            <input type="password" placeholder='Enter Password'
                                   className='shadow-lg border-slate-100	 rounded-[12px] pr-[176px] pl-[10px] border-[1px] pt-[11px] pb-[11px]'/><br/><br/>
                            <input type="password" placeholder='Confirm Password'
                                   className='shadow-lg border-slate-100	 rounded-[12px] pr-[176px] pl-[10px] border-[1px] pt-[11px] pb-[11px]'/><br/>
                            <span className='flex ml-[5.1rem]'>
                                <br/>
                                 <a href="#" className='text-[16px] text-blue-500 mt-[1.35rem] ml-[15.1rem]'>Forgot Password?</a>
                             </span>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <button
                                className='pl-[140px] pr-[140px] rounded-[30px] text-[30px] bg-blue-600 text-white pt-[6px] pb-[6px]'>
                                Confirm
                            </button>
                            <br/><br/>
                        </div>
                    </center>
                </div>
            </div>
        </>
    )
}

export default ChangePassword
