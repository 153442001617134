import { CREATE_CV_CAREER, CREATE_CV_COURSE, CREATE_CV_EDUCATION, CREATE_CV_INTEREST, CREATE_CV_LANGUAGE, CREATE_CV_SKILL, CV_CHECK, CV_LOADING, CV_SUCCESS, GET_CV_USER, LOADING, SUCCESS } from "../../Utils/Constant"
import * as api from '../../API/index'



export const getCvByUser = (user) => async (dispatch) => {
    try {
        dispatch({ type: LOADING })
        dispatch({ type: CV_LOADING })
        const { data: { data } } = await api.fetchCvByUser(user);
        dispatch({ type: GET_CV_USER, payload: data })
        dispatch({ type: SUCCESS })

    } catch (error) {
        console.log(error)
    }
}


// export const CheckCV = (user) => async (dispatch) => {
//     try {
//         dispatch({ type: LOADING })
//         dispatch({ type: CV_LOADING })
//         const { data: { status } } = await api.checkCV(user);
//         dispatch({ type: CV_CHECK, payload: { check: status } })
//         dispatch({ type: SUCCESS })
//         dispatch({ type: CV_SUCCESS })

//     } catch (error) {
//         console.log(error)
//         dispatch({ type: CV_ERROR })

//     }
// }

export const CVEducation = (cv, qualification, timeperiod, institute) => async (dispatch) => {
    try {
        dispatch({ type: LOADING })
        dispatch({ type: CV_LOADING })

        const { data: { data } } = await api.addCVEducation(cv, qualification, timeperiod, institute);
        dispatch({
            type: CREATE_CV_EDUCATION, payload: {
                data: {
                    cv: cv,
                    qualification: qualification,
                    timeperiod: timeperiod,
                    institute: institute,
                }
            }
        })
        console.log('data', data);
        dispatch({ type: SUCCESS })
        dispatch({ type: CV_SUCCESS })

    } catch (error) {
        console.log(error)
        // dispatch({ type: CV_ERROR })

    }
}

export const CVCareer = (cv, company, job, timeperiod, address, phone) => async (dispatch) => {
    try {
        dispatch({ type: LOADING })
        dispatch({ type: CV_LOADING })
        const { data: { data } } = await api.addCVCareer(cv, company, job, timeperiod, address, phone);
        dispatch({
            type: CREATE_CV_CAREER, payload: {
                data: {
                    cv: cv,
                    company: company,
                    job: job,
                    timeperiod: timeperiod,
                    address: address,
                    phone: phone

                }
            }
        })
        console.log('data', data);
        dispatch({ type: SUCCESS })
        dispatch({ type: CV_SUCCESS })

    } catch (error) {
        console.log(error)
        // dispatch({ type: CV_ERROR })
    }
}

export const CVCourse = (cv, course, timeperiod, institute) => async (dispatch) => {
    try {
        dispatch({ type: LOADING })
        dispatch({ type: CV_LOADING })

        const { data: { data } } = await api.addCVCourse(cv, course, timeperiod, institute);
        dispatch({
            type: CREATE_CV_COURSE, payload: {
                data: {
                    cv: cv,
                    course: course,
                    timePeriod: timeperiod,
                    institute: institute,
                },
            }
        })
        console.log('data', data);
        dispatch({ type: SUCCESS })
        dispatch({ type: CV_SUCCESS })

    } catch (error) {
        console.log(error)


    }
}

export const CVInterest = (cv, interest) => async (dispatch) => {
    try {
        dispatch({ type: LOADING })
        dispatch({ type: CV_LOADING })

        const { data: { data } } = await api.addCVInterest(cv, interest);
        dispatch({
            type: CREATE_CV_INTEREST, payload: {
                data: {
                    cv: cv,
                    interest: interest
                }
            }
        })
        console.log('interest', data);
        dispatch({ type: SUCCESS })
        dispatch({ type: CV_SUCCESS })

    } catch (error) {
        console.log(error)
        // Handle error or dispatch({ type: CV_ERROR })
    }
}


export const CVLanguage = (cv, language) => async (dispatch) => {
    try {
        dispatch({ type: LOADING })
        dispatch({ type: CV_LOADING })

        const { data: { data } } = await api.addCVLanguage(cv, language);
        dispatch({
            type: CREATE_CV_LANGUAGE, payload: {
                data: {
                    cv: cv,
                    language: language
                }
            }
        })
        console.log('language', data);
        dispatch({ type: SUCCESS })
        dispatch({ type: CV_SUCCESS })

    } catch (error) {
        console.log(error)
        // dispatch({ type: CV_ERROR })

    }
}


export const CVSkill = (cv, skill) => async (dispatch) => {
    try {
        dispatch({ type: LOADING })
        dispatch({ type: CV_LOADING })

        const { data: { data } } = await api.addCVSkill(cv, skill);
        dispatch({
            type: CREATE_CV_SKILL, payload: {
                data: {
                    cv: cv,
                    skill: skill
                }
            }
        })
        console.log('skill', data);
        dispatch({ type: SUCCESS })
        dispatch({ type: CV_SUCCESS })

    } catch (error) {
        console.log(error)
        // dispatch({ type: CV_ERROR })

    }
}
