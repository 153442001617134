import React from 'react'
import { useNavigate } from 'react-router-dom';

const AppliedSuccessfully = () => {
    const router = useNavigate();
    return (
      <div className="h-screen flex justify-center items-center bg-gray-200">
        <div className="w-[50%] max-md:w-[95%] py-[3rem] p-2 bg-white flex flex-col items-center border-2 shadow-md rounded-md">
          <div className="flex flex-col items-center">
            {/* <CheckCircleIcon
              style={{ fontSize: "100px" }}
              width
              className="text-green-500 "
            /> */}
            <h2 className="mt-8  font-[700] text-[1.4rem]">
              Job Applied Successfully!
            </h2>
            <p className="w-[60%] mt-3 text-gray-600 text-[.8rem] font-[400] text-center">
                Your job application is confirmed. We appreciate your interest and look forward to delivering positive news soon!
            </p>
          </div>
          <div className="flex justify-center gap-5 w-full">
            <button
              onClick={() => router("/SearchPage")}
              className="w-[30%] max-md:w-[50%] mt-10 bg-green-500 text-white font-[500] hover:bg-green-700  hover:border-white rounded-[8px] border-2 py-3 transition-all ease-in-out duration-300 "
            >
              Search more Jobs
            </button>
            <button
              onClick={() => router("/")}
              className="w-[30%] max-md:w-[50%] mt-10  font-[500] hover:bg-gray-200 rounded-[8px] border-2 py-3">
              Go Back to Home
            </button>
          </div>
        </div>
      </div>
    );
}

export default AppliedSuccessfully
