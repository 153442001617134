import * as api from '../../API/index'
import { ALL_COMPANIES, ALL_COMPANY_LOADING, COMPANY_LOADING, GET_COMPANY, LOADING, SUCCESS } from "../../Utils/Constant";


export const AllCompanies = () => async (dispatch) => {
    try {
        dispatch ({ type: LOADING })
        dispatch({ type:COMPANY_LOADING })
        const { data: {data} } = await api.fetchAllCompanies();
        dispatch ({ type: ALL_COMPANIES, payload: { companies: data }})
        dispatch ({ type: SUCCESS })
    } catch (error) {
        console.log(error)
    }
}

export const getCompanies = (id) => async (dispatch) => {
    try {
        const { data: {data} } = await api.fetchCompany(id);
        dispatch ({ type: GET_COMPANY, payload: data})
    } catch (error) {
        console.log(error)
    }
}