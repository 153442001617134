import {Modal} from 'flowbite-react'
import React, {useEffect} from 'react'
import {BsArrowUpRightCircle,} from 'react-icons/bs'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment/moment'
import {getFeaturedApiJob} from '../API/actions/jobsApiActions'

const FeaturedJobsModal = ({visible, toggleVisibility, ID, navigate}) => {
    console.log(ID)
    const dispatch = useDispatch()

    const featureJob = useSelector(state => state.apiJob.apiJob)

    useEffect(() => {
        console.log('feature', featureJob);
    }, [featureJob])

    useEffect(() => {
        dispatch(getFeaturedApiJob(ID))
    }, [dispatch, ID])

    return (
        <div>
            <Modal show={visible} size={'7xl'} onClose={() => toggleVisibility()}>
                <Modal.Header className='w-[100%] max-md:w-[100%] bg-white  max-md:ml-0'>Job Detail</Modal.Header>
                <Modal.Body className='w-[100%] max-md:w-[100%] bg-white  max-md:ml-0'>
                    <div className='flex max-md:flex-col bg-white gap-4 shadow-md shadow-gray-200 rounded-xl p-2'>
                        <div className='flex gap-4  '>
                            <div className=''>
                                <p className='bg-blue-400 px-5 py-2 text-gray-800 text-[3rem] rounded-md'>M</p>
                            </div>
                            <div className='w-[100%] '>
                                <h1 className='text-[1.3rem] max-md:text-[1rem] text-gray-800'>{featureJob?.title}</h1>
                                <h1 className='text-[1.1rem] max-md:text-[1rem] text-gray-700'>{featureJob?.role}</h1>
                                <h1 className='text-[.9rem] text-blue-800'>{featureJob?.company} </h1>
                                <h1 className='text-[.9rem] text-gray-600'> {featureJob?.site}</h1>
                            </div>
                        </div>
                        <div className='max-md:w-[100%] max-md:ml-[20%] ml-auto '>
                            <div className='flex justify-center mt-[10%] gap-6'>
                                <a href={featureJob?.url} target="_blank"
                                   className='flex bg-[#8060B6] ml-auto max-md:mr-auto px-5 py-2 text-white gap-2 font-[600] rounded-md cursor-pointer hover:bg-purple-500'>
                                    <BsArrowUpRightCircle size='1.3rem' className='mt-[2px]'/>
                                    <h1>Apply on external link</h1>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='p-4  '>
                        <div className='flex max-md:flex-col pt-4 pb-2'>
                            <h1 className='text-purple-800 font-[500]'>Location</h1>
                            <h1 className='ml-auto text-gray-500 max-md:text-[0.8rem] font-[400]'>{featureJob?.locations}<span
                                className='text-[.7rem] text-blue-600 font-[500] ml-2'> {featureJob?.type} </span>
                            </h1>
                        </div>
                        <hr/>
                        <div className='flex max-md:flex-col pt-4 pb-2'>
                            <h1 className='text-purple-800 font-[500]'>Salary</h1>
                            <h1 className='ml-auto text-gray-500 font-[400]'> {featureJob?.salary}</h1>
                        </div>
                        <hr/>
                        <div className='flex max-md:flex-col pt-4 pb-2'>
                            <h1 className='text-purple-800 font-[500]'>Posted</h1>
                            <h1 className='ml-auto text-gray-500 font-[400]'>{moment(featureJob?.date).format('YYYY-MM-DD')}</h1>
                        </div>
                        <hr/>
                        <h1 className='pt-2 text-[1.2rem] font-[500] text-gray-900'>Description</h1>
                        <p className='py-3 text-gray-700 font-[400]'>{featureJob?.description}</p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default FeaturedJobsModal
