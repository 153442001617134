import { Modal } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { BsArrowUpRightCircle } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { getJob } from '../API/actions/jobsAction'
import moment from 'moment/moment'
import LoginRequireModal00 from './LoginRequireModal'
import { Link, useNavigate } from 'react-router-dom'
import { SESSION_LOGIN, SESSION_SEEKER } from '../Utils/Constant'
import LoginModal from './LoginModal'
import { fetchSeeker } from '../API/actions/seekerAction'
import { checkCV } from '../API'
import CheckCvModal from './CheckCvModal'


const ProviderModal = ({ visible, toggleVisibility, ID }) => {

    console.log('id', ID);

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [data, setData] = useState();
    const [login, setLogin] = useState();
    const [check, setCheck] = useState();


    const seeker = useSelector(state => state.seeker.seeker)

    console.log('seeker' , seeker);


    useEffect(() => {
        const isLogin = sessionStorage.getItem(SESSION_LOGIN);
        if (isLogin === "true") {
            setLogin(true);
        } else {
            setLogin(false);
        }
    }, []);

    useEffect(() => {
        setData(JSON.parse(sessionStorage.getItem(SESSION_SEEKER)));
    }, []);

    useEffect(() => {
        if (data) {

            dispatch(fetchSeeker(data?.id))
        }
    }, [dispatch, data]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await checkCV(data?.id);
                console.log('applied', res);
                // setApplied(res?.data?.data)
                setCheck(res?.data?.status)

            } catch (error) {
                console.error('Error verifying seeker:', error);
            }
        };

        fetchData();
    }, [data?.id]);

    const job = useSelector(state => state.job.job)

    useEffect(() => {

        dispatch(getJob(ID, data?.id))

    }, [dispatch, ID, data?.id])

    const [requireVisible, setRequireVisible] = useState(false)
    const toggleVisible = () => setRequireVisible(!requireVisible)

    const [requireLinkVisible, setRequireLinkVisible] = useState(false)
    const toggleLinkVisible = () => setRequireLinkVisible(!requireLinkVisible)

    const [checkModal, setCheckModal] = useState(false);

    const toggleCheck = () => {
        setCheckModal(!checkModal)
    }

    const [checkProfile, setCheckProfile] = useState(false)

    useEffect(() => {
        if (seeker?.address && seeker?.city && seeker?.country && seeker?.code && seeker?.phone && seeker?.gender && seeker?.name) {
            setCheckProfile(true)
        } else {
            setCheckProfile(false)
        }

    }, [seeker])

    console.log('seeker profile', checkProfile);



    return (
        <div>
            <LoginModal visible={requireVisible} toggleVisible={toggleVisible} />
            <LoginRequireModal00 visible={requireLinkVisible} toggleVisible={toggleLinkVisible} link={job?.link} />
            <CheckCvModal visible={checkModal} toggleVisibility={toggleCheck} />

            <Modal show={visible} size={'7xl'} onClose={() => toggleVisibility()}>
                <Modal.Header className='w-[100%] max-md:w-[100%] bg-white  max-md:ml-0'>Job Detail</Modal.Header>
                <Modal.Body className='w-[100%] max-md:w-[100%] bg-white  max-md:ml-0'>
                    <div className='flex max-md:flex-col bg-white gap-4 shadow-md shadow-gray-200 rounded-xl p-2'>
                        <div className='flex gap-4  '>
                            <div className=''>
                                <p className='bg-[#7E3AF2] px-8 py-2  text-[3rem] rounded-md text-white'>{job?.role && job.role.charAt(0)}</p>
                            </div>
                            <div className='w-[100%] '>
                                <h1 className='text-[1.3rem] max-md:text-[1rem] text-gray-800'>{job?.title}</h1>
                                <h1 className='text-[1.1rem] max-md:text-[1rem] text-gray-700'>{job?.role}</h1>
                                <h1 className='text-[.9rem] text-blue-800'>{job?.company_name} </h1>
                            </div>
                        </div>
                        <div className='  max-md:w-[100%] max-md:ml-[20%] ml-auto'>
                            <div className='flex justify-center mt-[10%] gap-6'>
                                {!login && (
                                    <a onClick={toggleVisible} className='flex bg-purple-600 px-5 py-2 text-white gap-2 font-semibold rounded-md cursor-pointer hover:bg-purple-500'>
                                        <BsArrowUpRightCircle size='1.3rem' className='mt-[2px]' />
                                        <h1>Apply</h1>
                                    </a>
                                )}

                                {login && job?.applied === 0 && (
                                    job?.link?.length > 0 ? (
                                        <a href={job?.link} target='_blank' className='flex bg-purple-600 px-5 py-2 text-white gap-2 font-semibold rounded-md cursor-pointer hover:bg-purple-500'>
                                            <BsArrowUpRightCircle size='1.3rem' className='mt-[2px]' />
                                            <h1>Apply on external link</h1>
                                        </a>
                                    ) :

                                        checkProfile ?
                                            (
                                                seeker?.verified === "true" ? (
                                                    check === 'complete' ? (
                                                        <a onClick={() => navigate('/applyForm', { state: { job: job?.id } })} className='flex bg-purple-600 px-5 py-2 text-white gap-2 font-semibold rounded-md cursor-pointer hover:bg-purple-500'>
                                                            <BsArrowUpRightCircle size='1.3rem' className='mt-[2px]' />
                                                            <h1>Apply</h1>
                                                        </a>
                                                    )
                                                        :
                                                        (
                                                            <a onClick={toggleCheck} className='flex bg-purple-600 px-5 py-2 text-white gap-2 font-semibold rounded-md cursor-pointer hover:bg-purple-500'>
                                                                <BsArrowUpRightCircle size='1.3rem' className='mt-[2px]' />
                                                                <h1>Apply</h1>
                                                            </a>
                                                        )

                                                ) :
                                                    (
                                                        <Link to='/personalinfo' className='flex bg-purple-600 px-5 py-2 text-white gap-2 font-semibold rounded-md cursor-pointer hover:bg-purple-500'>
                                                            <BsArrowUpRightCircle size='1.3rem' className='mt-[2px]' />
                                                            <h1>Complete Verify first</h1>
                                                        </Link>
                                                    )
                                            )
                                            :
                                            <Link to='/personalinfo' className='flex bg-purple-600 px-5 py-2 text-white gap-2 font-semibold rounded-md cursor-pointer hover:bg-purple-500'>
                                                <BsArrowUpRightCircle size='1.3rem' className='mt-[2px]' />
                                                <h1>Complete Profile first</h1>
                                            </Link>
                                )}

                                {login && job?.applied !== 0 && (
                                    <p className='flex bg-purple-600 px-5 py-2 text-white gap-2 font-semibold rounded-md cursor-pointer hover:bg-purple-500'>
                                        <BsArrowUpRightCircle size='1.3rem' className='mt-[2px]' />
                                        <h1>Applied</h1>
                                    </p>
                                )}

                            </div>
                        </div>
                    </div>
                    <div className='p-4  '>
                        <div className='flex max-md:flex-col pt-4 pb-2'>
                            <h1 className='text-purple-800 font-[500]'>Location</h1>
                            <h1 className='ml-auto text-gray-500 max-md:text-[0.8rem] font-[400]'>{job?.city_name}, {job?.country_name}<span
                                className='text-[.7rem] text-blue-600 font-[500] ml-2'>{job?.type}</span></h1>
                        </div>
                        <hr />
                        <div className='flex max-md:flex-col pt-4 pb-2'>
                            <h1 className='text-purple-800 font-[500]'>Salary</h1>
                            <h1 className='ml-auto text-gray-500 font-[400]'> {job?.salary}<span
                                className='text-[.7rem] text-blue-600 font-[500] ml-2'></span></h1>
                        </div>
                        <hr />
                        <div className='flex max-md:flex-col pt-4 pb-2'>
                            <h1 className='text-purple-800 font-[500]'>Posted</h1>
                            <h1 className='ml-auto text-gray-500 font-[400]'>{moment(job?.date).format('YYYY-MM-DD')}</h1>
                        </div>
                        <hr />
                        <h1 className='pt-2 text-[1.2rem] font-[500] text-gray-900'>Description</h1>
                        <p className='py-3 text-gray-700 font-[400]'
                            dangerouslySetInnerHTML={{ __html: job?.description }}></p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ProviderModal
