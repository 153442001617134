import { FEATURED_JOB_LOADING, GET_JOBSAPI_FEATUREDJOBS, GET_JOBSAPI_JOBS, GET_JOBSAPI_RECENT, LOADING, SUCCESS } from "../../Utils/Constant"
import * as api from '../../API/index'



export const getApiJobs = (search) => async (dispatch) => {
    try {
        dispatch({ type: LOADING })
        dispatch({ type: FEATURED_JOB_LOADING })
        const { data: { data } } = await api.fetchApiJobs(search);
        dispatch({ type: GET_JOBSAPI_JOBS, payload: data })
        dispatch({ type: SUCCESS })

    } catch (error) {
        console.log(error)
    }
}


export const getApiJobsRecent = (search) => async (dispatch) => {
    try {
        const { data: { data } } = await api.fetchApiJobsRecent(search);
        dispatch({ type: GET_JOBSAPI_RECENT, payload: data })
        console.log('recent jobs', data);

    } catch (error) {
        console.log(error)
    }
}

export const getFeaturedApiJob = (id) => async (dispatch) => {
    try {
        const { data: { data } } = await api.fetchFeaturedJob(id);
        console.log(data)
        dispatch({ type: GET_JOBSAPI_FEATUREDJOBS, payload: data })

    } catch (error) {
        console.log(error)
    }
}