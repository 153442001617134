import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { createUserPlan } from '../API'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { SESSION_SEEKER } from '../Utils/Constant'

const PlanSuccessful = () => {

  const navigation = useNavigate()



  const [seekerData, setSeekerData] = useState()
  useEffect(() => {
    setSeekerData(JSON.parse(sessionStorage.getItem(SESSION_SEEKER)))
  }, [])

  console.log('seekerdata', seekerData);

  const [type, setType] = useState();
  const [Pname, setPname] = useState();
  const [id, setId] = useState();

  const [seekerId, setSeekerId] = useState();

  useEffect(() => {
    if (id && seekerData) {
      setSeekerId(seekerData?.id)
    }
  }, [id, seekerData])


  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    console.log('params', params);
    setId(params.get('req'));
    setPname(params.get('req2'));
    setType(params.get('req3'));

  }, []);

  console.log('req', seekerId, id, type);



  useEffect(() => {
    if (seekerId && id) {
      const subscribePlan = () => {
        const postDate = moment().format("YYYY-MM-DD")
        createUserPlan(seekerId, id, postDate, 'Seeker').then(res => {
          const { data: { responseCode } } = res;
          console.log('subscribe ? ', res);
          
        })
      }
      subscribePlan();
    }

  }, [seekerId, id]);


  return (
    <div className="h-screen flex justify-center items-center bg-gray-200">
      <div className="w-[50%] py-[3rem] bg-white flex flex-col items-center border-2 shadow-md rounded-md">
        <div className="flex flex-col items-center">
          <h2 className="mt-8  font-[700] text-[1.4rem]">
            Plan Purchased Successfully!
          </h2>
          <p className="w-[60%] mt-3 text-gray-600 text-[.8rem] font-[400] text-center">
            Our purchasing plan was a success, thanks to careful planning and teamwork. We secured resources efficiently, ensuring quality while optimizing costs.
          </p>
        </div>
        <div className="flex justify-center gap-5 w-full">
          <button
            onClick={() => navigation("/SearchPage", { replace: true })}
            className="w-[30%] mt-10 bg-green-500 text-white font-[500] hover:bg-green-700  hover:border-white rounded-[8px] border-2 py-3 transition-all ease-in-out duration-300 "
          >
            Find Jobs
          </button>
          <button
            onClick={() => navigation("/", { replace: true })}
            className="w-[30%] mt-10  font-[500] hover:bg-gray-200 rounded-[8px] border-2 py-3">
            Go to Home
          </button>
        </div>
      </div>
    </div>

  );
}

export default PlanSuccessful