import {Modal} from 'flowbite-react'

const LoginRequireModal00 = ({visible, toggleVisible, link}) => {

    return (
        <div>
            <Modal show={visible} size={'2xl'} onClose={() => toggleVisible()}>
                <Modal.Header className='w-[100%] max-md:w-[100%] bg-white  max-md:ml-0'>Link of the job</Modal.Header>
                <Modal.Body className='w-[100%] max-md:w-[100%] bg-white  max-md:ml-0'>
                    <div className='flex'>

                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default LoginRequireModal00
