// PaymentForm.js
import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';
import { createUserPlan } from '../API';
import moment from 'moment';

const PaymentForm = ({ clientSecret }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const location = useLocation();

    const price = location?.state?.price;
    const name = location?.state?.name;
    const purpose = location?.state?.purpose;
    const type = location?.state?.accounttype;
    const Pname = location?.state?.Pname;
    const id = location?.state?.id;




    // const fetchPaymentIntentClientSecret = async () => {
    //     const response = await fetch(`http://182.176.99.175/mevin/create-payment-intent`, {
    //         method: "POST",
    //         credentials: 'include',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             price: price,
    //         })
    //     });
    //     const { clientSecret, error } = await response.json();
    //     return { clientSecret, error };
    // };

    const subscribePlan = () => {
        const postDate = moment().format("YYYY-MM-DD")
        const updateDate = moment().format("YYYY-MM-DD")
        createUserPlan(id, Pname, postDate, type).then(res => {
            const { data: { responseCode } } = res;
            console.log('subscribe ? ', res);
            if (responseCode === 200) {
                alert('done')
                // navigation.replace('PaymentSuccessful', { type: type })
            } else {
                alert('not done')
            }
        })
    }



    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        // await fetchPaymentIntentClientSecret().then(async (data) => {
        await elements.submit();
        const { error } = await stripe.confirmPayment({
            elements: elements,
            clientSecret: clientSecret,
            confirmParams: {
                return_url: `${process.env.REACT_APP_DOMAIN}/plansuccessful?req=${id}&req2=${Pname}&req3=${type}`
            }
        })
        if (error) {
            console.error("Error", error)
        } else {
            subscribePlan()
            console.log("Working Successfully")
        }
        // });
        setTimeout(() => {
            setLoading(false)
        }, 4000);
    };



    return (
        <div className="py-16 ">
            <div className='w-[80%] flex justify-evenly gap-10 m-auto ' >

                <div className="md:w-1/2 ">
                    <div className="border rounded-md overflow-hidden shadow-md bg-white">
                        <div className="text-center py-4 bg-blue-700">
                            <h2 className="text-3xl font-extrabold text-white">Plans Details</h2>
                        </div>
                        <div className="p-6">

                            <p className="text-2xl font-semibold text-gray-800 text-center">{name}</p>

                            <div className="mb-6">
                                <h3 className="text-lg font-semibold mb-3 text-gray-800">Plan Details:</h3>
                                {purpose?.split(', ').map((sentence, index) => (
                                    <p key={index} className="flex items-center text-gray-700 mb-2">
                                        <span className="w-5 h-5 mr-3 flex items-center justify-center bg-blue-500 text-white rounded-full flex-shrink-0">
                                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                <path d="M20 6L9 17l-5-5"></path>
                                            </svg>
                                        </span>{sentence}
                                    </p>
                                ))}
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-lg font-semibold text-gray-800">Total Price:</span>
                                <span className="text-xl font-semibold text-green-600">${price}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <form onSubmit={handleSubmit} className="relative w-[50%] shadow-xl rounded-xl p-4">
                    <div className="bg-white text-black">
                        <div className="mb-6">
                            <p className="block text-xl mb-2">Card Details</p>
                            <div className="bg-white p-3 border rounded-md">
                                {/* Assuming PaymentElement is a component or a library for handling payment details */}
                                {/* Please replace this with your actual PaymentElement component */}
                                <PaymentElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '16px',
                                                color: '#424770',
                                                '::placeholder': {
                                                    color: '#aab7c4',
                                                },

                                            },
                                            invalid: {
                                                color: '#9e2146',
                                            },
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        {error && (
                            <div className="text-red-500 mb-4">{error}</div>
                        )}
                    </div>
                    {loading ? (
                        <button className="absolute bottom-4 right-4 w-[5.5rem] flex justify-center bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue">
                            <div className="animate-spin rounded-full h-6 w-6 border-t-4 border-blue-500 border-solid"></div>
                        </button>
                    ) : (
                        <button
                            type="submit"
                            disabled={!stripe}
                            className="absolute bottom-4 right-4 bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue"
                        >
                            Pay Now
                        </button>
                    )}
                </form>

            </div>
        </div>


    );
};

export default PaymentForm;
