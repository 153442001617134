import React from 'react'
import TopBar from '../components/TopBar'

const TermsConditions = () => {
    return (
        <>
            
            <div className='w-[100%] h-full'>
                <div>
                    <div className="container w-[75%] mx-auto mt-10 p-8 bg-white rounded shadow-lg">
                        <h1 className="text-3xl text-center font-[700] text-gray-700 mb-6">Terms and Conditions</h1>

                        <div className="text-gray-700">
                            <p>These terms and conditions outline the rules and regulations for the use of jobss.com.au
                                Website.</p>
                            <br />
                            <p>By accessing this website we assume you accept these terms and conditions in full. Do not
                                continue to use jobss.com.au website if you do not accept all of the terms and
                                conditions stated on this page.</p>
                            <br />
                            <p>The following terminology applies to these Terms and Conditions, Privacy Statement and
                                Disclaimer Notice and any or all
                                Agreements: &ldquo;Client&rdquo;, &ldquo;You&rdquo; and &ldquo;Your&rdquo; refers to
                                you, the person accessing this website and accepting the Company&rsquo;s terms and
                                conditions. &ldquo;The
                                Company&rdquo;, &ldquo;Ourselves&rdquo;, &ldquo;We&rdquo;, &ldquo;Our&rdquo; and &ldquo;Us&rdquo;,
                                refers to our Company. &ldquo;Party&rdquo;, &ldquo;Parties&rdquo;, or &ldquo;Us&rdquo;,
                                refers to both the Client and ourselves, or either the Client or ourselves. All terms
                                refer to the offer, acceptance and consideration of payment necessary to undertake the
                                process of our assistance to the Client in the most appropriate manner, whether by
                                formal meetings of a fixed duration, or any other means, for the express purpose of
                                meeting the Client&rsquo;s needs in respect of provision of the Company&rsquo;s stated
                                services/products, in accordance with and subject to, prevailing law of Australia. Any
                                use of the above terminology or other words in the singular, plural, capitalisation
                                and/or he/she or they, are taken as interchangeable and therefore as referring to
                                same.</p>
                            <br />
                            <p className="text-[1.5rem] font-[500] text-gray-800">Cookies</p><br />
                            <p className='mb-3'>We employ the use of cookies. By using jobss.com.au website you consent
                                to the use of cookies in accordance with sponsorjobs.com.au privacy policy.</p>

                            <p>Most of the modern day interactive web sites use cookies to enable us to retrieve user
                                details for each visit. Cookies are used in some areas of our site to enable the
                                functionality of this area and ease of use for those people visiting. Some of our
                                affiliate / advertising partners may also use cookies.</p>
                            <br />
                            <p className="text-[1.5rem] font-[500] text-gray-800">License</p>
                            <br />
                            <p>Unless otherwise stated, jobss.com.au and/or it&rsquo;s licensors own the intellectual
                                property rights for all material on jobss.com.au . All intellectual property rights are
                                reserved. You may view and/or print pages from https:// jobss.com.au/ for your own
                                personal use subject to restrictions set in these terms and conditions.</p>
                            <p><br /></p>
                            <p>You must not:</p>
                            <br />
                            <p>  Republish material from https://jobss.com.au/</p>
                            <p>  Sell, rent or sub-license material from https://jobss.com.au/</p>
                            <p>  Reproduce, duplicate or copy material from https://jobss.com.au/</p>
                            <p>Redistribute content from jobss.com.au (unless content is specifically made for
                                redistribution).</p>
                            <br />
                            <p className="text-[1.5rem] font-[500] text-gray-800">Hyperlinking to our Content&nbsp;</p>
                            <br />
                            <p>  The following organizations may link to our Web site without prior written
                                approval:</p>
                            <p>  Government agencies;</p>
                            <p>  Search engines;</p>
                            <p>  News organizations;</p>
                            <p>  Online directory distributors when they list us in the directory may link
                                to our Web site in the same manner as they hyperlink to the Web sites of other listed
                                businesses; and</p>
                            <p>  Systemwide Accredited Businesses except soliciting non-profit
                                organizations, charity shopping malls, and charity fundraising groups which may not
                                hyperlink to our Web site.</p>
                            <p>0. These organizations may link to our home page, to publications or to
                                other Web site information so long as the link: (a) is not in any way misleading; (b)
                                does not falsely imply sponsorship, endorsement or approval of the linking party and its
                                products or services; and (c) fits within the context of the linking party&rsquo;s
                                site.</p>
                            <p>1. We may consider and approve in our sole discretion other link requests
                                from the following types of organizations:</p>
                            <p>  commonly-known consumer and/or business information sources such as
                                Chambers of Commerce, American Automobile Association, AARP and Consumers Union;</p>
                            <p>  dot.com community sites;</p>
                            <p>  associations or other groups representing charities, including charity
                                giving sites,</p>
                            <p>  online directory distributors;</p>
                            <p>  internet portals;</p>
                            <p>  accounting, law and consulting firms whose primary clients are businesses;
                                and</p>
                            <p>  educational institutions and trade associations.</p>
                            <p><br /></p>
                            <p>We will approve link requests from these organizations if we determine that: (a) the link
                                would not reflect unfavourably on us or our accredited businesses (for example, trade
                                associations or other organizations representing inherently suspect types of business,
                                such as work-at-home opportunities, shall not be allowed to link);&nbsp;</p>
                            <p>(b)the organization does not have an unsatisfactory record with us;&nbsp;</p>
                            <p>(c) the benefit to us from the visibility associated with the hyperlink outweighs the
                                absence of ; and&nbsp;</p>
                            <p>(d) where the link is in the context of general resource information or is otherwise
                                consistent with editorial content in a newsletter or similar product furthering the
                                mission of the organization.</p>
                            <p>These organizations may link to our home page, to publications or to other Web site
                                information so long as the link:&nbsp;</p>
                            <p>(a) is not in any way misleading;&nbsp;</p>
                            <p>(b) does not falsely imply sponsorship, endorsement or approval of the linking party and
                                it products or services; and&nbsp;</p>
                            <p>(c) fits within the context of the linking party&rsquo;s site.</p>
                            <p>If you are among the organizations listed in paragraph 2 above and are interested in
                                linking to our website, you must notify us by sending an e-mail to <a
                                    href="mailto:info@jobss.com.au">info@jobss.com.au</a>&nbsp; Please include your
                                name, your organization name, contact information (such as a phone number and/or e-mail
                                address) as well as the URL of your site, a list of any URLs from which you intend to
                                link to our Web site, and a list of the URL(s) on our site to which you would like to
                                link. Allow 2-3 weeks for a response.</p>
                            <p><br /></p>
                            <p>Approved organizations may hyperlink to our Web site as follows:</p>
                            <p>  By use of our corporate name; or</p>
                            <p>  By use of the uniform resource locator (Web address) being linked to;
                                or</p>
                            <p>  By use of any other description of our Web site or material being linked
                                to that makes sense within the context and format of content on the linking
                                party&rsquo;s site.</p>
                            <p>No use of jobss.com.au logo or other artwork will be allowed for linking absent a
                                trademark license agreement.</p>
                            <br />
                            <p className="text-[1.4rem] font-[500] text-gray-700">I frames</p>
                            <p>Without prior approval and express written permission, you may not create frames around
                                our Web pages or use other techniques that alter in any way the visual presentation or
                                appearance of our Web site.</p>
                            <br />
                            <p className="text-[1.4rem] font-[500] text-gray-700">Reservation of Rights</p>
                            <br />
                            <p>We reserve the right at any time and in its sole discretion to request that you remove
                                all links or any particular link to our Web site. You agree to immediately remove all
                                links to our Web site upon such request. We also reserve the right to amend these terms
                                and conditions and its linking policy at any time. By continuing to link to our Web
                                site, you agree to be bound to and abide by these linking terms and conditions.</p>
                            <br />
                            <p className="text-[1.4rem] font-[500] text-gray-700">Removal of links from our website</p>
                            <br />
                            <p>If you find any link on our Web site or any linked web site objectionable for any reason,
                                you may contact us about this. We will consider requests to remove links but will have
                                no obligation to do so or to respond directly to you.</p>
                            <p>Whilst we endeavour to ensure that the information on this website is correct, we do not
                                warrant its completeness or accuracy; nor do we commit to ensuring that the website
                                remains available or that the material on the website is kept up to date.</p>
                            <br />
                            <p className="text-[1.4rem] font-[500] text-gray-700">Content Liability</p><br />
                            <p>We shall have no responsibility or liability for any content appearing on your Web site.
                                You agree to indemnify and defend us against all claims arising out of or based upon
                                your Website. No link(s) may appear on any page on your Web site or within any context
                                containing content or materials that may be interpreted as libelous, obscene or
                                criminal, or which infringes, otherwise violates, or advocates the infringement or other
                                violation of, any third party rights.</p>
                            <br />
                            <p className="text-[1.4rem] font-[500] text-gray-700">Disclaimer</p>
                            <br />
                            <p>To the maximum extent permitted by applicable law, we exclude all representations,
                                warranties and conditions relating to our website and the use of this website
                                (including, without limitation, any warranties implied by law in respect of satisfactory
                                quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in
                                this disclaimer will:</p>
                            <p>  limit or exclude our or your liability for death or personal injury
                                resulting from negligence.</p>
                            <p>  limit or exclude our or your liability for fraud or fraudulent
                                misrepresentation.</p>
                            <p>  limit any of our or your liabilities in any way that is not permitted
                                under applicable law; or</p>
                            <p>  exclude any of our or your liabilities that may not be excluded under
                                applicable law.</p>
                            <p>The limitations and exclusions of liability set out in this Section and elsewhere in this
                                disclaimer:&nbsp;</p>
                            <p>(a) are subject to the preceding paragraph; and&nbsp;</p>
                            <p>(b) govern all liabilities arising under the disclaimer or in relation to the subject
                                matter of this disclaimer, including liabilities arising in contract, in tort (including
                                negligence) and for br/each of statutory duty.</p>
                            <p>To the extent that the website and the information and services on the website are
                                provided free of charge, we will not be liable for any loss or damage of any nature.</p>
                            <p><br /></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsConditions
