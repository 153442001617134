import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AllCategories } from '../API/actions/categoriesAction'
import { Carousel } from 'flowbite-react'

const QuickSearch = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const categories = useSelector(state => state.category.categories)

    useEffect(() => {
        dispatch(AllCategories())
    }, [dispatch])

    const handleSubmit = (id, name) => {
        navigate('/jobs', { state: { ID: id, Name: name } })
    }

    return (
        <div>
            <div className='flex w-[100%]'>

                <div className='flex w-[22rem] '>
                    <div className='bg-[#2994FF] text-white font-bold px-6 py-2 rounded-r-full text-[1.4rem] '>
                        <h1 className='pt-2'>Quick</h1>
                        <h1>Search</h1>
                    </div>
                    <div className='flex '>
                        <div className='pt-10 pl-10 pr-10 '>
                            <div className='text-gray-600 font-[500]'>
                                Categories
                            </div>
                        </div>
                        <div className='ml-4 h-[4rem] w-[1px] bg-gray-700 mt-6 mb-4'>
                            <hr />
                        </div>
                    </div>
                </div>

                <div className="flex justify-between w-[81%] ">

                    <div className=' w-[70%] justify-center '>
                        <div className='pt-5 pb-4 pl-[2%] text-gray-600 font-[500] text-[14px]'>
                            <ul className='flex gap-6 flex-wrap'>
                                {categories?.slice(0, 10).map((value) => (
                                    <div key={value.id}>
                                        <p onClick={() => handleSubmit(value.id, value?.name)}
                                            className='text-center cursor-pointer flex-wrap'>{value.name}</p>
                                    </div>
                                ))}
                            </ul>
                        </div>

                    </div>

                    <div className=' ml-auto mt-auto mr-4 underline cursor-pointer'>
                        <p onClick={() => navigate('category')}>See more</p>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default QuickSearch
