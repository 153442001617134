import React, { useEffect, useMemo, useState } from 'react'
import Footer from '../components/Footer'
import { AiOutlineSearch } from 'react-icons/ai'
import TopBar from '../components/TopBar'
import LoginModal from '../components/LoginModal'
import { Link, useLocation } from 'react-router-dom'
import { CiLocationOn } from 'react-icons/ci'
import { useDispatch, useSelector } from 'react-redux'
import { getJobSearch } from '../API/actions/jobsAction'
import moment from 'moment'
import CategoryModal from '../components/CategoryModal'
import CityModal from '../components/CityModal'
import LoginRequireModal from '../components/LoginRequireModal'
import CompanyModal from '../components/CompanyModal'
import { getApiJobs } from '../API/actions/jobsApiActions'
import FeaturedJobsModal from '../components/FeatureJobsModal'
import { ClipLoader } from 'react-spinners'
import { ThemeProvider } from 'styled-components'
import { Pagination, Stack, createTheme } from '@mui/material'

const FeaturedJobs = () => {

	const [visible, setVisible] = useState(false)
	const [jobId, setJobId] = useState('')

	const params = useLocation();
	const Search = params?.state?.Params
	console.log('searchData', Search)

	const [searchData, setSearchData] = useState(Search ? Search : '')
	const [categoryOpen, setCategoryOpen] = useState()
	const [cityOpen, setCityOpen] = useState()
	const [companyOpen, setCompanyOpen] = useState()

	const [nodata, setNoData] = useState(false)


	const [apiData, setApiData] = useState({
		search: '',
		country: '',
		category: '',
		city: '',
		company: '',
		salaryStart: '',
		salaryEnd: '',
		type: '',
	})

	const toggleVisibility = (id) => {
		setVisible(!visible)
		setJobId(id)
	}


	const [loginVisible, setLoginVisible] = useState(false)
	const toggleLoginVisibility = () => setLoginVisible(!loginVisible)

	const dispatch = useDispatch()


	const featuredApiJobs = useSelector(state => state?.apiJob?.apiJobs)

	const [isLoading, setIsLoading] = useState(true)


	useEffect(() => {
		if (featuredApiJobs) {
			setIsLoading(false)
		} else {
			dispatch(getApiJobs(''));
		}
	}, [dispatch, featuredApiJobs])




	useEffect(() => {
		if (featuredApiJobs?.length === 0) {
			setNoData(true)
		} else {
			setNoData(false)
		}
	})


	const [requireVisible, setRequireVisible] = useState(false)
	const toggleVisible = () => setRequireVisible(!requireVisible)

	const toggleChangeVisible = () => {
		setVisible(false)
		setRequireVisible(true)
	}

	// Pagination ==============

	const itemsPerPage = 5;
	const [currentPage, setCurrentPage] = useState(1);
	const handlePageChange = (event, newPage) => {
		setCurrentPage(newPage);
	};

	const [filteredData, setFilteredData] = useState([]);
	const [paginatedData, setPaginatedData] = useState(featuredApiJobs);

	useEffect(() => {
		const startIndex = (currentPage - 1) * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;
		setPaginatedData(filteredData?.slice(startIndex, endIndex));
	}, [currentPage, filteredData]);


	useEffect(() => {
		if (featuredApiJobs) {
			const filteredResult = featuredApiJobs.filter((item) =>
				item.title && item.title?.toLowerCase().includes(searchData?.toLowerCase())
			);
			setFilteredData(filteredResult);
			setCurrentPage(1);
		}
	}, [searchData, featuredApiJobs]);

	const theme = createTheme({ palette: { primary: { main: '#E83B46', contrastText: '#EEE' } } })



	return (
		<div className=''>
			{isLoading ?
				<div className='flex justify-center mt-[20%]'>
					<ClipLoader size='5rem' />
				</div>
				:
				<>
					<FeaturedJobsModal visible={visible} toggleVisibility={toggleVisibility} ID={jobId}
						toggleQrVisible={() => toggleChangeVisible()} />
					<LoginRequireModal visible={requireVisible} toggleVisible={toggleVisible} />
					<LoginModal loginVisible={loginVisible} toggleLoginVisibility={toggleLoginVisibility} />

					<CategoryModal open={categoryOpen} setOpen={setCategoryOpen} />
					<CityModal open={cityOpen} setOpen={setCityOpen} />
					<CompanyModal open={companyOpen} setOpen={setCompanyOpen} />


					<div className='bg-gray-200 p-4 min-h-screen'>
						<div className='flex max-md:flex-col gap-6 w-[70%] max-md:w-[98%] m-auto'>

							<div className='bg-white ml-4 rounded-xl w-[33%] max-md:w-[100%] max-md:m-auto '>
								<h1 className='bg-yellow-400 py-3 text-center font-[600] mb-5 '>Search</h1>

								<div className="relative w-[95%] mx-auto mb-4  ">
									<input type="text"
										className='pl-[50px] w-[98%] rounded-xl border-gray-400  focus:ring-yellow-200 ring-0'
										placeholder='Search here' value={searchData}
										onChange={(e) => setSearchData(e.target.value)} />
									<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none ">
										<AiOutlineSearch className="w-6 h-6 text-gray-500 dark:text-gray-400 " />
									</div>
								</div>

								{/* <div className='w-full flex justify-start '>
									<button onClick={() => dispatch(getJobSearch(apiData))}
										className=' text-white w-[90%] ml-auto mr-5 rounded-md py-[4px] bg-blue-600 px-5 py-3 mb-4 mt-2 hover:bg-blue-700 '>
										<p className='text-center font-[600]'>Search</p>
									</button>
								</div> */}

							</div>

							{nodata ?
								<div className='mt-10 ml-10'>
									<div className=" pt-[10%]">
										<h1 className=" text-[2rem] text-gray-500 mt-[-4rem] pt-10">No Featured Jobs Found</h1>
									</div>
								</div>
								:

								<div
									className=' mr-4 w-[80%]  max-md:w-[100%] max-md:ml-auto max-md:mr-0 rounded-md border-2 '>


									{paginatedData?.length > 0 ?
										<div>
											<div className='grid grid-cols-1 max-md:grid-cols-1 gap-2'>
												{paginatedData?.map((value) => (
													<div
														className='bg-white p-4  rounded-md border-[1px] border-solid border-gray-400  cursor-pointer'
														onClick={() => toggleVisibility(value.id)}
													>
														<div className='flex'>
															<div className='w-full'>
																<div className='flex'>
																	<h1 className='text-gray-800 text-[1.4rem] font-[600]'>{value.title}</h1>
																</div>
																<h1 className='text-gray-600 text-[1.1rem] mt-2 font-[500]'>{value.company}</h1>
																<h1 className='text-gray-600 text-[0.9rem] font-[400]'>< CiLocationOn
																	className="w-4 h-4 inline mr-2 text-gray-500  " />
																	{value.locations}</h1>
																{value.salary.length === 0 ?
																	'' :
																	<div className='flex mt-2'>
																		<h1 className='h-full text-gray-700 text-[0.8rem] font-[500] bg-gray-200 text-center py-1 px-4 rounded-md '>Salary {value.salary} </h1>
																	</div>
																}
																<div className='flex items-center mt-5'>
																	<h1 className=' ml-4 text-gray-600 text-[.8rem] font-[400] '>{moment(value?.date).format('YYYY-MM-DD')}</h1>

																	<h1 className='bg-blue-500 ml-auto text-white font-[500] px-6 rounded-md py-[3px] hover:bg-blue-600 cursor-pointer'
																		onClick={() => toggleVisibility(value.id)}>View Details</h1>
																</div>
															</div>
														</div>
													</div>

												))}
											</div>
											<div className='mt-10'>
												<ThemeProvider theme={theme}>
													<Stack direction="row" justifyContent="center" marginTop={2}>
														<Pagination
															count={Math.ceil(filteredData?.length / itemsPerPage)}
															page={currentPage}
															onChange={handlePageChange}
															color="primary"
														/>
													</Stack>
												</ThemeProvider>
											</div>
										</div>
										:
										<p className='text-[1.5rem] text-gray-500 font-[400]'>No jobs</p>
									}
								</div>
							}
						</div>
					</div>
					<Footer />
				</>}
		</div>

	)


}

export default FeaturedJobs

