import { Modal } from 'flowbite-react'
import React, { useState } from 'react'
import { PhoneData } from '../../Utils/Constant';

const PhoneModal = ({ visible, togglePhoneVisible, set }) => {

    const add = (code) => {
        set(code);
    };

    const [data, setData] = useState(PhoneData);

    const search = (query) => {
        const searched = PhoneData.filter((data) => {
            return data.name.toLowerCase().includes(query.toLowerCase());
        });
        setData(searched);
    };


    return (
        <div>
            <Modal show={visible} size={'2xl'} onClose={() => togglePhoneVisible()}>
                <Modal.Header > Select Country Code</Modal.Header>
                <Modal.Body className='  bg-white  max-md:ml-0'>
                    <input
                        onChange={(e) => search(e.target.value)}
                        style={{
                            borderRadius: '5px',
                            marginVertical: '8px',
                            borderColor: 'gray',
                            borderWidth: '1px',
                            paddingVertical: '6px',
                            marginHorizontal: '10px',
                            marginTop: '-10px',
                            marginBottom: '20px',
                            width:'100%'
                        }} className="pl-6 py-2"
                        placeholder={'Search Country'}
                    />
                    <ul style={{ margin: '0', padding: '0', listStyle: 'none', marginTop: '20px' }} className="min-h-[500px]">
                        {data.map((item, index) => (
                            <li key={index}>
                                <div
                                    onClick={() => add(item.dial_code)}
                                    style={{ cursor: 'pointer',marginTop:8 }}  >
                                    <div>
                                        <div style={{ flexDirection: 'row', alignItems: 'center' }}>

                                            <h5  style={{ marginHorizontal: '20px',  borderRightWidth: '1px',  width: '70px',  }} >
                                                {item.dial_code}
                                            </h5>
                                            <h5  style={{  textAlign: 'right',  color: 'gray',  }}  >
                                                {item.name}
                                            </h5>
                                        </div>
                                        <div
                                            style={{
                                                backgroundColor: '#777777',
                                                height: '0.5px',
                                                marginHorizontal: '10px',
                                                marginVertical: '5px',
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>



                </Modal.Body>
            </Modal>
        </div >
    )
}

export default PhoneModal
