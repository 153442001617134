import { ALL_JOBS, ERROR, GETJOB_CATEGORY, GETJOB_COMPANY, GETSEARCH_JOB, GET_JOB, GET_JOBS, LOADING, RECENT_JOBS, SUCCESS } from "../../Utils/Constant";



const job = (state = { isLoading: true, success: false, error: false, jobs: [] }, action) => {
    switch (action.type) {
        case LOADING:
            return { ...state, isLoading: true }
        case SUCCESS:
            return { ...state, isLoading: false, success: true, error: false }
        case ERROR:
            return { ...state, isLoading: false, success: false, error: true }
        case ALL_JOBS:
            return { ...state, jobs: action.payload.jobs }
        case GET_JOBS:
            return { ...state, jobs: action.payload }
        case GET_JOB:
            return { ...state, job: action.payload }
        case GETSEARCH_JOB:
            return { ...state, jobs: action.payload }
        case RECENT_JOBS:
            return { ...state, jobs: action.payload }
        case GETJOB_CATEGORY:
            return { ...state, jobs: action.payload }
        case GETJOB_COMPANY:
            return { ...state, jobs: action.payload }
        default:
            return state;
    }
}

export default job;
