import { Carousel } from 'flowbite-react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import QuickSearch from '../components/QuickSearch';
import GetApp from '../components/GetApp';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FeaturedCategories } from '../API/actions/categoriesAction';
import TopBar from '../components/TopBar';
import { getCompanies } from '../API/actions/companiesAction';
import LoginRequireModal from '../components/LoginRequireModal';
import { AiOutlineSearch } from 'react-icons/ai';
import { ClipLoader } from 'react-spinners';
import { getApiJobsRecent } from '../API/actions/jobsApiActions';
import moment from 'moment';
import { CiLocationOn } from 'react-icons/ci';
import FeaturedJobsModal from '../components/FeatureJobsModal';
import { SESSION_LOGIN } from '../Utils/Constant';
import { GrAnnounce } from "react-icons/gr";
import { HiSpeakerphone } from 'react-icons/hi';
import { FaArrowRight } from 'react-icons/fa';
import { RxCross2 } from "react-icons/rx";




export default function Page() {

 


  const [search, setSearch] = useState('')
  console.log(search)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const featuredCategories = useSelector(state => state.category.featured)

  useEffect(() => {
    dispatch(FeaturedCategories())
  }, [dispatch])

  const recentJobs = useSelector(state => state.apiJob.apiJobs)
  const loading = useSelector(state => state.loading.recentJobLoading)

  useEffect(() => {
    dispatch(getApiJobsRecent(search))
  }, [dispatch, ])

  const companies = useSelector(state => state.company.companies)

  useEffect(() => {
    console.log('company', companies)
  }, [companies])

  useEffect(() => {
    dispatch(getCompanies())
  }, [dispatch])

  const [visible, setVisible] = useState(false)
  const [jobId, setJobId] = useState('')
  const toggleVisibility = (id) => {
    setVisible(!visible)
    setJobId(id)
  }

  const [requireVisible, setRequireVisible] = useState(false)
  const toggleVisible = () => setRequireVisible(!requireVisible)

  const [seeker, setSeeker] = useState()

  const handleSubmit = (id , name) => {
    navigate('/jobs', { state: { ID: id , Name : name } })
  }

  useEffect(() => {
    const isLogin = sessionStorage.getItem(SESSION_LOGIN)
    if (isLogin === "true") {
      setSeeker(false)
    } else {
      setSeeker(true)
    }
  }, [seeker]);


  const [show, setShow] = useState(true)

  const handlSearchSubmit = () => {
    navigate('/SearchPage', { state: { Params: search } })
  }

  return (
    <div>
      {loading ?
        <div className='flex justify-center mt-[20%]'>
          <ClipLoader size='5rem' />
        </div>
        :
        <>
          {/* {show ?
            <div className='bg-[#F2B127] flex py-6 gap-4 '>
              <h2 className='ml-auto'>Hire candidates from just 500pkr/month </h2>
              <h2>|</h2>
              <div className="flex gap-2">
                <h2>Sign Up with HIRINGS +</h2>
                <FaArrowRight color='#6C2BDA' className='mt-[5px]' />
              </div>
              <div className='ml-auto mr-6 cursor-pointer' onClick={() => setShow(false)}>
                <RxCross2 size={20} />
              </div>
            </div>
            :
            ''
          } */}

          <div className='bg-gray-200 pt-6'>
            <div className='flex justify-center items-center'>
              <form onSubmit={handlSearchSubmit} className='flex'>
                <div className="relative mb-6 max-md:ml-0">
                  <input
                    type="text"
                    onChange={(e) => setSearch(e.target.value)}
                    className='pl-[50px] w-[34rem] max-md:w-[15rem] max-md:h-[1.9rem] max-md:text-[0.7rem] rounded-s-xl border-gray-400 focus:ring-yellow-200 ring-0'
                    placeholder='Search here'
                  />
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none mt-[0px]">
                    <AiOutlineSearch className="w-4 h-4 text-gray-500" />
                  </div>
                </div>
                <input
                  type='submit'
                  className='text-center mb-6 max-md:text-[0.6rem] text-white mr-4 font-[600] bg-[#2994FF] py-[9px] max-md:py-[8px] px-6 rounded-r-xl'
                  value='Search job'
                />
              </form>

            </div>
          </div>
          <div className='max-xl:hidden'>
            <QuickSearch />
          </div>

          <FeaturedJobsModal visible={visible} toggleVisibility={toggleVisibility} ID={jobId}
            navigate={navigate} />
          {/* <ProviderModal visible={visible} toggleVisibility={toggleVisibility} ID={jobId} navigate={navigate} /> */}
          <LoginRequireModal visible={requireVisible} toggleVisible={toggleVisible} />

          <h1 className=' text-[1rem] text-center p-2 font-[800] max-md:text-[0.6rem] max-md:font-[400] text-white bg-[#F0A51E]'>We
            will connect with hiring agencies, job banks and recruiters to place your CV</h1>
          <GetApp />
          {seeker ?
            <div className='grid grid-cols-2   '>
              <div className='bg-[#F0A51E] p-8 max-md:p-2 py-6'>
                <center>
                  <h1 className='uppercase text-[1.8rem] max-md:text-[.8rem] font-[500] text-gray-800 '>I
                    AM A JOBSEEKER</h1>
                    <button onClick={() => navigate('/login')} className='cursor-pointer mt-3 mb-3 text-[1rem] max-md:text-[0.7rem] bg-gray-800 text-white rounded-md px-8 max-md:px-[7px] py-1'>Get
                      Started
                    </button>
                   <p className='w-[70%] max-md:w-[100%] font-[500] max-md:font-[300] max-md:text-[0.6rem] text-gray-800'>Create your
                    professional resume with online resume builder and start applying for best jobs</p>
                </center>
              </div>
              <div className='bg-[#4095FF] p-8 max-md:p-2 py-6'>
                <center>
                  <h1 className='uppercase text-[1.8rem] font-[500] text-gray-200 max-md:text-[.8rem] '>I
                    AM A JOB PROVIDER</h1>
                   <button onClick={() => navigate('/providerRegister')} className='cursor-pointer mt-3 mb-3 text-[1rem] max-md:text-[0.7rem] bg-gray-800 text-white rounded-md px-8 max-md:px-[7px] py-1'>Get
                      Started
                    </button>
 
                  <p className='w-[70%] max-md:w-[100%] font-[500] max-md:font-[300] max-md:text-[0.6rem] text-gray-200'>Elevate your business
                    with our user-friendly resume builder, helping your clients secure their dream roles
                    effortlessly</p>
                </center>
              </div>
            </div>
            :
            ''}



          <div className=' py-10 bg-gray-100'>
            <center>
              <h1 className='uppercase text-[40px] font-[400]  max-md:text-[1rem]'>FIND LATEST JOBS</h1>
              <h1 className='text-[#999] text-[16px] font-[400]  max-md:w-[80%]  max-md:text-[.8rem]'>The Most Comprehensive
                Search Engine For Jobs.</h1>
              <hr className='w-[3rem] h-2 bg-green-400 rounded-xl mt-4' />
            </center>
            <div className='w-[70%] max-md:w-[95%] m-auto'>
              <div className='grid grid-cols-2 max-md:grid-cols-2 gap-5 max-md:gap-2 m-auto mt-5 '>
                {recentJobs.map((value) => (
                  <div onClick={() => toggleVisibility(value.id)}
                    className='border-2 rounded-xl bg-white hover:shadow-xl hover:shadow-gray-400 cursor-pointer p-6 transition duration-300'>
                    <div className='w-full'>
                      <div className='flex'>
                        <h1 className='text-gray-800 text-[1.4rem] max-md:text-[.6rem] font-[600]'>{value.title}</h1>
                      </div>
                      <h1 className='text-gray-600 text-[1.1rem] mt-2 font-[500] max-md:text-[.6rem]'>{value.company}</h1>
                      <h1 className='text-gray-600 text-[0.9rem] font-[400] max-md:text-[.6rem]'>< CiLocationOn
                        className="w-4 h-4 inline mr-2 text-gray-500   " />
                        {value.locations}</h1>
                      <div className='flex mt-2'>
                        <h1 className='h-full text-gray-700 text-[0.8rem] font-[500] bg-gray-200 text-center py-1 px-4 rounded-md max-md:text-[.6rem]'>Salary {value.salary} </h1>
                      </div>
                      <div className='flex items-center mt-5'>
                        <h1 className=' ml-4 text-gray-600 text-[.8rem] max-md:text-[.6rem] font-[400] '>{moment(value?.date).format('YYYY-MM-DD')}</h1>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <center>
              <p onClick={() => navigate('/featured')}
                className='border-2  py-[2px] cursor-pointer w-[7rem] max-md:w-[5rem] max-md:text-[.9rem] rounded-xl mt-10 text-[#2994FF] border-[#2994FF] hover:text-white hover:bg-[#2994FF]'> Show
                All </p>
            </center>
          </div>

          <div className='my-20 bg-[#F3F4F5] pt-4 px-20 max-md:px-2'>
            <center>
              <h1 className='uppercase text-[40px] font-[400] max-md:text-[1rem]'>TOP CATEGORIES</h1>
              <p className='text-[#999] text-[16px] font-[400] max-md:text-[.8rem] '> A better career is out there. We’ll
                help you find it.</p>
              <hr className='w-[3rem] max-md:w-[2rem] h-2 bg-green-400 rounded-xl mt-4' />

            </center>
            <div className='container mx-auto mt-10'>
              <div className='grid grid-cols-2 md:grid-cols-2 rounded-2xl lg:grid-cols-4 gap-8 max-md:gap-2 mb-1'>
                {featuredCategories?.slice(0, 4).map((value) => (
                  <div onClick={() => handleSubmit(value.id , value?.name)} key={value.id} className='bg-white cursor-pointer shadow-lg rounded-2xl hover:shadow-2xl transition duration-300 border-2'>
                    <div className='h-[15rem] max-md:h-[11rem]'>
                      <img src={value.image} alt={value.alt} className='w-32 h-32 max-md:w-20  max-md:h-20 mx-auto mt-4 p-4 rounded-full bg-white' />
                      <div className='p-4'>
                        <h2 className='text-xl font-semibold mb-2 text-center max-md:text-[.9rem]'>{value.name}</h2>
                      </div>
                    </div>
                    <div className='flex justify-center mb-4'>
                      <p className='bg-[#F0A51E] text-white font-semibold rounded-full py-1 w-32 text-center max-md:text-[.9rem]' > {value.jobs} JOBS </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <center className='pb-4'>
              <p onClick={() => navigate('/category')}
                className='border-2  py-[2px] cursor-pointer w-[7rem] max-md:w-[5rem] max-md:text-[.9rem] rounded-xl mt-10 text-[#2994FF] border-[#2994FF] hover:text-white hover:bg-[#2994FF]'> Show
                All </p>
            </center>
          </div>

          <div>
            <center>
              <h1 className='uppercase text-[40px] font-[400] max-md:text-[1rem]'>COMPANIES HIRING
                NOW</h1>
              <p className='text-[#999] text-[16px] font-[400] max-md:text-[.8rem]'> The most comprehensive search engine for
                jobs.</p>
              <hr className='w-[3rem] h-2 bg-green-400 rounded-xl mt-4' />
            </center>
            <div className='h-40 w-[100%] my-10 max-md:my-0 '>
              <Carousel pauseOnHover>
                <div className='flex gap-10 max-md:gap-10 justify-center'>
                  <img
                    alt="..."
                    src="./assets/yamaha.png"
                    className='w-30 max-md:w-[3rem] max-md:h-[2rem] h-20'
                  />
                  <img
                    alt="..."
                    src="./assets/hp.png"
                    className='w-30 max-md:w-[3rem] max-md:h-[2.4rem] h-20'
                  />
                  <img
                    alt="..."
                    src="./assets/palo.png"
                    className='w-30 max-md:w-[3rem] max-md:h-[4rem] max-md:hidden h-20'
                  />
                  <img
                    alt="..."
                    src="./assets/hyundai.png"
                    className='w-20 h-20 max-md:w-[3rem] max-md:h-[3rem] '
                  />
                  <img
                    alt="..."
                    src="./assets/ford.png"
                    className='w-30 max-md:w-[5rem] max-md:h-[4rem] h-20 max-md:hidden'
                  />
                </div>
                <div className='flex gap-10 justify-center'>
                  <img
                    alt="..."
                    src="./assets/code.png"
                    className='w-30 max-md:w-[5rem] max-md:h-[4rem] h-20 max-md:hidden'
                  />
                  <img
                    alt="..."
                    src="./assets/nike.png"
                    className='w-30 max-md:w-[5rem] max-md:h-[4rem] h-20'
                  />
                  <img
                    alt="..."
                    src="./assets/himalaya.png"
                    className='w-30 max-md:w-[5rem] max-md:h-[4rem] h-20 max-md:hidden'
                  />
                  <img
                    alt="..."
                    src="./assets/palo.png"
                    className='w-30 max-md:w-[6rem] max-md:h-[3rem] h-20'
                  />
                  <img
                    alt="..."
                    src="./assets/nokia.png"
                    className='w-30 max-md:w-[5rem] max-md:h-[4rem] h-20'
                  />
                </div>
              </Carousel>
            </div>
          </div>

          <section className="text-gray-600 bg-[#F3F4F5] body-font">
            <div className="container px-5 py-24 mx-auto">
              <h1 className="text-3xl font-medium title-font text-gray-900 text-center max-md:text-[1rem]">Testimonials</h1>
              <hr className='w-[3rem] max-md:w-[2rem] h-2 bg-green-400 rounded-xl m-auto mb-12 mt-4' />
              <div className="flex flex-wrap -m-4">
                <div className="p-4 md:w-1/2 w-full">
                  <div className="h-full bg-white p-8 rounded">
                    <svg fill="currentColor" className="block w-5 h-5 text-gray-400 mb-4"
                      viewBox="0 0 975.036 975.036">
                      <path
                        d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                    </svg>
                    <h1 className='text-[1.5rem] max-md:text-[1rem] mb-2'>Job Seeker</h1>
                    <p className="leading-relaxed mb-6 max-md:text-[.8rem]">Jobss.com.au has been a game-changer in my
                      job search. The job alerts are incredibly helpful, and I've received job
                      offers I wouldn't have found elsewhere. The resume tips and interview
                      preparation resources were invaluable in securing my dream job. Thanks to
                      jobss.com.au, I'm now on a career path I love.
                    </p>
                    <div className="inline-flex items-center">
                      <img alt="testimonial" src="./assets/one.jpg"
                        className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center" />
                      <span className="flex-grow flex flex-col pl-4">
                        <span className="title-font font-medium text-gray-900">Holden Caulfield</span>
                        <span className="text-gray-500 text-sm">UI DEVELOPER</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="p-4 md:w-1/2 w-full">
                  <div className="h-full bg-white p-8 rounded">
                    <svg fill="currentColor" className="block w-5 h-5 text-gray-400 mb-4"
                      viewBox="0 0 975.036 975.036">
                      <path
                        d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                    </svg>
                    <h1 className='text-[1.5rem] mb-2 max-md:text-[1rem]'>Employer</h1>
                    <p className="leading-relaxed mb-6 max-md:text-[.8rem]">Joining the team at jobss.com.au has been a
                      fulfilling experience. The company's commitment to innovation and helping
                      job seekers is truly inspiring. I've had the opportunity to contribute to a
                      platform that makes a real difference in people's lives. The supportive work
                      environment and focus on professional growth have made jobss.com.au an
                      exceptional place to work.</p>
                    <div className="inline-flex items-center">
                      <img alt="testimonial" src="./assets/two.jpg"
                        className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center" />
                      <span className="flex-grow flex flex-col pl-4">
                        <span className="title-font font-medium text-gray-900">Alper Kamu</span>
                        <span className="text-gray-500 text-sm">DESIGNER</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>}
    </div >
  )
}