import { Avatar, Dropdown, Navbar } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HiLogout, HiViewGrid } from "react-icons/hi";
import {
  SESSION_ID,
  SESSION_LOGIN,
  SESSION_SEEKER,
  SESSION_TYPE,
} from "../Utils/Constant";

const TopBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [login, setLogin] = useState();
  const [seekerData, setSeekerData] = useState();

  useEffect(() => {
    const isLogin = sessionStorage.getItem(SESSION_LOGIN);
    if (isLogin === "true") {
      setLogin(true);
      setSeekerData(JSON.parse(sessionStorage.getItem(SESSION_SEEKER)));
      // console.log(JSON.parse(sessionStorage.getItem(SESSION_SEEKER)))
    } else {
      setLogin(false);
    }
  }, []);

  const logout = () => {
    sessionStorage.setItem(SESSION_LOGIN, "false");
    sessionStorage.setItem(SESSION_ID, null);
    sessionStorage.setItem(SESSION_TYPE, null);
    sessionStorage.setItem(SESSION_SEEKER, null);
    setLogin(false);
    navigate('/')
  };

  return (
    <div className="">
      <div className="flex justify-between mt-5 mb-5 w-[55%] ml-auto max-md:w-[80%]">
        <img
          src="/assets/logo.png"
          alt=""
          className="mt-4 w-[10rem] max-md:w-[9rem] max-md:h-[2rem] max-md:ml-6 "
        />
        {login ? (
          <div className="ml-auto mr-4 ">
            <Dropdown
              arrowIcon={false}
              inline
              label={
                <Avatar
                  alt="User settings"
                  img="./assets/profile.png"
                  rounded
                  className="max-md:w-[2.2rem] max-md:h-[1rem]"
                />
              }
              className="w-50 p-4 rounded-xl"
            >
              <Dropdown.Header>
              </Dropdown.Header>
              <Link to="/profileinfo" className="flex mt-[-20px]">
                <div className="ml-2 mr-4 mt-[4px]">
                  <HiViewGrid />
                </div>
                Profile
              </Link>

              <Dropdown.Divider />
              <div
                className="flex py-2 cursor-pointer"
                onClick={() => logout()}
              >
                <div className="ml-2 mr-4 mt-[4px]">
                  <HiLogout />
                </div>
                Sign out
              </div>
            </Dropdown>
          </div>
        )

          :

          (
            <div
              className="ml-auto mr-5 cursor-pointer"
              onClick={() => navigate("/login")}
            >
              <p className="bg-white text-black border-[1px] px-4 py-[4px] max-md:text-[.8rem] max-md:px-[4px] rounded-md border-gray-900 hover:bg-[#2894FF] hover:border-[#2894FF]  hover:text-white ">
                Log In
              </p>
            </div>
          )}
      </div>

      <Navbar fluid rounded className=" h-20 ">
        <Navbar.Brand></Navbar.Brand>
        <div className="flex md:order-4">
          <Navbar.Toggle />
        </div>
        <Navbar.Collapse className="bg-white z-50 rounded-md">
          <Link
            to="/"
            className={`
            ${location.pathname === "/"
                ? "border-b-4  border-yellow-300"
                : "hover:border-b-4 "
              }`}
          >
            <Navbar.Link>
              <p className="font-[700] ">Home</p>
            </Navbar.Link>
          </Link>
          <Link
            to="/category"
            className={`  
        ${location.pathname === "/category"
                ? "border-b-4  border-yellow-300"
                : "hover:border-b-4 "
              }`}
          >
            <Navbar.Link>
              <p className="font-[700]">Categories</p>
            </Navbar.Link>
          </Link>

          <Link
            to="/SearchPage"
            className={`${location.pathname === "/SearchPage"
              ? "border-b-4  border-yellow-300"
              : "hover:border-b-4 "
              } `}
          >
            <Navbar.Link>
              <p className="font-[700]">Find Jobs</p>
            </Navbar.Link>
          </Link>

          <Link
            to="/featured"
            className={`${location.pathname === "/featured"
              ? "border-b-4  border-yellow-300"
              : "hover:border-b-4 "
              } `}
          >
            <Navbar.Link>
              <p className="font-[700]">Featured Jobs</p>
            </Navbar.Link>
          </Link>
          <Link
            to="/pricing"
            className={`${location.pathname === "/pricing"
              ? "border-b-4  border-yellow-300"
              : "hover:border-b-4 "
              } `}
          >
            <Navbar.Link>
              <p className="font-[700]">Pricing</p>
            </Navbar.Link>
          </Link>
          {login ? (
            <>
              <Link
                to="/applied"
                className={`${location.pathname === "/applied"
                  ? "border-b-4  border-yellow-300"
                  : "hover:border-b-4 "
                  } `}
              >
                <Navbar.Link>
                  <p className="font-[700]">Applied Jobs</p>
                </Navbar.Link>
              </Link>

              <Link
                to="/resume"
                className={`${location.pathname === "/resume"
                  ? "border-b-4  border-yellow-300"
                  : "hover:border-b-4 "
                  } `}
              >
                <Navbar.Link>
                  <p className="font-[700]">Resume</p>
                </Navbar.Link>
              </Link>
            </>
          )
            :
            (
              ""
            )}

          <Link
            to="/contact"
            className={`${location.pathname === "/contact"
              ? "border-b-4  border-yellow-300"
              : "hover:border-b-4 "
              } `}
          >
            <Navbar.Link>
              <p className="font-[700]">Contact Us</p>
            </Navbar.Link>
          </Link>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default TopBar;
