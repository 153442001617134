import {
    ALL_BOOKMARKS, BOOKMARKS_LOADING
} from "../../Utils/Constant";
import * as api from "../index";

export const AllBookmarks = (user) => async (dispatch) => {
    try {
        dispatch({ type:BOOKMARKS_LOADING  })
        const { data: { data } } = await api.fetchBookmarks(user);
            dispatch({type: ALL_BOOKMARKS, payload: {bookmarks: data}})
    } catch (error) {
        console.log(error)
    }
}
