import React from 'react'
import Search from '../components/Search'
import QuickSearch from '../components/QuickSearch'
import GetApp from '../components/GetApp'
import Footer from '../components/Footer'
import TopBar from '../components/TopBar'

const company = [
    {
        img: '/assets/kfc.png',
        about: 'Google LLC is an American multinational technology company focusing on.....',
        state: 'U.S',
        count: '200'
    },
    {
        img: '/assets/sprite.png',
        about: 'Google LLC is an American multinational technology company focusing on.....',
        state: 'U.S',
        count: '200'
    },
    {
        img: '/assets/kfc.png',
        about: 'Google LLC is an American multinational technology company focusing on.....',
        state: 'U.S',
        count: '200'
    },
    {
        img: '/assets/sprite.png',
        about: 'Google LLC is an American multinational technology company focusing on.....',
        state: 'U.S',
        count: '200'
    },

]
const recent = [
    {
        status: 'New',
        time: 'Today',
        role: 'Occupational therapist (G2) - School Readiness',
        company: 'Facebook',
        about: 'IT & Communications',
        requirement: 'Bachelor',
        city: 'Sydney, Australia',
    },
    {
        status: 'New',
        time: 'Today',
        role: 'Occupational therapist (G2) - School Readiness',
        company: 'Facebook',
        about: 'IT & Communications',
        requirement: 'Bachelor',
        city: 'Sydney, Australia',
    },
    {
        status: 'New',
        time: 'Today',
        role: 'Occupational therapist (G2) - School Readiness',
        company: 'Facebook',
        about: 'IT & Communications',
        requirement: 'Bachelor',
        city: 'abc, Australia',
    },

]
const category = [
    {img2: '/assets/marketing.png', title: 'Accounting', count: '300', alt: 'acount',},
    {img2: '/assets/medical.png', title: 'Accounting', count: '300', alt: 'acount',},
    {img2: '/assets/marketing.png', title: 'Accounting', count: '300', alt: 'acount',},
    {img2: '/assets/medical.png', title: 'Accounting', count: '300', alt: 'acount',},

]
const Seeker = () => {
    return (
        <div>
            <TopBar/>
            <Search/>
            <div className='max-md:hidden'>
                <QuickSearch/>
            </div>
            <h1 className='uppercase text-[1rem] text-center p-2 font-[800] text-white bg-[#F0A51E]'>Job Seeker</h1>

            <GetApp/>
            <div className='mt-10 bg-gray-200 py-20 '>
                <center>
                    <h1 className='uppercase text-[1.9rem] font-[600]'>Top Companies</h1>
                    <p className='uppercase font-[500] '>The Most Comprehensive Search Engine For Jobs</p>
                </center>

                <div className='w-[90%] m-auto'>
                    <div className='grid grid-cols-3  max-md:grid-cols-1 gap-10 m-auto mt-5'>
                        {recent.map((value) => (
                            <div className='border-2 rounded-xl bg-white pb-2 shadow-xl shadow-gray-400'>
                                <div className='flex mt-5'>
                                    <p className='bg-[#ccf0d0] rounded-md px-2 ml-4 text-[#207a00]'>{value.status}</p>
                                    <p className='ml-auto mr-4 font-[500]'>{value.time}</p>
                                </div>
                                <h1 className='font-[600] ml-4 w-[80%]'>{value.role}</h1>
                                <h1 className='ml-4 font-[500]'>{value.company}</h1>
                                <div className='flex'>
                                    <p className='ml-4 font-[700]'>{value.about}</p>
                                    <p className='ml-auto mr-4 font-[700]'>{value.requirement}</p>
                                </div>
                                <h1 className='text-[1.1rem] mt-2 text-center text-blue-600 font-[600]'>{value.city}</h1>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className='mt-10 bg-gray-200 pb-10 pt-10 '>
                <center>
                    <h1 className='uppercase text-[1.9rem] font-[600]'>RECENT JOBS</h1>
                    <p className='uppercase font-[500] '>The Most Comprehensive Search Engine For Jobs</p>
                </center>

                <div className='w-[90%] m-auto'>
                    <div className='grid grid-cols-3  max-md:grid-cols-1 gap-10 m-auto mt-5'>
                        {recent.map((value) => (
                            <div className='border-2 rounded-xl bg-white pb-2'>
                                <div className='flex mt-5'>
                                    <p className='bg-[#ccf0d0] rounded-md px-2 ml-4 text-[#207a00]'>{value.status}</p>
                                    <p className='ml-auto mr-4 font-[500]'>{value.time}</p>
                                </div>
                                <h1 className='font-[600] ml-4 w-[80%]'>{value.role}</h1>
                                <h1 className='ml-4 font-[500]'>{value.company}</h1>
                                <div className='flex'>
                                    <p className='ml-4 font-[700]'>{value.about}</p>
                                    <p className='ml-auto mr-4 font-[700]'>{value.requirement}</p>
                                </div>
                                <h1 className='text-[1.1rem] mt-2 text-center text-blue-600 font-[600]'>{value.city}</h1>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className='my-20'>
                <center>
                    <h1 className='uppercase text-[1.9rem] font-[600]'>TOP CATEGORIES</h1>
                    <p className='uppercase font-[400] '> A better career is out there. We’ll help you find it.</p>
                </center>
                <div className='w-[80%] m-auto p-5'>
                    <div className='grid grid-cols-4  max-md:grid-cols-1  gap-4'>
                        {category.map((value) => (
                            <center>
                                <div className='border-2 pt-4 rounded-xl w-[15rem] shadow-xl shadow-gray-400'>
                                    <img src={value.img2} alt={value.alt} className='w-[4rem] h-[4rem]'/>
                                    <h1 className='my-3 font-[500]'>{value.title}</h1>
                                    <h1 className='bg-gray-300 font-[500] mb-2 rounded-xl px-2 py-1 w-[8rem]'>{value.count} jobs</h1>
                                </div>
                            </center>
                        ))}
                    </div>
                </div>

            </div>

            <Footer/>
        </div>
    )
}

export default Seeker
