import React from 'react'
import TopBar from '../components/TopBar'
import Search from '../components/Search'

const PostJob = () => {
    return (
        <div>
            <TopBar/>
            <Search/>
            <section class="text-gray-600 body-font">

                <div class="container px-5 mx-auto flex flex-wrap">
                    <div class="flex flex-wrap w-full">
                        <div class="flex flex-wrap w-full my-3 mt-3">
                            <div class="lg:w-1/2 w-full  lg:mb-0">
                                <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Post a
                                    Job</h1>
                                <div class="h-1 w-20 bg-indigo-500 rounded"></div>
                            </div>
                            <div className='my-10'>
                                <center>
                                    <img src="assets/jobhire.png" alt="" className='w-[20%]'/>
                                    <h2 className='text-[2.1rem] mt-4 font-[600] text-black'>Your next hire is here</h2>
                                    <p className='mt-4 text-gray-400'>Publish your jobs on XYZ and start hiring the best
                                        candidates.</p>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>

                <section class="text-gray-600 body-font">
                    <div class="container mx-auto flex   md:flex-row flex-col items-center">
                        <div
                            class="lg:flex-grow md:w-1/2 lg:px-10  flex flex-col md:items-start md:text-left items-center text-center">
                            <div className='flex max-sm:flex-col px-6'>
                                <div
                                    class="flex flex-col w-[50%] max-sm:w-full md:justify-start max-md:mt-[-6.5rem] sm:[mt-[5rem]] justify-center ">
                                    <h1 className='font-[700] text-[1.5rem] w-full text-left'>1- The Job</h1>
                                    <div class="relative mr-4 w-full">
                                        <label for="hero-field" class="leading-7 text-sm text-gray-600">Job
                                            Title</label>
                                        <input type="text" id="hero-field" name="hero-field"
                                               class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                                    </div>
                                    <div class="relative mr-4 w-full">
                                        <label for="hero-field" class="leading-7 text-sm text-gray-600">Job Type</label>
                                        <input type="text" id="hero-field" name="hero-field"
                                               class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                                    </div>
                                    <div class="relative mr-4 w-full">
                                        <label for="hero-field" class="leading-7 text-sm text-gray-600">Required
                                            Language</label>
                                        <input type="text" id="hero-field" name="hero-field"
                                               class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                                    </div>
                                    <div class="relative mr-4 w-full">
                                        <label for="hero-field" class="leading-7 text-sm text-gray-600">Remote
                                            Location</label>
                                        <input type="text" id="hero-field" name="hero-field"
                                               class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                                    </div>
                                    <div class="relative mr-4 w-full">
                                        <label for="hero-field" class="leading-7 text-sm text-gray-600">Job
                                            Description</label>
                                        <input type="text" id="hero-field" name="hero-field"
                                               class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                                    </div>
                                    <div class="relative mr-4 w-full">
                                        <label for="hero-field" class="leading-7 text-sm text-gray-600">Job Type</label>
                                        <input type="text" id="hero-field" name="hero-field"
                                               class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                                    </div>
                                </div>
                                <div className='ml-5'>
                                    <div>

                                        <h1 className='font-[700] text-[1.5rem] w-full text-left '>2- The Company</h1>
                                        <div class="relative mr-4 w-full">
                                            <label for="hero-field" class="leading-7 text-sm text-gray-600">Company
                                                Name</label>
                                            <input type="text" id="hero-field" name="hero-field"
                                                   class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                                        </div>
                                        <div class="relative mr-4 w-full">
                                            <label for="hero-field" class="leading-7 text-sm text-gray-600">Job
                                                URL</label>
                                            <input type="text" id="hero-field" name="hero-field"
                                                   class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                                        </div>
                                    </div>
                                    <div>
                                        <h1 className='font-[700] text-[1.5rem] text-left mt-8'>3- Contact
                                            Information</h1>
                                        <div class="relative mr-4 w-full">
                                            <label for="hero-field" class="leading-7 text-sm text-gray-600">Name</label>
                                            <input type="text" id="hero-field" name="hero-field"
                                                   class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                                        </div>
                                        <div class="relative mr-4 w-full">
                                            <label for="hero-field" class="leading-7 text-sm text-gray-600">Work
                                                Email</label>
                                            <input type="text" id="hero-field" name="hero-field"
                                                   class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                                        </div>
                                        <div class="relative mr-4 w-full">
                                            <label for="hero-field" class="leading-7 text-sm text-gray-600">Phone
                                                Number</label>
                                            <input type="text" id="hero-field" name="hero-field"
                                                   class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                                        </div>
                                    </div>
                                    <div className='flex jusify-center  my-5'>
                                        <button
                                            class=" block text-white mt-5 bg-indigo-500 border-0 py-2 px-[3rem] max-sm:w-full focus:outline-none hover:bg-indigo-600 rounded text-lg">Post
                                            a Job
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center md:h-[30rem] lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                            <img class=" object-cover object-center rounded max-md:hidden" alt="hero"
                                 src="assets/jobimage.png"/>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    )
}

export default PostJob
