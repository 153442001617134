
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Page from './pages/Page';
import SearchPage from './pages/SearchPage';
import Login from './pages/Login';
import Register from './pages/Register';
import VerificationCode from './pages/VerificationCode';
import Verify from './pages/Verify';
import ChangePassword from './pages/ChangePassword';
import Provider from './pages/Provider';
import Seeker from './pages/Seeker';
import Categories from './pages/Categories';
import Companies from './pages/Companies';
import ContactUs from './pages/ContactUs';
import JobDetail from './pages/JobDetail';
import RecommendedJobs from './pages/RecommendedJobs';
import CrmSinglePage from './pages/CrmSinglePage';
import PostJob from './pages/PostJob';
import Test from './pages/Test';
import SeekerInfo from './pages/SeekerInfo';
import ProfileInfo from './pages/ProfileInfo';
import ApplyForm from './pages/ApplyForm';
import Jobs from './pages/Jobs';
import Resume from './pages/Resume';
import SavedJobs from './pages/SavedJobs';
import FeaturedJobs from './pages/FeaturedJobs';
import { GoogleOAuthProvider } from "@react-oauth/google";
import TermsConditions from './pages/Terms&Conditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Search from './pages/Search';
import AboutUs from './pages/AboutUs';
import CoverLetter from './pages/CoverLetter';
import AppliedSuccessfully from './pages/AppliedSuccessfully';
import PersonalInfo from './pages/PersonalInfo';
import AppliedJobs from './pages/AppliedJobs';
import Pricing from './pages/Pricing';
import ProviderRegister from './pages/ProviderRegister';
import Checkout from './pages/CheckOut';
import ResumeForm from './components/ResumeForm';
import TopBar from './components/TopBar';
import PlanSuccessful from './pages/PlanSuccessful';
import VerifySuccessful from './pages/VerifySuccessful';
import DeleteAccount from './pages/DeleteAccount';
import ProtectedRoute from "./components/ProtectedRoute";
function App() {

  const location = useLocation();

  return (
    <>
      <GoogleOAuthProvider clientId="404623696003-doe1cpjrlljj8om770ha3ri9s9vatoc8.apps.googleusercontent.com">
        {location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/providerRegister' || location.pathname === '/deleteAccount' || location.pathname === '/plansuccessful'  || location.pathname === '/verifySuccess' ? (
          ''
        ) :
          <TopBar />
        }

        <Routes>
          <Route path='/' element={<Page />} />
          {/* <Route path='/about' element={<About />} /> */}
          <Route
        path="/searchpage"
        element={
          <ProtectedRoute>
            <SearchPage />
          </ProtectedRoute>
        }
      />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/verificationcode' element={<VerificationCode />} />
          <Route path='/verify' element={<Verify />} />
          <Route path='/changepassword' element={<ChangePassword />} />
          <Route path='/provider' element={<Provider />} />
          <Route path='/seeker' element={<Seeker />} />
          
          <Route
        path="/category"
        element={
          <ProtectedRoute>
            <Categories />
          </ProtectedRoute>
        }
      />
          {/* <Route path='/companies' element={<Companies />} /> */}
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/postjob' element={<PostJob />} />
          <Route path='/jobDetail' element={<JobDetail />} />
          <Route path='/RecommendedJobs' element={<RecommendedJobs />} />
          <Route path='/crmsinglepage' element={<CrmSinglePage />} />
          <Route path='/test' element={<Test />} />
          <Route path='/profileInfo' element={<ProfileInfo />} />
          <Route path='/seekerInfo' element={<SeekerInfo />} />
          <Route path='/applyForm' element={<ApplyForm />} />
          <Route path='/applysuccessfully' element={<AppliedSuccessfully />} />
          
          <Route
        path="/jobs"
        element={
          <ProtectedRoute>
            <Jobs />
          </ProtectedRoute>
        }
      />
          <Route path='/resume' element={<Resume />} />
          <Route path='/coverletter' element={<CoverLetter />} />
          <Route path='/saved' element={<SavedJobs />} />
          
          <Route
        path="/featured"
        element={
          <ProtectedRoute>
            <FeaturedJobs />
          </ProtectedRoute>
        }
      />
          <Route path='/termsconditions' element={<TermsConditions />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='/search' element={<Search />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/personalinfo' element={<PersonalInfo />} />
          <Route path='/applied' element={<AppliedJobs />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/providerRegister' element={<ProviderRegister />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='/resumeForm' element={<ResumeForm />} />
          <Route path='/plansuccessful' element={<PlanSuccessful />} />
          <Route path='/verifySuccess' element={<VerifySuccessful />} />
          <Route path='/deleteAccount' element={<DeleteAccount />} />

        </Routes>

      </GoogleOAuthProvider>
    </>
  );
}

export default App;
