import React, { useState } from 'react'
import { AiFillEye, AiFillEyeInvisible, AiOutlineMail } from 'react-icons/ai'
import { RiLockPasswordLine } from 'react-icons/ri'
import { BsFillPeopleFill } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import { register } from '../API'
import { useDispatch } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify'
import RegistrationModal from '../components/RegistrationModal'


const Register = () => {
    const [data, setData] = useState({ name: '', username: '', email: '', password: '', confirmpassword: '' })

    const navigate = useNavigate()

    const handleChange = (e) => {
        setData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const RegisterUser = (e) => {
        e.preventDefault();

        if (data.name.length >= 2) {
            console.log(data.name);
            if (data.username.length >= 3) {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                    toast.error('Please Enter a Valid Email Address', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    // toast.show({ type: 'error', position: 'top', text1: 'Please Enter a Valid Email Address' })
                } else {
                    if (data.password === data.confirmpassword) {
                        if (data.password.length >= 9) {
                            register(data.name, data.username, data.email, '', '', '', '', data.password, 'NORMAL').then(res => {
                                const { data: { responseCode } } = res;
                                console.log('res', res)
                                if (responseCode === 200) {
                                    toast.success('User register successfuly', {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                
                                    setTimeout(() => {
                                        navigate('/login')
                                    }, 1000);
                                } 
                                else if (responseCode === 205) {
                                    toast.error('Email already exist', {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                }
                                else if (responseCode === 206) {
                                    toast.error('UserName already exist', {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                }

                            });

                        } else {
                            toast.error('Please Enter a Strong Password', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    } else {
                        toast.error('Password Not Match', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                    }
                }
            } else {
                toast.error('Please Enter a Valid UserName', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else {
            toast.error('Please Enter a Valid Name', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }
    }

    const [showPassword, setShowPassword] = useState(false)

    const handlePassword = () => {
        setShowPassword(!showPassword)
        typeFunction()
    }

    const typeFunction = () => {
        let x = document.getElementById("Input")
        if (x.type === "password") {
            x.type = "text"
        } else {
            x.type = "password"
        }
    }

    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const handleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
        ConfirmtypeFunction()
    }

    const ConfirmtypeFunction = () => {
        let x = document.getElementById("InputConfirm")
        if (x.type === "password") {
            x.type = "text"
        } else {
            x.type = "password"
        }
    }
    const [show, setShow] = useState(false)

    const handleVisible = () => {
        setShow(!show)
    }


    return (
        <div>
            <RegistrationModal visible={show} toggleVisible={handleVisible} />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div>
                <div className='w-[100%] h-[24rem] bg-blue-600'>
                </div>
                <div className=' bg-gray-400 '>
                    <form onSubmit={RegisterUser} className="flex bg-white py-10 rounded-xl w-[60%] relative top-[-250px] m-auto ">
                        <div className="w-[50%] mt-[10%] max-md:hidden">
                            <img src="./assets/panelLogin.png" alt="" className="w-160 " />
                        </div>
                        <center className="w-[40%] max-md:w-[90%] max-md:m-auto">
                            <div className=' p-[10px]'><br />
                                <h1 className='text-[22px]'>Welcome to Jobs.com.au!</h1><br />
                                <h1 className='text-[13px] text-gray-600 font-[600]'>Join us today and unlock a world of
                                    possibilities.</h1><br />
                                <input onChange={handleChange} type="text" name='name' placeholder='Name'
                                    className='shadow-lg rounded-[12px] text-[.9rem] text-gray-700 font-[500] w-[100%] max-md:w-[100%] pl-[50px] border-2 pt-[7px] pb-[7px]' /><br />
                                <div className="relative top-[-1.9rem] left-[-44%] w-[10%]"><BsFillPeopleFill />
                                </div>
                                <input onChange={handleChange} type="text" name='username' placeholder='User Name'
                                    className='shadow-lg rounded-[12px] text-[.9rem] text-gray-700 font-[500] w-[100%] max-md:w-[100%] pl-[50px] border-2 pt-[7px] pb-[7px]' /><br />
                                <div className="relative top-[-1.9rem] left-[-44%] w-[10%]"><BsFillPeopleFill />
                                </div>
                                <input onChange={handleChange} type="email" name="email" placeholder='Email'
                                    className='shadow-lg rounded-[12px] text-[.9rem] text-gray-700 font-[500] w-[100%] max-md:w-[100%] pl-[50px] border-2 pt-[7px] pb-[7px]' /><br />
                                <div className="relative top-[-1.9rem] left-[-44%] w-[10%]"><AiOutlineMail />
                                </div>
                                <input onChange={handleChange} type="password" name="password" id='Input' placeholder='Password'
                                    className='shadow-lg rounded-[12px] text-[.9rem] text-gray-700 font-[500] w-[100%] max-md:w-[100%] pl-[50px] border-2 pt-[7px] pb-[7px]' /><br />
                                <div className="relative top-[-1.9rem] left-[-44%] w-[10%]"><RiLockPasswordLine />
                                </div>
                                {showPassword ?
                                    <div className="relative top-[-2.9rem] left-[44%] w-[10%] text-[1.4rem]" onClick={() => handlePassword()}> <AiFillEye />
                                    </div>
                                    :
                                    <div className="relative top-[-2.9rem] left-[44%] w-[10%] text-[1.4rem]" onClick={() => handlePassword()}> <AiFillEyeInvisible />
                                    </div>
                                }
                                <div className='mt-[-1.4rem]'>
                                    <input onChange={handleChange} type="password" name="confirmpassword" id='InputConfirm' placeholder='Confirm Password'
                                        className='shadow-lg rounded-[12px] text-[.9rem] text-gray-700 font-[500] w-[100%] max-md:w-[100%] pl-[50px] border-2 pt-[7px] pb-[7px]' /><br />
                                    <div className="relative top-[-1.9rem] left-[-44%] w-[10%]"><RiLockPasswordLine />
                                    </div>
                                    {showConfirmPassword ?
                                        <div className="relative top-[-2.9rem] left-[44%] w-[10%] text-[1.4rem]" onClick={() => handleConfirmPassword()}> <AiFillEye />
                                        </div>
                                        :
                                        <div className="relative top-[-2.9rem] left-[44%] w-[10%] text-[1.4rem]" onClick={() => handleConfirmPassword()}> <AiFillEyeInvisible />
                                        </div>
                                    }
                                </div>

                                <input type='submit' onClick={RegisterUser} value='Sign Up'
                                    className='bg-blue-600 text-white cursor-pointer font-[600] px-10 py-[5px] w-[100%] rounded-full mt-6' />

                                <div className="flex gap-2 mt-4 justify-center">
                                </div>
                                <span> <a className='text-[17px]'>Already have an account? </a> <Link to='/Login'
                                    className='text-blue-500'>Sign In</Link><br /> </span><br />
                            </div>
                        </center>
                    </form>
                </div>
            </div>
        </div>


    )
}

export default Register
