import React from 'react'
import TopBar from '../components/TopBar'

const PrivacyPolicy = () => {
    const mailtoHref = "mailto:info@jobss.com.au?subject=SendMail&body=Description";

    return (
        <div>
            
            <div className="bg-gray-100 min-h-screen py-12">
                <div className="container mx-auto px-4">
                    <div className="bg-white rounded-lg shadow-md p-6">
                        <h1 className="text-3xl text-center font-semibold mb-6">Privacy Policy</h1>

                        <section className="mb-6">
                            <h2 className="text-xl font-semibold mb-2"> Information We Collect</h2>
                            <p>
                                <strong>Personal Information:</strong> We may collect information such as your name, email address, phone number, and resume/CV when you create an account or apply for jobs.
                            </p>
                            <p>
                                <strong>Usage Data:</strong> We collect data on how you interact with our app, including search history, job preferences, and device information.
                            </p>
                            <p>
                                <strong>Location Information:</strong> With your consent, we may collect your device's location to provide localized job recommendations.
                            </p>
                        </section>

                        <section className="mb-6">
                            <h2 className="text-xl font-semibold mb-2"> How We Use Your Information</h2>
                            <p>
                                <strong>Matching:</strong> We use your information to match you with job opportunities and employers, improving your job search experience.
                            </p>
                            <p>
                                <strong>Communication:</strong> We may contact you about job matches, updates, or promotions related to our services.
                            </p>
                            <p>
                                <strong>Analytics:</strong> We use data analytics to enhance our app's functionality and user experience.
                            </p>
                        </section>

                        <section className="mb-6">
                            <h2 className="text-xl font-semibold mb-2"> Information Sharing</h2>
                            <p>
                                <strong>Employers:</strong> Your profile and job application information may be shared with potential employers when you apply for a job.
                            </p>
                            <p>
                                <strong>Service Providers:</strong> We may share data with service providers to enhance our app's features.
                            </p>
                        </section>

                        <section className="mb-6">
                            <h2 className="text-xl font-semibold mb-2"> Security</h2>
                            <p>
                                We take measures to protect your information but cannot guarantee absolute security. Please use strong passwords and keep your login details secure.
                            </p>
                        </section>

                        <section className="mb-6">
                            <h2 className="text-xl font-semibold mb-2"> Your Choices</h2>
                            <p>
                                You can review and update your information in your account settings.
                            </p>
                            <p>
                                You have the right to access, correct, or delete your personal data. Contact us to make these requests.
                            </p>
                        </section>

                        <section className="mb-6">
                            <h2 className="text-xl font-semibold mb-2"> Third-Party Links</h2>
                            <p>
                                Our app may contain links to third-party websites or services. We are not responsible for their privacy practices.
                            </p>
                        </section>

                        <section className="mb-6">
                            <h2 className="text-xl font-semibold mb-2"> Changes to Privacy Policy</h2>
                            <p>
                                We may update this Privacy Policy as our app evolves. You will be notified of significant changes.
                            </p>
                        </section>
                        <section className="mb-6">
                            <h2 className="text-xl font-semibold mb-2"> Contact Us: </h2>
                            <p>
                                If you have questions or concerns about your privacy or this policy, please contact us at <span className='text-blue-600 cursor-pointer '>1300 562 772 </span> / <span className='text-blue-600 cursor-pointer '>0403 786 786  </span> email us at: <a href={mailtoHref} className='text-blue-600 cursor-pointer'> info@jobss.com.au </a>
                                By using the jobss.com.au mobile app, you agree to this Privacy Policy. Please read it carefully and check for updates. Your privacy and data security are important to us.
                            </p>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy
