
import { CREATE_CV_CAREER, CREATE_CV_COURSE, CREATE_CV_EDUCATION, CREATE_CV_INTEREST, CREATE_CV_LANGUAGE, CREATE_CV_SKILL, CV_CHECK, ERROR, GET_COVER_BY_USER, GET_CV_USER, LOADING, SUCCESS } from "../../Utils/Constant";



const cv = (state = { isLoading: true, success: false, error: false, cvs: [] }, action) => {
    switch (action.type) {
        case LOADING:
            return { ...state, isLoading: true }
        case SUCCESS:
            return { ...state, isLoading: false, success: true, error: false }
        case ERROR:
            return { ...state, isLoading: false, success: false, error: true }
        case GET_CV_USER:
            return { ...state, cvs: action.payload }
        case GET_COVER_BY_USER:
            return { ...state, coverLetter: action.payload.coverLetter }
        case CREATE_CV_EDUCATION:
            return { ...state, cvs: { ...state.cvs, educations: [...state.cvs?.educations, action.payload.data], }, };
        case CREATE_CV_CAREER:
            return { ...state, cvs: { ...state.cvs, careers: [...state.cvs?.careers, action.payload.data], }, };
        case CREATE_CV_COURSE:
            return { ...state, cvs: { ...state.cvs, courses: [...state.cvs?.courses, action.payload.data], }, };

        case CREATE_CV_INTEREST:
            console.log('action.payload interest', action.payload?.data);
            console.log('full cv ', state.cvs);
            return { ...state, cvs: { ...state.cvs, interests: [...state.cvs?.interests, action.payload.data], }, };

        case CREATE_CV_LANGUAGE:
            console.log('action.payload language', action)
            return { ...state, cvs: { ...state.cvs, languages: [...state.cvs?.languages, action.payload.data], }, };
        case CREATE_CV_SKILL:
            return { ...state, cvs: { ...state.cvs, skills: [...state.cvs?.skills, action.payload.data], }, };
        case CV_CHECK:
            return { ...state, check: action.payload.check }

        default:
            return state;
    }
}

export default cv;