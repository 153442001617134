import { Accordion } from 'flowbite-react'
import React, { useEffect, useMemo, useState } from 'react'
import Footer from '../components/Footer'
import { AiOutlineSearch } from 'react-icons/ai'
import TopBar from '../components/TopBar'
import ProviderModal from '../components/ProviderModal'
import { useLocation } from 'react-router-dom'
import LoginModal from '../components/LoginModal'
import { Link } from 'react-router-dom'
import { CiLocationOn } from 'react-icons/ci'
import { useDispatch, useSelector } from 'react-redux'
import { getJobSearch } from '../API/actions/jobsAction'
import moment from 'moment'
import { AllCategories } from '../API/actions/categoriesAction'
import { AllCities } from '../API/actions/citiesAction'
import { AllCompanies } from '../API/actions/companiesAction'
import CategoryModal from '../components/CategoryModal'
import CityModal from '../components/CityModal'
import LoginRequireModal from '../components/LoginRequireModal'
import CompanyModal from '../components/CompanyModal'
import { ClipLoader } from 'react-spinners'
import { ThemeProvider } from 'styled-components'
import { Pagination, Stack, createTheme } from '@mui/material'

const SearchPage = () => {
  const [visible, setVisible] = useState(false)
  const [jobId, setJobId] = useState('')
  const params = useLocation();
  const Search = params?.state?.Params

  const [searchData, setSearchData] = useState(Search ? Search : '')

  const [categoryOpen, setCategoryOpen] = useState()
  const [cityOpen, setCityOpen] = useState()
  const [companyOpen, setCompanyOpen] = useState()

  const [jobData, setJobData] = useState()
  const [selectedCategory, setSelectedCategory] = useState()

  const [nodata, setNoData] = useState(false)
  const [viewMoreCategories, setViewMoreCategories] = useState(false)
  const [viewMoreCities, setviewMoreCities] = useState(false)

  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedCities, setSelectedCities] = useState([])
  const [selectedCompanies, setSelectedCompanies] = useState([])
  const [selectedType, setSelectedType] = useState([])

  const [apiData, setApiData] = useState({
    search: '',
    country: '',
    category: '',
    city: '',
    company: '',
    salaryStart: '',
    salaryEnd: '',
    type: '',
    isCountry: 'false',
    isCategory: 'false',
    isCity: 'false',
    isCompany: 'false',
    isSalary: 'false',
    isType: 'false',
  })

  const removeSelectedCategory = (id) => {
    setSelectedCategories(selectedCategories.filter((item) => item !== id));
  }

  const removeSelectedCity = (id) => {
    setSelectedCities(selectedCities.filter((item) => item !== id))
  }

  const removeSelectedCompany = (id) => {
    setSelectedCompanies(selectedCompanies.filter((item) => item !== id))
  }

  const removeSelectedType = (index) => {
    setSelectedType(selectedType?.filter((item) => item !== index))
  }
  useEffect(() => {
    var data = JSON.stringify(selectedCategories).slice(1, -1)
    console.log(data)
    if (data !== '') {
      setApiData({ ...apiData, isCategory: 'true', category: data })
    } else {
      setApiData({ ...apiData, isCategory: 'false', category: data })
    }
  }, [selectedCategories]);

  useEffect(() => {
    console.log(selectedCategory)
  }, [selectedCategory]);

  useEffect(() => {
    var data = JSON.stringify(selectedCities).slice(1, -1)
    // console.log("cities", data)
    if (data !== '') {
      setApiData({ ...apiData, isCity: 'true', city: data })
    } else {
      setApiData({ ...apiData, isCity: 'false', city: data })
    }
  }, [selectedCities]);

  useEffect(() => {
    var data = JSON.stringify(selectedCompanies).slice(1, -1)
    // console.log("company", selectedCompanies)
    if (data !== '') {
      setApiData({ ...apiData, isCompany: 'true', company: data })
    } else {
      setApiData({ ...apiData, isCompany: 'false', company: data })
    }
  }, [selectedCompanies]);

  useEffect(() => {
    var data = JSON.stringify(selectedType).slice(1, -1)
    // console.log("type", selectedType)
    if (data !== '') {
      setApiData({ ...apiData, isType: 'true', type: data })
    } else {
      setApiData({ ...apiData, isType: 'false', type: data })
    }
  }, [selectedType]);

  const type = [
    { id: 0, name: 'Part-Time' },
    { id: 1, name: 'Full-Time' },
    { id: 2, name: 'Remote' }
  ]

  const toggleVisibility = (id) => {
    setVisible(!visible)
    setJobId(id)
  }

  const CategoryVisibility = () => {
    setCategoryOpen(!categoryOpen)
  }

  const CityVisibility = () => {
    setCityOpen(!cityOpen)
  }

  const CompanyVisibility = () => {
    setCompanyOpen(!cityOpen)
  }

  const [loginVisible, setLoginVisible] = useState(false)
  const toggleLoginVisibility = () => setLoginVisible(!loginVisible)

  const dispatch = useDispatch()

  const jobs = useSelector(state => state.job.jobs)
  const loading = useSelector(state => state.loading.searchJobLoading)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    console.log('jobs', jobs)
  }, [jobs])

  useEffect(() => {
    setIsLoading(true)
    dispatch(getJobSearch(apiData))
    setIsLoading(false)
  }, [dispatch])

  const categories = useSelector(state => state.category.categories)
  const cities = useSelector(state => state.city.cities)

  useEffect(() => {
    dispatch(AllCities())
  }, [dispatch])

  //fetching companies
  const companies = useSelector(state => state.company.companies)

  useEffect(() => {
    dispatch(AllCompanies())
  }, [dispatch])

  useEffect(() => {
    dispatch(AllCategories())
  }, [dispatch])

  // No data
  useEffect(() => {
    if (jobs?.length === 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
  })
  //fetching cities

  //pagination=============================

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const [filteredData, setFilteredData] = useState([]);
  const [paginatedData, setPaginatedData] = useState(jobs);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setPaginatedData(filteredData?.slice(startIndex, endIndex));
  }, [currentPage, filteredData]);


  useEffect(() => {
    if (jobs) {
      const filteredResult = jobs.filter((item) =>
        item.title && item.title?.toLowerCase().includes(searchData?.toLowerCase())
      );
      setFilteredData(filteredResult);
      setCurrentPage(1);
    }
  }, [searchData, jobs]);

  const theme = createTheme({ palette: { primary: { main: '#E83B46', contrastText: '#EEE' } } })


  function getFilteredList() {
    if (!selectedCategory) {
      return jobData;
    }
    return jobData.filter((item) => item.category === selectedCategory);
  }

  var filteredList = useMemo(getFilteredList, [selectedCategory, jobData]);

  const [select, setSelect] = useState(0)
  const [requireVisible, setRequireVisible] = useState(false)
  const toggleVisible = () => setRequireVisible(!requireVisible)

  const toggleChangeVisible = () => {
    setVisible(false)
    setRequireVisible(true)
  }


  const categoriesView = viewMoreCategories ? categories.length : 5;
  const citiesView = viewMoreCities ? cities.length : 5;






  return (
    <div>

      <ProviderModal visible={visible} toggleVisibility={toggleVisibility} ID={jobId}
        toggleQrVisible={() => toggleChangeVisible()} />
      <LoginRequireModal visible={requireVisible} toggleVisible={toggleVisible} />
      <LoginModal loginVisible={loginVisible} toggleLoginVisibility={toggleLoginVisibility} />

      <CategoryModal open={categoryOpen} setOpen={setCategoryOpen} selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories} selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory} />
      <CityModal open={cityOpen} setOpen={setCityOpen} selectedCities={selectedCities}
        setSelectedCities={setSelectedCities} />
      <CompanyModal open={companyOpen} setOpen={setCompanyOpen} />

      {isLoading ?
        <div className='flex justify-center mt-[20%]'>
          <ClipLoader size='5rem' />
        </div>
        :
        <>
          {loading ?
            <div className='flex justify-center mt-[20%]'>
              <ClipLoader size='5rem' />
            </div>
            :
            <>
              <div className='bg-gray-200 p-4'>
                <div className='flex max-md:flex-col gap-6 w-[70%] max-md:w-[98%] m-auto'>
                  <div className='bg-white ml-4 rounded-xl'>
                    <h1 className='bg-yellow-400 py-3 text-center font-[600] mb-5 '>Advance
                      Search</h1>

                    <div className="relative w-[95%] mx-auto mb-4  ">
                      <input type="text"
                        className='pl-[50px] w-[98%] rounded-xl border-gray-400  focus:ring-yellow-200 ring-0'
                        placeholder='Search here' value={searchData}
                        onChange={(e) => setSearchData(e.target.value)} />
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none ">
                        <AiOutlineSearch className="w-6 h-6 text-gray-500 dark:text-gray-400 " />
                      </div>
                    </div>


                    <div className='w-full flex justify-start '>
                      <button onClick={(e) => {
                        e.preventDefault()
                        dispatch(getJobSearch(apiData))
                      }}
                        className=' text-white w-[90%] ml-auto mr-5 rounded-md  bg-blue-600 px-5 py-3 mb-4 mt-2 hover:bg-blue-700'>
                        <p className='text-center font-[600]'>Apply filter </p>
                      </button>
                    </div>

                    <Accordion data-accordion="open"
                      className='w-[20rem] max-md:w-[90%] max-md:mx-auto'>
                      <Accordion.Panel>
                        <Accordion.Title
                          className='bg-white text-black hover:bg-blue-970 h-[2rem]'>
                          Category
                        </Accordion.Title>
                        <Accordion.Content>
                          {categories?.slice(0, categoriesView).map((value) => (
                            <>
                              <div className='flex '>
                                <input onChange={e => {
                                  if (e.target.checked) {
                                    setSelectedCategories(state => [...state, value.id])
                                  } else {
                                    removeSelectedCategory(value.id)
                                  }
                                }} checked={selectedCategories.includes(value.id)} type="checkbox" name={value.name} id={value.id}
                                  className='rounded-xl' />
                                <p className='text-gray-700 font-[500] ml-4 text-[.9rem] '
                                  onChange={(e) => setSelectedCategory(e.target.value)}
                                // onClick={() => filterItems(value.id)}
                                >{value.name}</p>
                                <p className='ml-auto mr-2 text-[.9rem] text-gray-500 font-[500]'>({value.jobs})</p>
                              </div>
                            </>
                          ))}
                          <div onClick={() => setViewMoreCategories(!viewMoreCategories)}
                            className='mt-5 text-gray-800 underline'>
                            <button
                              className='text-blue-600 hover:border-b-2'>{viewMoreCategories ? "View Less" : "View More"}</button>
                          </div>
                        </Accordion.Content>
                      </Accordion.Panel>
                    </Accordion>

                    <Accordion>
                      <Accordion.Panel>
                        <Accordion.Title
                          className='bg-white text-black hover:bg-gray-970  h-[2rem]'>
                          City
                        </Accordion.Title>
                        <Accordion.Content>
                          {cities?.slice(0, citiesView).map((value) => (
                            <div className='flex '>
                              <input onChange={e => {
                                if (e.target.checked) {
                                  setSelectedCities(state => [...state, value.id])
                                } else {
                                  removeSelectedCity(value.id)
                                }
                              }} checked={selectedCities.includes(value.id)} type="checkbox" name="" id="" className='rounded-xl' />
                              <p className='text-gray-700 font-[500] ml-4 text-[.9rem] '>{value.name}</p>
                              <p className='ml-auto mr-2 text-[.9rem] text-gray-500 font-[500]'>({value.jobs})</p>
                            </div>
                          ))}
                          <div onClick={() => setviewMoreCities(!viewMoreCities)}
                            className='mt-3 text-gray-800 underline'>
                            <button
                              className='text-blue-600 hover:border-b-2'>{viewMoreCities ? "View Less" : "View More"}</button>
                          </div>
                        </Accordion.Content>
                      </Accordion.Panel>
                    </Accordion>

                    <Accordion>
                      <Accordion.Panel>
                        <Accordion.Title
                          className='bg-white text-black hover:bg-blue-970 h-[2rem]'>
                          Job Type
                        </Accordion.Title>
                        <Accordion.Content>
                          <div className='flex gap-2 flex-col'>
                            {type?.map((value, index) => (
                              <div className='flex'>
                                <input onChange={e => {
                                  if (e.target.checked) {
                                    setSelectedType(state => [...state, value.name])
                                  } else {
                                    removeSelectedType(value.name)
                                  }
                                }} checked={selectedType.includes(value.name)} type="checkbox" name="" id=""
                                  className='rounded-xl' />
                                <p className='text-gray-700 font-[500] ml-4 text-[.9rem] '>{value.name}</p>
                              </div>
                            ))}
                          </div>

                        </Accordion.Content>
                      </Accordion.Panel>
                    </Accordion>
                  </div>
                  {nodata ?
                    <div className='mt-10 ml-10'>
                      <div className=" pt-[10%]">
                        <h1 className=" text-[2rem] text-gray-500 mt-[-4rem] pt-10">No Jobs
                          Found</h1>
                      </div>
                    </div>
                    :
                    <div
                      className=' mr-4 w-[80%]  max-md:w-[100%] max-md:ml-auto max-md:mr-0 rounded-md border-2 '>
                      {paginatedData?.length > 0 ?
                        <div>


                          <div className='grid grid-cols-1 max-md:grid-cols-1 gap-2'>
                            {paginatedData?.map((value) => (
                              <div
                                className='bg-white p-4  rounded-md border-[1px] border-solid border-gray-400  cursor-pointer'
                                onClick={() => toggleVisibility(value.id)}>
                                <div className='flex'>
                                  <div className='w-full'>
                                    <div className='flex'>
                                      <h1 className='text-gray-800 text-[1.4rem] font-[600] w-[85%]'>{value.title}</h1>
                                    </div>
                                    <h1 className='text-gray-600 text-[1.1rem] mt-2 font-[500]'>{value.company_name}</h1>
                                    <h1 className='text-gray-600 text-[0.9rem] font-[400]'>
                                      <CiLocationOn
                                        className="w-4 h-4 inline mr-2 text-gray-500 dark:text-gray-400 max-md:mt-0" />
                                      {value.address}</h1>

                                    <div className='flex mt-2'>
                                      <h1 className='h-full text-gray-700 text-[0.8rem] font-[500] bg-gray-200 text-center py-1 px-4 rounded-md '>Salary {value.salary} </h1>
                                      <h1 className='ml-3 h-full text-gray-700 text-[0.8rem] font-[500] bg-gray-200 text-center py-1 px-4 rounded-md '>{value.type}</h1>
                                    </div>
                                    <div className='flex items-center mt-5'>
                                      <h1 className=' ml-4 text-gray-600 text-[.8rem] font-[400] '>{moment(value?.date).format('YYYY-MM-DD')}</h1>
                                      <h1 className='bg-blue-500 text-white ml-auto font-[500] px-6 rounded-md py-[3px] hover:bg-blue-600 cursor-pointer'
                                        onClick={() => toggleVisibility(value.id)}>View
                                        Details</h1>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            ))}
                          </div>

                          <div className='mt-10'>
                            <ThemeProvider theme={theme}>
                              <Stack direction="row" justifyContent="center" marginTop={2}>
                                <Pagination
                                  count={Math.ceil(filteredData.length / itemsPerPage)}
                                  page={currentPage}
                                  onChange={handlePageChange}
                                  color="primary"
                                />
                              </Stack>
                            </ThemeProvider>
                          </div>
                        </div>
                        :
                        <p className='text-[1.4rem] text-gray-500 font-bold '>No Jobs </p>
                      }
                    </div>}
                </div>
              </div>
              <Footer />
            </>}
        </>}
    </div>

  )


}

export default SearchPage
