import { Modal } from 'flowbite-react';
import React from 'react'
import { MdOutgoingMail } from "react-icons/md";


const MailModal = ({ toggleMail, visible, email, toggleMailVisible }) => {


    
    return (
        <Modal show={visible} size={'xl'} onClose={() => toggleMail()}>
            <Modal.Body className='w-[100%] px-10 max-md:w-[100%] bg-white  max-md:ml-0 rounded-lg min-h-[17rem]' >
                <div className='py-5 flex'>
                    <button onClick={() => {
                        toggleMailVisible();
                    }} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center :hover:bg-gray-600 :hover: " data-modal-hide="popup-modal">
                        <svg className="w-3 h-3" aria-hidden="true" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <center className='w-[80%] m-auto align-middle mt-auto'>
                    <MdOutgoingMail size={60} color='#3671E6' />
                    <p className='text-gray-600 font-[500] text-[1.4rem] mt-2' > Check Your mail</p>
                    <p className='text-gray-600 font-[500] text-[.9rem] mt-2' >We've sent instruction to verify profile to <span className='text-[#3671E6] cursor-pointer'>{email}</span></p>
                </center>

            </Modal.Body>
        </Modal>
    )
}

export default MailModal
