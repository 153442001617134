import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isProfileCompleteAndVerified } from '../Utils/profileValidation';

const ProtectedRoute = ({ children }) => {
  const seeker = useSelector((state) => state.seeker.seeker);

  const isAllowed = isProfileCompleteAndVerified(seeker);

  if (!isAllowed) {
    // Show toast message
    toast.error("Please complete your profile first!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    return <Navigate to="/personalinfo" />;
  }

  return children;
};

export default ProtectedRoute;
