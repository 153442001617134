import TopBar from '../components/TopBar'
import Footer from '../components/Footer'
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {AllBookmarks} from "../API/actions/bookmarksAction";
import ProviderModal from '../components/ProviderModal'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ClipLoader} from 'react-spinners'
import { SESSION_ID } from '../Utils/Constant';

const Jobs = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const bookmarks = useSelector(state => state.bookmark.bookmarks)
    const loading = useSelector(state => state.loading.bookmarksLoading)

    useEffect(() => {
        dispatch(AllBookmarks(sessionStorage.getItem(SESSION_ID)))
    }, [dispatch]);

    useEffect(() => {
        console.log(bookmarks)
    }, [bookmarks])


    const [jobId, setJobId] = useState('')
    const [visible, setVisible] = useState(false)

    const toggleVisibility = (id) => {
        setVisible(!visible)
        setJobId(id)
    }

    return (
        <div>
            {loading ?
                <div className='flex justify-center mt-[20%]'>
                    <ClipLoader size='5rem'/>
                </div>
                :
                <>
                    <TopBar/>
                    <ProviderModal visible={visible} toggleVisibility={toggleVisibility} ID={jobId}
                                   navigate={navigate}/>

                    <div className=' py-10 h-screen'>
                        <center>
                            <h1 className='uppercase text-[40px] font-[400]  max-md:text-[1.5rem]'>Saved Jobs</h1>
                            <h1 className=' text-[#999] text-[16px] font-[400]  max-md:w-[80%]  '>The Most Comprehensive
                                Search Engine For Jobs.</h1>
                            <hr className='w-[3rem] h-2 bg-green-400 rounded-xl mt-4'/>
                        </center>
                        <div className='w-[70%] m-auto'>
                            <div className='grid grid-cols-2 max-md:grid-cols-1 gap-5 m-auto mt-5 '>
                                {bookmarks?.map((value) => (
                                    <div onClick={() => toggleVisibility(value.id)}
                                         className='border-2 rounded-xl bg-white hover:shadow-xl hover:shadow-gray-400 cursor-pointer p-6 transition duration-300'>
                                        <h1 className='text-[1.6rem] font-[500] '>{value.company_name}</h1>
                                        <h1 className='font-[600] w-[80%] mt-2'>{value.title}</h1>
                                        <div className=' border-2 w-[20%] mt-2 rounded-md'>
                                            <p className=' text-center '>{value.type}</p>
                                        </div>
                                        <div className='flex mt-2'>
                                            <p className=' font-[700]'>{value.category_name}</p>
                                            <p className='ml-auto  font-[700]'>{value.qualification}</p>
                                        </div>
                                        <h1 className='text-[1.1rem] max-md:text-[.9rem] mt-2 text-center text-blue-600 font-[600]'>{value.city_name}, {value.country_name}</h1>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </>}
        </div>
    )
}

export default Jobs
