import React from 'react'
import QuickSearch from '../components/QuickSearch'
import GetApp from '../components/GetApp'
import Footer from '../components/Footer'
import TopBar from '../components/TopBar'
import Search from '../components/Search'

const recent = [
    {
        status: 'New',
        time: 'Today',
        role: 'Occupational therapist (G2) - School Readiness',
        company: 'Facebook',
        about: 'IT & Communications',
        requirement: 'Bachelor',
        city: 'Sydney, Australia',
    },
    {
        status: 'New',
        time: 'Today',
        role: 'Occupational therapist (G2) - School Readiness',
        company: 'Facebook',
        about: 'IT & Communications',
        requirement: 'Bachelor',
        city: 'Sydney, Australia',
    },
    {
        status: 'New',
        time: 'Today',
        role: 'Occupational therapist (G2) - School Readiness',
        company: 'Facebook',
        about: 'IT & Communications',
        requirement: 'Bachelor',
        city: 'abc, Australia',
    },

]
const Provider = () => {
    return (
        <div>
            <TopBar/>
            <Search/>
            <div className='max-md:hidden'>
                <QuickSearch/>
            </div>
            <h1 className='uppercase text-[1rem] text-center p-2 font-[800] text-white bg-[#F0A51E]'>Job provider</h1>

            <GetApp/>
            <center>
                <div className='w-[80%] '>
                    <div className='grid grid-cols-2 max-md:grid-cols-1 gap-10 mt-8 mb-8'>
                        <div className='bg-yellow-400 rounded-xl py-20 w-[100%]'>
                            <h1 className='text-[1.4rem] text-white font-bold text-center'>Applied</h1>
                            <h1 className='text-[1.4rem] text-white font-bold text-center'>Users</h1>
                        </div>
                        <div className='bg-yellow-400 rounded-xl py-20 w-[100%]'>
                            <h1 className='text-[1.4rem] text-white font-bold text-center'>Sent </h1>
                            <h1 className='text-[1.4rem] text-white font-bold text-center'>Offers</h1>
                        </div>
                    </div>
                </div>
            </center>
            <div className='bg-gray-300 py-10'>
                <div className='bg-[#96c7dc] w-[50%] max-md:w-[90%] rounded-xl m-auto pb-6'>
                    <h1 className='text-center font-[700] text-[1.6rem] text-blue-800 pt-6'>Post job</h1>
                    <center>
                        <p className='font-[600] font-[1.1rem] mt-5'>You can see reports about your </p>
                        <p className='font-[600] font-[1.1rem] '>job posts and detailed data on your Portal</p>
                        <h1 className='text-white font-bold py-6 text-[1.5rem]'>Post Jobs using your portal</h1>
                        <h1 className='bg-white w-[70%] py-[5px] rounded-xl text-blue-700'>https://www.example.com</h1>
                        <p className='mt-4 text-gray-600'> Login to your portal using app credentials </p>
                        <p className='text-gray-600'> (email and password) </p>
                    </center>
                </div>
            </div>

            <div className='mt-10  pb-10 pt-10 '>
                <center>
                    <h1 className='uppercase text-[1.9rem] font-[600]'>posted JOBS</h1>
                    <p className='uppercase font-[500] '>The Most Comprehensive Search Engine For Jobs</p>
                </center>

                <div className='w-[90%] m-auto'>
                    <div className='grid grid-cols-3 max-md:grid-cols-1  gap-10 m-auto mt-5'>
                        {recent.map((value) => (
                            <div className='border-2 rounded-xl bg-white pb-2'>
                                <div className='flex mt-5'>
                                    <p className='bg-[#ccf0d0] rounded-md px-2 ml-4 text-[#207a00]'>{value.status}</p>
                                    <p className='ml-auto mr-4 font-[500]'>{value.time}</p>
                                </div>
                                <h1 className='font-[600] ml-4 w-[80%]'>{value.role}</h1>
                                <h1 className='ml-4 font-[500]'>{value.company}</h1>
                                <div className='flex'>
                                    <p className='ml-4 font-[700]'>{value.about}</p>
                                    <p className='ml-auto mr-4 font-[700]'>{value.requirement}</p>
                                </div>
                                <h1 className='text-[1.1rem] mt-2 text-center text-blue-600 font-[600]'>{value.city}</h1>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Footer/>

        </div>
    )
}

export default Provider
