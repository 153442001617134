import { Modal } from 'flowbite-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const RegistrationModal = ({ visible, toggleVisible }) => {

    const navigation = useNavigate();


    return (
        <div>
            <Modal show={visible} size={'2xl'} onClose={() => toggleVisible()} >
                <Modal.Body>
                    <div>
                        <img src="./assets/welcome.png" alt="" />
                    </div>
                    <h1 className='text-center text-[1.5rem] mt-[-2%] '>Welcomd to Jobss.com.au</h1>
                    <h1 className='text-center text-[1rem] mt-4'>Create your
                        professional resume with online resume builder and start applying for best jobs.We will connect with hiring agencies, job banks and recruiters to place your CV</h1>
                    <p className='text-center bg-blue-500 py-2 text-white mt-4 cursor-pointer' onClick={() => navigation('/login')}>Log In Now </p>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default RegistrationModal
