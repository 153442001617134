import { ERROR, GET_SEEKER, LOADING, RECOMMENDED_SEEKER, SUCCESS } from "../../Utils/Constant";



const seeker = (state = { isLoading: true, success: false, error: false, seekers: [] }, action) => {
    switch (action.type) {
        case LOADING:
            return { ...state, isLoading: true }
        case SUCCESS:
            return { ...state, isLoading: false, success: true, error: false }
        case ERROR:
            return { ...state, isLoading: false, success: false, error: true }
        case RECOMMENDED_SEEKER:
            return { ...state, seekers: action.payload }
        case GET_SEEKER:
            return { ...state, seeker: action.payload.data }
        default:
            return state;
    }
}

export default seeker;
