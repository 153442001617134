import { Button } from '@mui/material';
import { Modal } from 'flowbite-react';
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';

const SkillModal = ({ visible, toggleSkillVisibility, add, edit, data }) => {

    const [skill, setSkill] = useState('');

    useEffect(() => {
        if (data !== null) {
            setSkill(data.skill);
        }
    }, [data]);

    const handlePress = () => {
        if (skill?.length > 0) {
            if (data?.status === 0) {
                edit(skill, data.id);
                setSkill('');
                toast.success('Skill Edit Successfuly', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                add(skill);
                setSkill('');
                toast.success('Skill added Successfuly', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }

            toggleSkillVisibility();

            // toggleSkillVisibility();
        } else {
            toast.error('Enter Degree', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const inputStyle = {
        padding: "1.0rem 0.75rem",
        width: "100%",
        fontSize: "0.8rem",
        fontWeight: '600',
        lineHeight: "1.25",
        color: "#343434",
        backgroundColor: "#efefef",
        backgroundImage: "none",
        backgroundClip: "padding-box",
        border: "1px solid #efefef",
        borderRadius: "8px",
        transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)",
    }


    return (
        <div>
            <Modal show={visible} size={'xl'} onClose={() => toggleSkillVisibility()}>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <Modal.Body className='w-[100%] px-10 max-md:w-[100%] bg-white  max-md:ml-0'>
                    <div className='py-5 flex'>
                        <p className='text-center text-[1.5rem] mt-[-10px] font-[700] '>Skill</p>
                        <button onClick={() => {
                            toggleSkillVisibility();
                            setSkill('');
                        }} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center :hover:bg-gray-600 :hover: " data-modal-hide="popup-modal">
                            <svg className="w-3 h-3" aria-hidden="true" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <input
                        type="text"
                        placeholder="Enter Your Skills"
                        className="focus:outline-none rounded-md focus:border-blue-600 border-[0px] shadow-md w-[100%] mt-4 py-2 pl-4"
                        value={skill}
                        onChange={(e) => setSkill(e.target.value)}
                        style={inputStyle}
                    />
                    <center>
                        <Button onClick={handlePress} className="uppercase border-2 rounded-xl !px-10 py-1 !bg-blue-600 !hover:bg-blue-800 !text-gray-100 !font-[600] !mt-10  mr-auto cursor-pointer text-center"
                        > {data?.status === 0 ? 'Update' : 'Add'}</Button>
                    </center>

                    {/* <div className="flex gap-4">

                    <input
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        placeholder="Enter Company"
                        className="focus:outline-none rounded-md focus:border-blue-600 border-[1px] shadow-md w-[100%] mt-4 py-2 pl-4"
                        />

                    <input
                        value={job}
                        onChange={(e) => setJob(e.target.value)}
                        placeholder="Enter Job"
                        className="focus:outline-none rounded-md focus:border-blue-600 border-[1px] shadow-md w-[100%] mt-4 py-2 pl-4"
                    />

                        </div>
                    <input
                        value={timeperiod}
                        onChange={(e) => setTimePeriod(e.target.value)}
                        placeholder="Time Period"
                        className="focus:outline-none rounded-md focus:border-blue-600 border-[1px] shadow-md w-[100%] mt-4 py-2 pl-4"
                    />
                    <input
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Address"
                        className="focus:outline-none rounded-md focus:border-blue-600 border-[1px] shadow-md w-[100%] mt-4 py-2 pl-4"
                    />
              
                    <button onClick={handleAdd} className="uppercase border-2 rounded-xl px-6 py-1 bg-blue-600 hover:bg-blue-800 text-gray-100 font-[500] mt-5 ml-auto mr-auto cursor-pointer">
                        {data?.status === 0 ? 'Update' : 'Add'}
                    </button> */}


                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SkillModal
