import React, { useState } from "react";

const DeleteAccount = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(0); // Start from step 0
  const [isDeleting, setIsDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleNextStep = async () => {
    if (step === 0) {
      setStep(1);
    } else if (step === 1 && email.trim() !== "") {
      try {
        setLoading(true);
        const response = await fetch(`https://jobss.com.au/api/seekers/email`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        });
        const data = await response.json();
        setLoading(false);

        if (response.ok) {
          if (data.responseCode === 200) {
            setStep(2);
            setError("");
          } else {
            setError("Invalid information provided.");
          }
        } else {
          setError("Invalid information provided.");
        }
      } catch (error) {
        console.error("Error:", error);
        setError("An error occurred. Please try again.");
        setLoading(false);
      }
    } else if (step === 2 && password.trim() !== "") {
      await deletingEmail();
    } else {
      setError("Invalid information provided.");
    }
  };

  const deletingEmail = async () => {
    try {
      setLoading(true);
      const response = await fetch(`https://jobss.com.au/api/seekerAuth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      setLoading(false);

      if (response.ok) {
        if (data.responseCode === 200) {
          await deleteAccount();
        } else {
          setError("Invalid information provided.");
        }
      } else {
        setError("Invalid information provided.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred. Please try again.");
      setLoading(false);
    }
  };

  const deleteAccount = async () => {
    try {
      setIsDeleting(true);
      const response = await fetch(`https://jobss.com.au/api/seekers/del/email`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      setIsDeleting(false);

      if (response.ok) {
        if (data.responseCode === 200) {
          alert("Your account has been successfully deleted.");
          // Optionally redirect the user or reset the form
          setStep(0);
          setEmail("");
          setPassword("");
        } else {
          setError(data.message || "Failed to delete account.");
        }
      } else {
        setError(data.message || "Failed to delete account.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred. Please try again.");
      setIsDeleting(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen p-4">
      <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-lg">
        <img
          src="/assets/logo.png"
          alt="Jobss Logo"
          className="w-40 mx-auto mb-8"
        />
        {error && <div className="mb-4 text-red-500">{error}</div>}
        {step === 0 && (
          <div>
            <h2 className="text-2xl font-semibold mb-4 text-center">
              Delete Your Jobss Account
            </h2>
            <p className="mb-6">
              We're sorry to see you go. Deleting your account will remove all your data from our servers. This action is irreversible.
            </p>
            <p className="mb-6">
              To proceed with deleting your account, please click the button below.
            </p>
            <button
              className="w-full bg-red-500 text-white py-3 rounded-lg"
              onClick={handleNextStep}
            >
              Proceed to Account Deletion
            </button>
          </div>
        )}
        {step === 1 && (
          <div>
            <h2 className="text-xl font-semibold mb-4 text-center">
              Confirm Your Email
            </h2>
            <input
              type="email"
              placeholder="Enter your email"
              className="w-full border border-gray-300 rounded-lg p-3 mb-4"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              className="w-full bg-red-500 text-white py-3 rounded-lg"
              onClick={handleNextStep}
              disabled={loading}
            >
              {loading ? "Loading..." : "Next"}
            </button>
            <button
              className="w-full mt-4 text-gray-500"
              onClick={() => setStep(0)}
            >
              Back
            </button>
          </div>
        )}
        {step === 2 && (
          <div>
            <h2 className="text-xl font-semibold mb-4 text-center">
              Confirm Your Password
            </h2>
            <div className="relative mb-4">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                className="w-full border border-gray-300 rounded-lg p-3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-500"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <button
              className="w-full bg-red-500 text-white py-3 rounded-lg"
              onClick={handleNextStep}
              disabled={isDeleting}
            >
              {isDeleting ? "Deleting..." : "Delete My Account"}
            </button>
            <button
              className="w-full mt-4 text-gray-500"
              onClick={() => setStep(1)}
            >
              Back
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeleteAccount;

