import React from 'react'
import {AiOutlineSearch} from 'react-icons/ai'
import {Link} from 'react-router-dom'

const Search = () => {

    return (
        <div className='bg-gray-200 pt-6'>
            <div className='flex justify-center'>
                <div className="relative mb-6 max-md:ml-0 ">
                    <input type="text"
                           className='pl-[50px] w-[34rem] max-md:w-[10rem] rounded-s-xl border-gray-400  focus:ring-yellow-200 ring-0'
                           placeholder='Search here'/>
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none mt-[-6px] ">
                        <AiOutlineSearch className="w-7 h-7 text-gray-500  dark:text-gray-400  "/>
                    </div>
                </div>
                <Link to='/searchpage' className=' text-center  max-md:text-[0.6rem] text-white mr-4'><p
                    className='font-[600] !bg-[#2994FF] py-[9px] max-md:py-[14px] px-6 rounded-r-xl'>Search job </p>
                </Link>
            </div>
        </div>
    )
}

export default Search
