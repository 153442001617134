import React from 'react'
import QuickSearch from '../components/QuickSearch'
import GetApp from '../components/GetApp'
import Footer from '../components/Footer'
import TopBar from '../components/TopBar'
import Search from '../components/Search'

const recent = [
    {
        status: 'New',
        time: 'Today',
        role: 'Occupational therapist (G2) - School Readiness',
        company: 'Facebook',
        about: 'IT & Communications',
        requirement: 'Bachelor',
        city: 'Sydney, Australia',
    },
    {
        status: 'New',
        time: 'Today',
        role: 'Occupational therapist (G2) - School Readiness',
        company: 'Facebook',
        about: 'IT & Communications',
        requirement: 'Bachelor',
        city: 'Sydney, Australia',
    },
    {
        status: 'New',
        time: 'Today',
        role: 'Occupational therapist (G2) - School Readiness',
        company: 'Facebook',
        about: 'IT & Communications',
        requirement: 'Bachelor',
        city: 'abc, Australia',
    },
    {
        status: 'New',
        time: 'Today',
        role: 'Occupational therapist (G2) - School Readiness',
        company: 'Facebook',
        about: 'IT & Communications',
        requirement: 'Bachelor',
        city: 'abc, Australia',
    },
    {
        status: 'New',
        time: 'Today',
        role: 'Occupational therapist (G2) - School Readiness',
        company: 'Facebook',
        about: 'IT & Communications',
        requirement: 'Bachelor',
        city: 'abc, Australia',
    },
    {
        status: 'New',
        time: 'Today',
        role: 'Occupational therapist (G2) - School Readiness',
        company: 'Facebook',
        about: 'IT & Communications',
        requirement: 'Bachelor',
        city: 'abc, Australia',
    },
    {
        status: 'New',
        time: 'Today',
        role: 'Occupational therapist (G2) - School Readiness',
        company: 'Facebook',
        about: 'IT & Communications',
        requirement: 'Bachelor',
        city: 'abc, Australia',
    },
    {
        status: 'New',
        time: 'Today',
        role: 'Occupational therapist (G2) - School Readiness',
        company: 'Facebook',
        about: 'IT & Communications',
        requirement: 'Bachelor',
        city: 'abc, Australia',
    },

]


const RecommendedJobs = () => {
    return (
        <div>
            <TopBar/>
            <Search/>
            <QuickSearch/>
            <h1 className='uppercase text-[1rem] text-center p-2 font-[800] text-white bg-[#F0A51E] max-md:text-[0.6rem]'>We
                will connect with hiring agencies, job banks and recruiters to place your CV</h1>
            <GetApp/>
            <h1 className='text-[2.3rem] font-[600] text-center mt-5'>Recommended Jobs</h1>
            <div className='bg-[#EAEAEA] pb-6'>
                <div className='w-[80%] m-auto pt-10'>
                    <div className='grid grid-cols-2  max-md:grid-cols-1 gap-10 m-auto mt-5'>
                        {recent.map((value) => (
                            <div className='border-2 rounded-xl bg-white py-4 shadow-xl shadow-gray-400'>
                                <div className='flex mt-5'>
                                    <p className='bg-[#ccf0d0] rounded-md px-2 ml-4 text-[#207a00]'>{value.status}</p>
                                    <p className='ml-auto mr-4 font-[500]'>{value.time}</p>
                                </div>
                                <h1 className='font-[600] ml-4 w-[80%]'>{value.role}</h1>
                                <h1 className='ml-4 font-[500]'>{value.company}</h1>
                                <div className='flex'>
                                    <p className='ml-4 font-[700]'>{value.about}</p>
                                    <p className='ml-auto mr-4 font-[700]'>{value.requirement}</p>
                                </div>
                                <h1 className='text-[1.1rem] mt-2 text-center text-blue-600 font-[600]'>{value.city}</h1>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default RecommendedJobs
