import {
    ALL_BOOKMARKS
} from "../../Utils/Constant";

const bookmark = (state = {}, action) => {
    switch (action.type){
        case ALL_BOOKMARKS:
            return {...state, bookmarks: action.payload.bookmarks}
        default:
            return state
    }
}

export default bookmark
