import {Modal} from 'flowbite-react'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {AllCompanies} from '../API/actions/companiesAction'

const CompanyModal = ({open, setOpen}) => {

    const dispatch = useDispatch()

    const handleClose = () => {
        setOpen(!open)
    }

    const companies = useSelector(state => state.company.companies)

    useEffect(() => {
        dispatch(AllCompanies())
    }, [dispatch])


    return (
        <div>
            <Modal show={open} className='h-[full-screen] rounded-lg  ' onClose={handleClose}
                   aria-labelledby="customized-dialog-title">
                <Modal.Header id="customized-dialog-title" className='w-[100%] max-md:w-[100%] bg-white  max-md:ml-0'>
                    Select Cities
                </Modal.Header>
                <div className=' border-[1px] my-[0.5rem] py-[0.5rem] px-[1.5rem] !bg-gray-100 border-gray-300'>
                    <Modal.Body className=' items-center w-[100%] p-[1rem] '>
                        <div className='grid grid-cols-2 overflow-scroll h-[16rem] '>
                            {companies?.map((value) => (
                                <div className='flex items-center p-3'>
                                    <input type="checkbox" name="" id="" className='rounded-xl'/>
                                    <p className='text-gray-700 font-[500] ml-4 text-[.9rem]'>{value.name}</p>
                                    <p className='ml-auto mr-2 text-[.9rem] text-gray-500 font-[500]'>({value.jobs})</p>
                                </div>
                            ))}
                        </div>
                    </Modal.Body>
                </div>
                <div className='!m-auto pb-5 '>
                    <button variant="text" className='text-white bg-blue-500 px-8 py-2 rounded-xl !mb-[-3rem] font'
                            onClick={handleClose}>
                        Cancel
                    </button>
                </div>
            </Modal>
        </div>
    )
}

export default CompanyModal
