import React from 'react'

const VerificationCode = () => {
    return (
        <>
            <div>
                <div className='w-[100%] h-[20rem] bg-blue-600'>
                </div>
                <div className='w-[100%] h-screen bg-gray-400'>
                    <center>
                        <div className='bg-white rounded-md p-[10px] w-[40%] text-[18px] relative top-[-210px]'>
                            <br/><br/>
                            <h1 className='text-[36px] font-normal'>Verification Code</h1>
                            <p className='test-[16px] font-light'>Please enter your code sent to xxxxxxxxxxx</p>
                            <br/><br/><br/>

                            <span className='flex'><hr
                                className='w-[30px] border-3 border-gray-500 ml-[6rem] mr-[1rem]'/><hr
                                className='w-[30px] border-3 border-gray-500 ml-[1rem] mr-[1rem]'/><hr
                                className='w-[30px] border-3 border-gray-500 ml-[1rem] mr-[1rem]'/><hr
                                className='w-[30px] border-3 border-gray-500 ml-[1rem] mr-[1rem]'/><hr
                                className='w-[30px] border-3 border-gray-500 ml-[1rem] mr-[1rem]'/><hr
                                className='w-[30px] border-3 border-gray-500 ml-[1rem] mr-[1rem]'/></span><br/><br/>
                            <button
                                className='pl-[140px] pr-[140px] rounded-[30px] text-[30px] bg-blue-600 text-white pt-[6px] pb-[6px]'>
                                Send
                            </button>
                            <br/><br/>
                            <p className='text-[15px]'>Didn't receive the code?</p>
                            <button className='font-medium text-[17px]'>Resend Code</button>
                            <br/><br/>
                        </div>
                    </center>
                </div>
            </div>
        </>
    )
}

export default VerificationCode
