import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Footer = () => {

    // console.log('url of admin', `${process.env.REACT_APP_ADMIN_URL}`);

    const navigate = useNavigate();

    return (
        <div>
            <p className='bg-gray-500 h-[1px]' />
            <div className='grid grid-cols-2 max-md:grid-cols-1 bg-[#010E3E] text-white '>
                <div className='p-10'>
                    <h1 className='text-[1.4rem] text-left max-md:text-[1rem] font-[400] '>Career Advice</h1>
                    <p className='hover:text-white cursor-pointer font-[500] mt-2 text-[.8rem] max-md:text-[.7rem] w-[80%] text-gray-600'> Access
                        valuable career insights and guidance from seasoned professionals to steer your career in the
                        right direction.
                    </p>
                    <div className='grid grid-cols-2 gap-10'>
                        <div>
                            <h1 className='text-[1.4rem] text-left max-md:text-[1rem] font-[400] mt-6'> Resume
                                Tips</h1>
                            <p className='hover:text-white cursor-pointer font-[500] mt-2  text-[.8rem] max-md:text-[.7rem] w-[90%]  text-gray-600'>Explore
                                our expert advice on crafting the perfect resume to make a lasting impression on
                                potential employers.
                            </p>
                        </div>
                        <div>
                            <h1 className='text-[1.4rem] text-left max-md:text-[1rem] font-[400] mt-6'>Interview
                                Preparation</h1>
                            <p className='hover:text-white cursor-pointer font-[500] mt-2  text-[.8rem] max-md:text-[.7rem] w-[90%]  text-gray-600'>Get
                                ready for your job interviews with our comprehensive interview preparation tips and
                                techniques.
                            </p>
                        </div>
                        <div>
                            <h1 className='text-[1.4rem] text-left max-md:text-[1rem] font-[400] '>Networking</h1>
                            <p className='hover:text-white cursor-pointer font-[500] mt-2  text-[.8rem] max-md:text-[.7rem] w-[90%]  text-gray-600'> Learn
                                how to build and expand your professional network to enhance your career opportunities
                                and growth.
                            </p>
                        </div>
                        <div>
                            <h1 className='text-[1.4rem] text-left max-md:text-[1rem] font-[400] '>Job Alerts</h1>
                            <p className='hover:text-white cursor-pointer font-[500] mt-2  text-[.8rem] max-md:text-[.7rem] w-[90%]  text-gray-600'> Stay
                                up to date with the latest job openings by subscribing to our job alerts. Receive
                                notifications tailored to your preferences.
                            </p>
                        </div>
                        <div>
                            <h1 className='text-[1.4rem] text-left max-md:text-[1rem] font-[400] '>Join Our Team</h1>
                            <p className='hover:text-white cursor-pointer font-[500] mt-2  text-[.8rem] max-md:text-[.7rem] w-[90%]  text-gray-600'> Discover
                                exciting opportunities to join our dynamic team at <span
                                    className='text-blue-600'> jobss.com.au </span> and be a part of our mission to
                                connect job seekers with their dream careers.
                            </p>
                        </div>
                        <div>
                            <h1 className='text-[1.4rem] text-left max-md:text-[1rem] font-[400] '>Careers</h1>
                            <p className='hover:text-white cursor-pointer font-[500] mt-2  text-[.8rem] max-md:text-[.7rem] w-[90%]  text-gray-600'> Explore
                                career opportunities with us and find out how you can contribute to our mission of
                                making the job search process easier for everyone.
                            </p>
                        </div>
                        <div>
                            <h1 className='text-[1.4rem] text-left max-md:text-[1rem] font-[400] '>Employee
                                Stories</h1>
                            <p className='hover:text-white cursor-pointer font-[500] mt-2  text-[.8rem] max-md:text-[.7rem] w-[90%]  text-gray-600'> Hear
                                firsthand accounts from our team members about their experiences and successes working
                                with <span className='text-blue-600'> jobss.com.au </span>.

                            </p>
                        </div>
                    </div>
                </div>
                <div className='flex-col'>
                    <div className='grid grid-cols-3 max-md:grid-cols-2 gap-6 text-white p-[3rem] '>
                        <div>
                            <h1 className='text-[1.5rem] text-left max-md:text-[1rem]'>Job Seekers</h1>
                            <div className='text-[.8rem] max-md:text-[.7rem] text-left text-gray-600 '>
                                <p onClick={() => navigate('/category')}
                                    className='hover:text-white font-[500] mt-2 cursor-pointer'>Categories </p>
                                <p onClick={() => navigate('/SearchPage')}
                                    className='hover:text-white font-[500] mt-2 cursor-pointer'>Find Job</p>
                                <p onClick={() => navigate('/featured')}
                                    className='hover:text-white font-[500] mt-2 cursor-pointer'>Featured job</p>
                                {/* <p onClick={() => navigate('/companies')}
                                    className='hover:text-white font-[500] mt-2 cursor-pointer'>Companies</p> */}
                                {/* <p onClick={() => navigate('/Resume')}
                                    className='hover:text-white font-[500] mt-2 cursor-pointer'>Distribute Resume</p> */}
                                <p onClick={() => navigate('/contact')}
                                    className='hover:text-white font-[500] mt-2 cursor-pointer'>Conatct Us</p>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[1.5rem] text-left max-md:text-[1rem]'>Employers</h1>
                            <div className='text-[.8rem] max-md:text-[.7rem] text-left text-gray-600'>
                                <a href="https://jobss.com.au/admin/providerLogin" target='_blank'>
                                    <p className='hover:text-white font-[500] mt-2  cursor-pointer'>Register
                                        Now</p>
                                </a>
                                <a href={`${process.env.REACT_APP_ADMIN_URL}`} target='_blank'>
                                    <p className='hover:text-white font-[500] mt-2  cursor-pointer'>Post a
                                        job </p>
                                </a>
                                {/* <p className='hover:text-white cursor-pointer font-[500] mt-2  cursor-pointer'> Service</p> */}
                            </div>
                        </div>
                        <div className="flex-col max-md:hidden ">
                                <img src="./assets/qr.png" alt="" className='w-[12rem] h-[11rem] rounded-xl hover:scale-105' />
                            <div className='flex-col relative gap-6 top-10'>
                            <a href="https://play.google.com/store/" target="_blank" rel="noopener noreferrer">
                                <img src="./assets/play_store_1.png" alt=""
                                    className='w-[12rem] h-[4.4rem] cursor-pointer my-3 hover:scale-105' />
                                    </a>
                            <a href="https://play.google.com/store/" target="_blank" rel="noopener noreferrer">
                                <img src="./assets/app_store_1.png" alt=""
                                    className='w-[12rem] h-[4.4rem] cursor-pointer my-3 hover:scale-105' />
                                    </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className='bg-[#010E3E]  text-white font-bold p-[1rem] text-[.7rem] '>
                <ul typeof='none' className='flex max-md:flex-col'>
                    <a >
                        <li className='cursor-pointer'>@2024 RIGHT RESERVED. Jobss.com.au</li>
                    </a>
                    <Link to='/termsconditions' className='ml-auto cursor-pointer max-md:mt-4'>
                        <li>TERMS AND CONDITIONS</li>
                    </Link>
                    <Link to='/privacypolicy' className='ml-2 cursor-pointer max-md:mt-4 max-md:ml-auto'>
                        <li>PRIVACY POLICY</li>
                    </Link>
                </ul>
            </div>
        </div>

    )
}

export default Footer
