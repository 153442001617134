import React from 'react'
import TopBar from '../components/TopBar'
import { Link } from 'react-router-dom'

const SeekerInfo = () => {
    return (
        <div className=''>
             
            <hr className=' mt-5 h-[2px] bg-gray-300' />

            <div className='flex flex-col my-5 mb-10 h-screen w-full'>
                <h1 className='text-[2rem] max-sm:text-[1.5rem] font-[600] ml-[24%] max-sm:ml-6 mt-3 '>Seeker Infomation</h1>
                <div className='flex flex-col my-6 items-center justify-center w-full'>
                    <div className=' w-[40%] max-sm:w-[90%]'>
                        <div class="relative mr-4">
                            <label for="hero-field" class="leading-7 font-[600] text-sm text-gray-600">Name</label>
                            <input type="text" id="hero-field" name="hero-field" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div class="relative mr-4 mt-3 ">
                            <label for="hero-field" class="leading-7 font-[600] text-sm text-gray-600">Email</label>
                            <input type="text" id="hero-field" name="hero-field" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div class="relative mr-4 mt-3 ">
                            <label for="hero-field" class="leading-7 font-[600] text-sm text-gray-600">Country</label>
                            <input type="text" id="hero-field" name="hero-field" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div class="relative mr-4 mt-3 ">
                            <label for="hero-field" class="leading-7 font-[600] text-sm text-gray-600">Address</label>
                            <input type="text" id="hero-field" name="hero-field" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div class="relative mr-4 mt-3 ">
                            <label for="hero-field" class="leading-7 font-[600] text-sm text-gray-600">Postal Code</label>
                            <input type="text" id="hero-field" name="hero-field" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div className='mr-auto mt-10'>
                            <Link to='/profileInfo' className='bg-white text-black border-[1px] border-gray-900 px-5 py-2 rounded-md '  >Save</Link>
                        </div>
                    </div>
                    <hr className='w-[60%] max-sm:w-full mt-5 h-[2px] bg-gray-400' />

                </div>

            </div>
        </div>
    )
}

export default SeekerInfo
