import React, { useEffect, useState } from 'react'
import TopBar from '../components/TopBar'
import Footer from '../components/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { getCvByUser } from '../API/actions/cvAction'
import LoginRequireModal00 from '../components/LoginRequireModal'
import { ClipLoader } from 'react-spinners'
import LoadingModal from '../components/LoadingModal'
import { SESSION_ID, SESSION_SEEKER } from '../Utils/Constant'
import ProAccount from '../components/ProAccount'
import ResumeModal from '../components/ResumeForm'
import { useNavigate } from 'react-router-dom'
import { fetchSeeker } from '../API/actions/seekerAction'
import { CiEdit } from "react-icons/ci";
import { RiEdit2Fill } from "react-icons/ri";
import { checkCV, distributeResume } from '../API'
import CheckCvModal from '../components/CheckCvModal'



const Resume = () => {
    
    const dispatch = useDispatch();
    const [data, setData] = useState()
    const navigate = useNavigate()

    const seeker = useSelector(state => state.seeker.seeker)

    const cvByUser = useSelector(state => state.cv.cvs)
    const loading = useSelector(state => state.loading.cvLoading)

    useEffect(() => {
        const isLogin = sessionStorage.getItem(SESSION_SEEKER)
        if (isLogin === 'null') {
            navigate('/')
        }
    })


    useEffect(() => {
        const seeker = sessionStorage.getItem(SESSION_ID)
        setData(JSON.parse(seeker))

    })


    useEffect(() => {
        dispatch(getCvByUser(data))
    }, [dispatch, data])


    const [visible, setVisible] = useState(false)
    const toggleVisible = () => setVisible(!visible)


    const [seekerData, setSeekerData] = useState()
    useEffect(() => {
        setSeekerData(JSON.parse(sessionStorage.getItem(SESSION_SEEKER)))
    }, [])

    useEffect(() => {
        dispatch(fetchSeeker(seekerData?.id))
    }, [dispatch, seekerData]);


    const [show, setShow] = useState(false)

    useEffect(() => {
        if (cvByUser?.role && cvByUser?.careers?.length > 0 && cvByUser?.educations?.length > 0) {
            setShow(false)
        } else {
            setShow(true)
        }
    }, [cvByUser?.role, cvByUser?.careers, cvByUser?.educations])



    const [check, setCheck] = useState()


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (seekerData?.id) {
                    const res = await checkCV(seekerData.id);
                    console.log('applied', res);
                    setCheck(res?.data?.status);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [seekerData?.id]);



    const [checkModal, setCheckModal] = useState(false);

    const toggleCheck = () => {
        setCheckModal(!checkModal)
    }

    const [resume, setResume] = useState(false)

    const toggleVisibility = async () => {
        setResume(true);
        const res = await distributeResume(cvByUser);
        console.log('res', res);
        setResume(false);

    }


    const toggleResumeVisible = () => setResume(!resume)







    return (
        <div>
            <CheckCvModal visible={checkModal} toggleVisibility={toggleCheck} />
            {loading ?
                <div className='flex justify-center mt-[20%]'>
                    <ClipLoader size='5rem' />
                </div>
                :
                <>

                    <ProAccount visible={visible} toggleVisible={toggleVisible} />
                    <LoadingModal visible={resume} toggleVisible={toggleVisibility} cvByUser={cvByUser} toggleResume={toggleResumeVisible} />

                    <div className="flex justify-center mt-10 gap-6 ">
                        <h1 className='text-[2rem] font-[800] text-[#000] text-center max-md:text-[2rem] uppercase '>Resume</h1>
                        <CiEdit size={30} className='mt-2 cursor-pointer' onClick={() => navigate('/resumeForm')} />
                    </div>

                    {show ?
                        <div>
                            <img src="./assets/resume.png" alt="" className='m-auto' />

                        </div>
                        :
                        <>
                            <div style={{ backgroundColor: 'white' }} className='border-4 max-md:w-[100%] w-[60%] m-auto p-4 mt-5 mb-10'>
                                <div className='w-[90%] mt-10  m-auto'>
                                    <h1 style={{ color: 'black', textAlign: 'center' }} className='max-md:text-[.9rem] text-[1rem]'>{cvByUser?.name}</h1>
                                    <h1 style={{ fontSize: 20, color: 'black', textAlign: 'center' }}> {cvByUser?.address}</h1>

                                    <div className='max-md:flex-col flex mt-4 justify-between '>
                                        <h1 style={{ fontSize: 16, color: 'black', }}> {cvByUser?.code}{cvByUser?.phone}</h1>
                                        <h1 style={{ fontSize: 16, color: 'black' }} className='text-nowrap'>{cvByUser?.email}</h1>
                                    </div>

                                </div>
                                <div className='w-[90%] m-auto'>
                                    <hr style={{ backgroundColor: 'gray', height: 1, marginTop: 20 }}></hr>
                                    <h1 style={{ textAlign: 'center', fontSize: 16, marginTop: 20 }}
                                        className='font-[600]'>{cvByUser?.role}</h1>
                                    <hr style={{ backgroundColor: 'gray', height: 1, marginTop: 20 }}></hr>

                                    <h1 className='my-5'>{cvByUser?.statement}</h1>

                                    <hr style={{ backgroundColor: 'gray', height: 1, marginTop: 20 }}></hr>
                                    <h1 style={{ textAlign: 'center', fontSize: 16, marginTop: 20 }} className='font-[600]'>Key
                                        Skills</h1>
                                    <hr style={{ backgroundColor: 'gray', height: 1, marginTop: 20 }}></hr>


                                    <div className="grid grid-cols-3   ">
                                        {cvByUser?.skills?.map((value) => (
                                            <p className='mt-2 text-center'>{value.skill}</p>
                                        ))}
                                    </div>

                                    <hr style={{ backgroundColor: 'gray', height: 1, marginTop: 20 }}></hr>
                                    <h1 style={{ textAlign: 'center', fontSize: 16, marginTop: 20 }}
                                        className='font-[600]'>EMPLOYMENT HISTORY</h1>
                                    <hr style={{ backgroundColor: 'gray', height: 1, marginTop: 20 }}></hr>

                                    {cvByUser?.careers?.map((item) => (
                                        <div>
                                            <div style={{ display: 'flex', gap: 4, marginTop: 6 }}>
                                                <h1 className='font-[600]'>
                                                    {item.job}
                                                </h1>
                                                <h1>|</h1>
                                                <h1>
                                                    {item.timeperiod}
                                                </h1>
                                            </div>
                                            <h1 className='my-[2px]'>
                                                {item.company}
                                            </h1>
                                            <div style={{ textAlign: 'right' }}>
                                                <h1>
                                                    {item.address}
                                                </h1>
                                            </div>
                                            {/* <h1 style={{ marginTop: 6, marginBottom: 6 }} className='text-right mr-10'>
                                                Phone :{item.phone}
                                            </h1> */}
                                            <hr style={{ backgroundColor: 'black', height: 1, paddingHorizontal: 20 }}></hr>
                                        </div>
                                    ))}


                                    <h1 style={{ textAlign: 'center', fontSize: 16, marginTop: 20 }}
                                        className='font-[600]'>QUALIFICATIONS</h1>
                                    <hr style={{ backgroundColor: 'gray', height: 1, marginTop: 20 }}></hr>

                                    {cvByUser?.educations?.map((item) => (
                                        <div>
                                            <div style={{ flexDirection: 'row', display: 'flex', gap: 5 }}>
                                                <h1 className='font-[600]'>
                                                    {item.qualification}
                                                </h1>
                                                <h1>|</h1>
                                                <h1>
                                                    {item.institute}
                                                </h1>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', }}>
                                                <h1 style={{ marginLeft: 'auto' }}>
                                                    {item.timeperiod}
                                                </h1>
                                            </div>
                                        </div>
                                    ))}

                                    <h1 style={{ textAlign: 'center', fontSize: 16, marginTop: 20 }}
                                        className='font-[600]'>TRAINING COURSES</h1>
                                    <hr style={{ backgroundColor: 'gray', height: 1, marginTop: 20 }}></hr>

                                    {cvByUser?.courses?.map((item) => (
                                        <div>
                                            <div style={{ flexDirection: 'row', display: 'flex', gap: 5 }}>
                                                <h1 className='font-[600]'>
                                                    {item.course}
                                                </h1>
                                                <h1>|</h1>
                                                <h1>
                                                    {item.timeperiod}
                                                </h1>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', }}>
                                                <h1 style={{ marginLeft: 'auto' }}>
                                                    {item.institute}
                                                </h1>
                                            </div>

                                        </div>
                                    ))}
                                    <div className='flex gap-6 justify-between w-[100%]' >

                                        <div className='w-[50%]'>
                                            <h1 style={{ textAlign: 'center', fontSize: 16, marginTop: 20 }} className='font-[600]'>Interest</h1>
                                            <hr style={{ backgroundColor: 'gray', height: 1, marginTop: 20 }}></hr>
                                            <div className="grid grid-cols-2   ">
                                                {cvByUser?.interests?.map((value) => (
                                                    <p className='mt-2 text-center'>{value.interest}</p>
                                                ))}
                                            </div>
                                        </div>

                                        <div className='w-[50%]'>
                                            <h1 style={{ textAlign: 'center', fontSize: 16, marginTop: 20 }} className='font-[600]'>languages</h1>
                                            <hr style={{ backgroundColor: 'gray', height: 1, marginTop: 20 }}></hr>
                                            <div className="grid grid-cols-2   ">
                                                {cvByUser?.languages?.map((value) => (
                                                    <p className='mt-2 text-center'>{value.language}</p>
                                                ))}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>}
                    <center>

                        {/* <button onClick={() => navigate('/resumeForm')}
                            className=' mt-2 mb-10 py-2 px-7 bg-blue-600 text-white rounded-md font-[600] '>
                            Update Resume
                        </button> */}

                        <button
                            onClick={() => {
                                if (seeker?.plan === 0) {
                                    toggleVisible();
                                } else if (check === 'complete') {
                                    toggleVisibility();
                                } else {
                                    toggleCheck()
                                }
                            }}
                            className='mt-2 mb-10 py-2 px-7 bg-blue-600 text-white rounded-md font-[600] '>
                            Distribute Resume
                        </button>


                    </center>
                    <Footer />
                </>}
        </div>
    )
}

export default Resume
